import {useEffect} from "react"
import {sortBy} from "lodash-es"

import usePreventDirectUnloadPage from "hooks/usePreventDirectUnloadPage"
import {useDroppedFiles, useListenDroppedFiles} from "lib/store/useDroppedFiles"
import {useUploadedFiles, useUploadFiles} from "services/uploadFile/hook"
import {useRefreshBillOfLadingsFn, useRefreshDistinctFn} from "services/bol"
import {useRefreshTextractFailedFilesFn} from "services/textract-failed"
import {isCsvFile} from "utils/file"

// TODO: handle new files in other way

/**
 * @param {{
 *  enable?: boolean
 *  uploadLimit?: number
 *  convertLimit?: number
 * }} [options]
 * @returns
 */
export function useBoLUploadingList(options = {}) {
  const {enable = false, uploadLimit = 3, convertLimit = 5} = options

  const refreshTableData = useRefreshBillOfLadingsFn()
  const refreshDistinct = useRefreshDistinctFn()
  const refreshErrorList = useRefreshTextractFailedFilesFn()

  const {
    addFiles,
    runHandlers: startUpload,
    cleanHandlers: cleanUploadHandlers,
    current: uploadingFiles,
    queued: queuedUploadingFiles,
    failed: failedUploadingFiles,
    completed: uploadedFiles,
    isCompleted: isUploadCompleted,
  } = useUploadFiles({
    enable,
    limit: uploadLimit,
  })

  const {
    current: analyzingFiles,
    queued: queuedAnalysisFiles,
    completed: analyzedFiles,
    failed: failedAnalyzingFiles,
    addBoLHandlers,
    runHandlers: startAnalysis,
    cleanHandlers: cleanAnalysisHandlers,
  } = useUploadedFiles({
    enable,
    limit: convertLimit,
    onCompleted: () => {
      refreshErrorList()
      refreshTableData()
      refreshDistinct()
    },
  })

  useListenDroppedFiles({
    enable,
    onUpdate: newFiles => {
      const {resetFiles, site} = useDroppedFiles.getState()
      if (site) {
        cleanUploadHandlers()
        cleanAnalysisHandlers()
        setTimeout(() => {
          const timestampString = Date.now().toString()
          console.log("### [useBoLUploadingList] add files and start upload")
          startUpload()
          addFiles({[timestampString]: newFiles.filter(f => !isCsvFile(f))})
          resetFiles()
        })
      } else {
        console.error("onPreStartUpload:: Unintended missing `site` param")
      }
    },
  })

  useEffect(() => {
    if (isUploadCompleted && uploadedFiles.length > 0) {
      const {site} = useDroppedFiles.getState()
      if (site) {
        const files = uploadedFiles.reduce((prev, handler) => {
          if (!prev[handler.groupName]) {
            prev[handler.groupName] = []
          }
          prev[handler.groupName].push(handler.file)
          return prev
        }, /** @type {Record<string,File[]>} */ ({}))
        startAnalysis()
        addBoLHandlers(files, site)
      } else {
        console.error("onPreStartAnalysis:: Unintended missing `site` param")
      }
    }
  }, [isUploadCompleted, uploadedFiles])

  const allUploadingFiles = !isUploadCompleted
    ? sortBy(
        [
          ...uploadingFiles,
          ...queuedUploadingFiles,
          ...uploadedFiles,
          ...failedUploadingFiles,
        ],
        "file.name",
      )
    : []

  const allAnalyzingFiles = isUploadCompleted
    ? sortBy(
        [
          ...analyzingFiles,
          ...queuedAnalysisFiles,
          ...analyzedFiles,
          ...failedAnalyzingFiles,
        ],
        "groupName",
      )
    : []

  usePreventDirectUnloadPage({
    enable: enable && uploadingFiles.length + analyzingFiles.length > 0,
  })

  const message = isUploadCompleted
    ? analyzingFiles.length === 0
      ? ["uploadWindowStatus.allCompleted"]
      : ["uploadWindowStatus.identifying"]
    : uploadingFiles.length > 0
    ? [
        "uploadWindowStatus.uploading",
        {
          count: uploadingFiles.length + queuedUploadingFiles.length,
        },
      ]
    : ["uploadWindowStatus.idle"]

  return {
    messageKey: /** @type {[string,...any]} */ (message),
    allAnalyzingFiles,
    allUploadingFiles,
  }
}
