import React from "react"
import {BoLStatus} from "@domain"
import {
  StatusCompleteIcon,
  StatusClearanceIcon,
  StatusNoDataIcon,
  StatusOnTransitIcon,
  StatusWaitingIcon,
  StatusSuspectIcon,
  StatusErrorIcon,
} from "./status"

const mapping = {
  [BoLStatus.Clearance]: StatusClearanceIcon,
  [BoLStatus.Complete]: StatusCompleteIcon,
  [BoLStatus.Transiting]: StatusOnTransitIcon,
  [BoLStatus.WaitingOnBoard]: StatusWaitingIcon,
  [BoLStatus.NoData]: StatusNoDataIcon,
  [BoLStatus.Suspect]: StatusSuspectIcon,
  [BoLStatus.Error]: StatusErrorIcon,
}

/**
 * @type {React.FC<{
 *  status: number
 * } & React.SVGAttributes<SVGElement>
 * >}
 */
const BoLStatusIcon = ({status, ...props}) => {
  // @ts-ignore
  const Component = mapping[status]
  return Component ? <Component {...props} /> : null
}

export default BoLStatusIcon
