import {useQuery} from "@tanstack/react-query"
import {Auth} from "aws-amplify"

import {oauthToken} from "lib/oauth"

/**
 * @param {import("@tanstack/react-query").UseQueryOptions<
 *  any,
 *  any,
 *  Awaited<ReturnType<Auth['currentSession']>>,
 *  any
 * >} [options]
 */
const useUserSession = (options = {}) => {
  return useQuery({
    ...options,
    queryKey: ["currentUserSession"],
    queryFn: oauthToken,
  })
}

export default useUserSession
