import * as React from "react"
import {useTranslation} from "react-i18next"

import ICLoading from "components/shared/icons/loading"

import {useIsEdited, useIsEditInvalid, useSaveEditedBol} from "./bol-edit.hook"

/**
 * @description the save button in the edit panel
 * it will only display when there're edited values
 */
export const SaveButton = ({}) => {
  const {t} = useTranslation()
  const isEdited = useIsEdited()
  const isInvalid = useIsEditInvalid()

  const {save, isLoading: isUpdating} = useSaveEditedBol()

  if (isUpdating) {
    return (
      <div className="p-2 py-3">
        <ICLoading size={20} />
      </div>
    )
  }

  return (
    <button
      disabled={isInvalid || isUpdating}
      onClick={save}
      className={`${
        isEdited ? "" : "invisible"
      } p-2 py-3 text-sm font-semibold rounded-lg text-primary hover:opacity-80
      disabled:!text-deepBlue-30 disabled:!opacity-100
  `}
    >
      {t("btnSave")}
    </button>
  )
}

export default SaveButton
