import Axios from "axios"

import {useMutation} from "lib/query"
import {giantRequest} from "lib/apiClient"
import {BolPrecheckDataDTO, BolPrecheckResultDTO} from "@domain"
import {z} from "zod"

const BOL_MUTATION_KEY = ["ValidateBolCsv"]

/**
 * @typedef {object} ValidateBolParams
 * @property {string} fileName
 * @property {File} file
 * @property {string} site
 */

/**
 * @param {ValidateBolParams} params
 * @param {AxiosRequestConfig=} options
 */
export async function validateBol({file, fileName, site}, options) {
  const response = await giantRequest({
    ...options,
    url: "/bol/validate",
    method: "Post",
    data: {
      doc: fileName,
      site,
    },
  }).catch(err => {
    if (Axios.isAxiosError(err) && err.response?.status) {
      if (err.response.status >= 400 && err.response.status < 500) {
        return err.response
      }
    }
    throw err
  })

  const content = await file.text()
  const lines = content.split(/\r?\n/)

  if (!response.data?.length || lines.length < response.data.length + 4) {
    throw new Error()
  }

  return BolPrecheckResultDTO.parse(response.data)
}

/**
 * @param {import('lib/query').UseMutationOptions<
 *  Awaited<ReturnType<typeof validateBol>>,
 *  import('axios').AxiosError<{}>,
 *  ValidateBolParams,
 *  undefined
 * >=} options
 */
export function useValidateBol(options = {}) {
  const validateBolMutation = useMutation(validateBol, {
    ...options,
    mutationKey: BOL_MUTATION_KEY,
  })

  return {
    resultHasWarning: validateBolMutation.data?.some(row => {
      const {row: _, ...rest} = row
      return Object.values(rest).some(o => o.hasOwnProperty("warning"))
    }),
    resultHasError: validateBolMutation.data?.some(row => {
      const {row: _, ...rest} = row
      return Object.values(rest).some(o => o.hasOwnProperty("error"))
    }),
    ...validateBolMutation,
  }
}

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
