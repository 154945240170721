import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const ICWrong = props => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={6.4} fill="#FF6A39" />
    <path
      d="M7.3 9.458H8.62l.18-5.41H7.108l.19 5.41Zm1.646 1.702c0-.515-.403-.918-.952-.918-.571 0-.974.403-.974.918s.403.918.974.918c.549 0 .952-.403.952-.918Z"
      fill="#fff"
    />
  </svg>
)

export default ICWrong
