import {z} from "zod"
import {ListObjectsV2Command} from "@aws-sdk/client-s3"

import {s3Client, awsConfig} from "lib/awsClient"
import {useInfiniteQuery, useQueryClient} from "lib/query"

const MAIN_KEY = "infinite-textract-failed"

const GetTextractFailedParams = z.object({
  pageSize: z.number().optional(),
  continuationToken: z.string().optional(),
})
/** @typedef {z.infer<typeof GetTextractFailedParams>} GetTextractFailedParams */

/**
 * @param {GetTextractFailedParams} params
 * @returns {[string, GetTextractFailedParams]}
 */
const _getKey = params => [MAIN_KEY, params]

/**
 * @param {import('@tanstack/react-query').QueryFunctionContext<
 *  [string, GetTextractFailedParams],
 *  GetTextractFailedParams['continuationToken'] | undefined
 * >} context
 */
async function _queryFn({queryKey, pageParam, signal}) {
  const [_ignore, queryParams = {}] = queryKey

  // @ts-ignore
  return s3Client.send(
    new ListObjectsV2Command({
      Bucket: awsConfig.bucketName,
      Delimiter: "/",
      Prefix: "textractFail/",
      MaxKeys: queryParams.pageSize,
      ContinuationToken: queryParams.continuationToken,
    }),
    {abortSignal: signal},
  )
}

/**
 * @template [TData=ListObjectsV2CommandOutput]
 * @param {{pageSize: number}} [params]
 * @param {import('@tanstack/react-query').UseInfiniteQueryOptions<
 *  ListObjectsV2CommandOutput,
 *  unknown,
 *  TData,
 *  ListObjectsV2CommandOutput,
 *  [string, GetTextractFailedParams]
 * > | undefined} [options]
 */
export function useTextractFailedFiles(params = {pageSize: 10}, options) {
  const queryKey = _getKey({pageSize: params.pageSize})
  return useInfiniteQuery(queryKey, _queryFn, {
    keepPreviousData: true,
    getNextPageParam: lastResult => {
      if (lastResult.IsTruncated && lastResult.NextContinuationToken) {
        return lastResult.NextContinuationToken
      }
      return null
    },
    ...options,
  })
}

export function useRefreshTextractFailedFilesFn() {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries([MAIN_KEY])
}

/**
 * @typedef {import("@aws-sdk/client-s3").ListObjectsV2CommandOutput} ListObjectsV2CommandOutput
 */
