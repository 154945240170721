import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const CloseButtonIcon = (props = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="4" fill="#DDE4ED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.286 11.225 16.29 7.22a.75.75 0 0 1 1.06 1.06l-4.005 4.006 4.005 4.005a.75.75 0 0 1-1.06 1.06l-4.005-4.005-4.006 4.005a.75.75 0 1 1-1.06-1.06l4.005-4.005L7.22 8.28a.75.75 0 0 1 1.06-1.06l4.006 4.005z"
      fill="#005EB8"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const ArrowDownButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.423 9.49a.592.592 0 0 1 .838 0l2.147 2.146V3.592a.592.592 0 0 1 1.184 0v8.044L10.74 9.49a.592.592 0 0 1 .838.837l-3.158 3.158a.59.59 0 0 1-.838 0l-3.158-3.158a.592.592 0 0 1 0-.837z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const ArrowUpButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.577 7.169a.592.592 0 0 1-.838 0L8.592 5.022v8.044a.592.592 0 0 1-1.184 0V5.022L5.26 7.169a.592.592 0 0 1-.838-.838l3.158-3.158a.59.59 0 0 1 .838 0l3.158 3.158a.592.592 0 0 1 0 .838z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const AddButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 21.5c5.385 0 9.75-4.365 9.75-9.75S17.135 2 11.75 2 2 6.365 2 11.75s4.365 9.75 9.75 9.75zm0-1.5a8.25 8.25 0 1 1 0-16.5 8.25 8.25 0 0 1 0 16.5zm-1-11.25V11H8.5a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0V12.5h2.25a.75.75 0 0 0 0-1.5h-2.25V8.75a.75.75 0 0 0-1.5 0z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const RemoveButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 21.5c5.385 0 9.75-4.365 9.75-9.75S17.135 2 11.75 2 2 6.365 2 11.75s4.365 9.75 9.75 9.75zm0-1.5a8.25 8.25 0 1 1 0-16.5 8.25 8.25 0 0 1 0 16.5zM8 11.75c0-.414.387-.75.865-.75h5.77c.478 0 .865.336.865.75s-.387.75-.865.75h-5.77c-.478 0-.865-.336-.865-.75z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const RemoveBoLButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 4.5a.75.75 0 0 0-.75.75V6h3v-.75a.75.75 0 0 0-.75-.75h-1.5zM15.5 6v-.75A2.25 2.25 0 0 0 13.25 3h-1.5A2.25 2.25 0 0 0 9.5 5.25V6H5.75a.75.75 0 0 0 0 1.5h.079l1.277 11.498A2.25 2.25 0 0 0 9.342 21h6.316c1.146 0 2.11-.862 2.235-2.002L19.171 7.5h.079a.75.75 0 0 0 0-1.5H15.5zm2.162 1.5H7.338l1.259 11.332a.75.75 0 0 0 .745.668h6.316a.75.75 0 0 0 .745-.668L17.663 7.5zM11 9.75a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75zm3 0a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const NextButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      d="M9.22 7.28a.75.75 0 0 1 1.06-1.06l5 5a.75.75 0 0 1 0 1.06l-5 5a.75.75 0 1 1-1.06-1.06l4.47-4.47-4.47-4.47z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const PreviousButtonIcon = ({className = "", ...props} = {}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`svg-icon ${className}`}
  >
    <path
      d="M15.28 7.28a.75.75 0 0 0-1.06-1.06l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 1 0 1.06-1.06l-4.47-4.47 4.47-4.47z"
      fill="currentColor"
    />
  </svg>
)
