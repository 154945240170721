import {Listbox} from "@headlessui/react"
import React, {useState} from "react"
import {
  ForwardIcon,
  SolidCheckIcon,
  CheckboxIcon,
  CheckboxStatusEnum,
} from "../icons"
import {useFloating, offset, flip, shift} from "@floating-ui/react-dom"
/**
 * @template V
 * @typedef {{
 *   title: string,
 *   value: V,
 *   disabled?: boolean,
 *   icon?: React.FC<React.SVGAttributes<SVGElement>>
 * }} OptionItem
 */

const OPTION_ALL_VALUE = "__@@dropdown-all"

/**
 * @typedef {import('../icons').CheckboxStatus} CheckboxStatus
 */

/**
 * @template TSelected
 * @param {OptionItem<TSelected> | OptionItem<TSelected>[]} selectedOption
 * @param {{
 *  placeholder?: string,
 *  options?: OptionItem<TSelected>[],
 *  renderOptionTitle?: (option: OptionItem<TSelected>) => string
 * }} [props]
 * @returns
 */
const renderDropdownTitleDefault = (selectedOption, props = {}) => {
  const {placeholder, options = [], renderOptionTitle} = props
  if (Array.isArray(selectedOption)) {
    // if all the options are selected, display the placeholder
    if (
      placeholder &&
      (selectedOption.length === options.length || selectedOption.length === 0)
    ) {
      return placeholder
    }
    return selectedOption
      .map(option =>
        renderOptionTitle ? renderOptionTitle(option) : option.title,
      )
      .join(", ")
  }
  return renderOptionTitle &&
    selectedOption &&
    selectedOption.value !== OPTION_ALL_VALUE
    ? renderOptionTitle(selectedOption)
    : selectedOption?.title
}

/**
 * @template T, O
 * @typedef {Object} DropdownListProps
 * @property {OptionItem<T>[]} options - The options for the dropdown list.
 * @property {boolean} [multiple] - Indicates whether the dropdown allows multiple selections.
 * @property {boolean} [disabled] - Indicates whether the dropdown is disabled.
 * @property {string} [name] - The name of the dropdown.
 * @property {OptionItem<T> | OptionItem<T>[]} [value] - The selected option(s) for the dropdown (for controlled input).
 * @property {OptionItem<T>} [defaultOption] - The default(initial) selected option for the dropdown.
 * @property {(option: OptionItem<T>) => string} [renderOptionTitle] - A function that takes an option and returns a string to be used as the option title.
 * @property {typeof renderDropdownTitleDefault} [renderDropdownTitle] - A function that takes the Dropdown props and return the text to display on the dropdown button.
 * @property {(option: O, inputOptions: OptionItem<T>[]) => void} [onSelectOption] - A callback function that is called when an option is selected.
 * @property {string} [titleForAll] - Title for option for select all (it only works when multiple is on)
 * @property {string} [className] - class name to be added to the ListBox.Option
 * @property {string} [containerClassName] - class name to be added to the ListBox.
 * @property {string} [placeholder] - Placeholder text to display when no option is selected.
 */

/**
 * @template T, O
 * @param {DropdownListProps<T, O>} props
 */
const DropdownList = ({
  options: optionsFromProps,
  multiple,
  disabled,
  name,
  defaultOption,
  onSelectOption,
  renderOptionTitle,
  titleForAll = "All",
  renderDropdownTitle = renderDropdownTitleDefault,
  value,
  className = "",
  containerClassName = "",
  placeholder,
}) => {
  const {x, y, reference, floating, strategy} = useFloating({
    placement: "bottom-start",
    strategy: "absolute",
    middleware: [offset(4), flip(), shift()],
  })

  /**
   * @type {OptionItem<T | null | string>[]}
   */
  let options = optionsFromProps
  if (multiple) {
    options = [
      {title: titleForAll, value: OPTION_ALL_VALUE, disabled: false},
      ...optionsFromProps,
    ]
  } else if (placeholder) {
    options = [
      {title: placeholder, value: null, disabled: true},
      ...optionsFromProps,
    ]
  }

  const [internalSelectedOption, setSelectedOption] = useState(
    () => value ?? (multiple ? optionsFromProps : defaultOption || options[0]),
  )

  const selectedOption = value ?? internalSelectedOption

  /**
   * @param {OptionItem<T> | OptionItem<T>[]} newSelectedOption
   */
  const handleOnChange = newSelectedOption => {
    let nextSelectedOptions = newSelectedOption

    if (Array.isArray(newSelectedOption)) {
      nextSelectedOptions = newSelectedOption.filter(
        o => o.value !== OPTION_ALL_VALUE,
      )

      // this change is caused by the press of "All option"
      if (newSelectedOption.length !== nextSelectedOptions.length) {
        if (nextSelectedOptions.length === 0) {
          // select all
          nextSelectedOptions = optionsFromProps
        } else {
          // select none
          nextSelectedOptions = []
        }
      }
    }

    // @ts-ignore
    onSelectOption?.(nextSelectedOptions, optionsFromProps)
    setSelectedOption(nextSelectedOptions)
  }

  const optionElements = options.map(option => {
    const Icon = option.icon || React.Fragment
    // for multiple selection, there could be various status
    let allSelectStatus = /**@type {CheckboxStatus} */ (CheckboxStatusEnum.none)
    if (multiple && Array.isArray(selectedOption)) {
      allSelectStatus =
        selectedOption.length === 0
          ? CheckboxStatusEnum.none
          : selectedOption.length >= optionsFromProps.length
          ? CheckboxStatusEnum.all
          : CheckboxStatusEnum.some
    }

    return (
      <Listbox.Option
        key={`${option.value}`}
        value={option}
        disabled={option.disabled}
        className={`
          flex flex-row items-center whitespace-nowrap
          px-3 py-1 gap-4
          ui-disabled:text-grey-70 ui-not-disabled:hover:bg-gray-3
          ${multiple ? "justify-start" : "justify-between"}
          ${className}
        `}
      >
        {({selected}) => (
          <>
            {multiple ? (
              <CheckboxIcon
                status={
                  option.value === OPTION_ALL_VALUE
                    ? allSelectStatus
                    : selected
                    ? CheckboxStatusEnum.all
                    : CheckboxStatusEnum.none
                }
                className="svgIcon text-blue"
              />
            ) : null}
            <span className="inline-flex items-center flex-row gap-2">
              <Icon />
              {renderOptionTitle && option.value !== OPTION_ALL_VALUE
                ? // @ts-ignore
                  renderOptionTitle(option)
                : option.title}
            </span>
            {multiple ? null : (
              <SolidCheckIcon className="svgIcon invisible text-blue ui-selected:visible" />
            )}
          </>
        )}
      </Listbox.Option>
    )
  })

  return (
    <Listbox
      as="div"
      className={`relative text-sm h-8 ${containerClassName}`}
      multiple={multiple}
      name={name}
      value={selectedOption}
      onChange={handleOnChange}
      disabled={disabled}
    >
      <Listbox.Button
        name={name}
        ref={reference}
        className={`
            button flex justify-between items-center w-full h-full pl-3 pr-2 gap-1
            font-medium rounded border cursor-default focus:outline-none
            bg-white text-black border-gray-3 ui-not-disabled:hover:border-primary disabled:text-grey-70
          `}
      >
        <span className="truncate">
          {renderDropdownTitle(
            // @ts-ignore
            selectedOption,
            {
              placeholder,
              renderOptionTitle,
              options: optionsFromProps,
            },
          )}
        </span>
        <ForwardIcon className="svg-icon pointer-events-none rotate-90 ui-open:-rotate-90" />
      </Listbox.Button>
      <Listbox.Options
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          width: "max-content",
        }}
        className={`
            flex flex-col py-2 min-w-full gap-1
            rounded-md shadow-lg z-50 cursor-default
            bg-white border border-gray-3
            max-h-80 overflow-y-auto focus:outline-none
            `}
      >
        {optionElements}
      </Listbox.Options>
    </Listbox>
  )
}

export default DropdownList
