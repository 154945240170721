import React from "react"
import {Document, Page} from "react-pdf/dist/esm/entry.webpack5.js"

export const loadingElement = (
  <div className="flex flex-1 items-center h-full flex-col">
    <div className="relative mt-[18%] h-[283px] w-[257px] bg-gray-3/30 rounded-2xl flex items-center flex-col animate-pulse">
      <div className="w-[80%] mt-[70%] h-3 bg-gray-3 rounded-sm "></div>
      <div className="w-[60%] mt-4 h-3 bg-gray-3 rounded-sm "></div>
    </div>
    <div className="mt-10 text-xl font-medium text-grey">Loading...</div>
  </div>
)

/**
 * @type {React.FC<{
 *  url: string
 *  height: number
 *  width: number
 * }>}
 */
const PreviewPDF = ({url, height, width}) => {
  return (
    <Document
      file={url}
      renderMode="canvas"
      // TODO: handle state message
      // noData={""}
      loading={loadingElement}
      error={""}
    >
      <Page
        pageNumber={1}
        height={height}
        width={width}
        error=""
        loading={loadingElement}
      />
    </Document>
  )
}

export default PreviewPDF
