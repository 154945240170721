import React from "react"
import {useTranslation} from "react-i18next"

import {ProcessState} from "services/uploadFile"

import {
  SolidCircleCheckIcon,
  SolidCircleExclamationIcon,
} from "components/shared/icons"
import {ProgressXMarkIcon} from "components/shared/icons/xMark"

/**
 * @type {React.FC<{
 *  state: string
 *  progress?: number
 * }>}
 */
const ProcessStateIcon = ({state, progress = 0}) => {
  let icon
  switch (state) {
    case ProcessState.Idle:
    case ProcessState.Pending: {
      icon = <ProgressXMarkIcon progress={progress} />
      break
    }
    case ProcessState.Success: {
      icon = <SolidCircleCheckIcon className="text-primary" />
      break
    }
    case ProcessState.Failed: {
      icon = <SolidCircleExclamationIcon className="text-[#C5283D]" />
      break
    }
    default: {
      icon = null
    }
  }
  return icon
}

export default ProcessStateIcon
