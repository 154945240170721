import React from "react"
import {
  QueryCache,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"

const isProduction = process.env.NODE_ENV === "production"

const devLog = isProduction
  ? () => undefined
  : (/**@type {Parameters<Console['log']>} */ ...args) => {
      if (process.env.NODE_ENV === "development") {
        console.log(...args)
      }
    }

const queryCache = new QueryCache({
  onError: (error, query) => {},
  onSuccess: (data, query) => {},
})
const mutationCache = new MutationCache({
  onError: (error, variables, context, mutation) => {
    devLog("[mutationCache] onError", variables)
  },
  onSuccess: (data, variables, context, mutation) => {
    devLog("[mutationCache] onSuccess", variables)
  },
})

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      // set this to make the react-query@4 have the same behavior with v3
      // (before we know more about it)
      networkMode: "offlineFirst",
    },
    mutations: {
      retry: false,
      // set this to make the react-query@4 have the same behavior with v3
      // (before we know more about it)
      networkMode: "offlineFirst",
    },
  },
})

/**
 * @type {React.FC<{
 *   children: React.ReactNode,
 * }>}
 */
export const QueryProvider = ({children}) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export * from "@tanstack/react-query"

/**
 * @template T
 * @typedef {import('axios').AxiosError<T>} AxiosError
 */

/**
 * @template TParam
 * @template [TQueryFnData=unknown]
 * @template [TData=TQueryFnData]
 * @template [TError=Error|AxiosError<TData>]
 * @typedef {Omit<import('@tanstack/react-query').UseQueryOptions<TQueryFnData, TError, TData, [string, TParam]>, 'queryKey' | 'queryFn'>} PUseQueryOptions
 */
