import create from "zustand"
import shallow from "zustand/shallow"

/**
 * @type {import('./types').useDropFileModalStore}
 */
export const useDropFileModal = create(set => ({
  showModal: false,
  openModal: () => set({showModal: true}),
  closeModal: () => set({showModal: false}),
  toggleModal: () => set(state => ({showModal: !state.showModal})),
}))

export function openDropFileModal() {
  useDropFileModal.getState().openModal()
}

export function closeDropFileModal() {
  useDropFileModal.getState().closeModal()
}

export function toggleDropFileModal() {
  useDropFileModal.getState().toggleModal()
}

export function useControlDropFileModal() {
  return useDropFileModal(
    state => ({
      openModal: state.openModal,
      closeModal: state.closeModal,
    }),
    shallow,
  )
}
