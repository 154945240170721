import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
const ICError = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={10} cy={10} r={8} fill="#99B5CC" />
      <path
        d="M9.124 11.822h1.652L11 5.06H8.886l.238 6.762Zm2.058 2.128c0-.644-.504-1.148-1.19-1.148-.714 0-1.218.504-1.218 1.148 0 .644.504 1.148 1.218 1.148.686 0 1.19-.504 1.19-1.148Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default ICError
