import * as React from "react"
import shallow from "zustand/shallow"

import {useEditBoLModal} from "lib/store/useEditBoLModal"
import {useUploadedFileURL} from "services/uploadFile"

import {openInNewTab} from "utils/openURL"

import UploadedFilesViewerLayout from "./UploadedFilesViewer.layout"

const UploadedFilesViewer = () => {
  const [showModal, bolRowData, fileIndex, setFileIndex] = useEditBoLModal(
    state => [
      state.showModal,
      state.bolRowData,
      state.fileIndex,
      state.setFileIndex,
    ],
    shallow,
  )

  const {data: url, fetchStatus} = useUploadedFileURL(
    {
      bolId: bolRowData?.bolSN?.id,
      fileName: bolRowData?.uploadedFiles?.[fileIndex],
    },
    {
      enabled: showModal,
    },
  )

  return (
    <UploadedFilesViewerLayout
      url={url}
      fileName={bolRowData?.uploadedFiles?.[fileIndex] ?? ""}
      isLoading={fetchStatus === "fetching"}
      onRequestOpenFile={openInNewTab}
      onRequestDeleteFile={input => {
        console.log("# onRequestDeleteFile", input)
      }}
      nextAble={!!bolRowData && fileIndex < bolRowData.uploadedFiles.length - 1}
      prevAble={
        !!bolRowData && fileIndex > 0 && bolRowData.uploadedFiles.length > 0
      }
      onNextFile={() => {
        setFileIndex(fileIndex + 1)
      }}
      onPrevFile={() => {
        setFileIndex(fileIndex - 1)
      }}
    />
  )
}

export default UploadedFilesViewer
