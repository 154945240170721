import React from "react"
import {useTranslation} from "react-i18next"

import {BoLStatus, BoLShipTypeEnum} from "@domain"
import {getPortOrAirport, getVoyageOrFlightNo, getVessel} from "domain/bol-meta"
import {formatUTCDate, formatCDDate} from "domain/date-format"

import BoLStatusIcon from "components/shared/icons/BoLStatusIcon"

import {useEditedShipType} from "./bol-edit.hook"

import SaveButton from "./SaveBolEditButton"
import VendorTextInput from "./BolVendorTextInput"
import CarrierSelect from "./BolCarrierSelector"
import ContainersEditor from "./BolContainersEditor"
import AddContainerButton from "./BolAddContainerButton"
import ShipTypeSelect from "./BolShipTypeSelector"

/**
 * @type {React.FC<{
 *  data: import("domain/bol").BillOfLading
 *  className?: string
 * }>}
 */
const BoLStatusEditPanel = ({data, className = ""}) => {
  const {t} = useTranslation()
  const editedShipType = useEditedShipType()

  if (!data) {
    return null
  }

  const labelClassName = "mb-4"
  const labelTextClassName = "text-xs text-grey mb-1.5 block"
  return (
    <section className={`relative ${className} overflow-hidden`}>
      <header className=" flex flex-row justify-between items-center mr-10">
        <span className="text-grey text-xl font-medium">{`${t("bol")}: ${
          data.bolSN.id
        }`}</span>
        <SaveButton />
      </header>

      <hr className="border-gray-3 h-px mt-0.5 mb-5 mr-10" />

      {/* 67 px is the {header + hr} height above */}
      <div className="overflow-auto pr-10 h-[calc(100%-67px)]">
        <div className={labelClassName}>
          <label htmlFor="vendor" className={labelTextClassName}>
            {t("vendor")}
          </label>
          <VendorTextInput />
        </div>

        <div className={labelClassName}>
          <label htmlFor="shipType" className={labelTextClassName}>
            {t("shipType")}
          </label>
          <ShipTypeSelect />
        </div>

        <div className={labelClassName}>
          <label htmlFor="carrier" className={labelTextClassName}>
            {t("carrier")}
          </label>
          <CarrierSelect />
        </div>

        {/* container ids */}
        {editedShipType === BoLShipTypeEnum.Sea ? (
          <section className="mb-10">
            <header className={labelTextClassName}>{t("containerSN")}</header>
            <ContainersEditor />

            <div className="flex flex-row justify-start items-center">
              <AddContainerButton className="mr-2" />
              <p className="text-sm font-medium text-grey">
                {t("btnAddContainer")}
              </p>
            </div>
          </section>
        ) : (
          <div className="mb-10" />
        )}

        {/* ship type and status  */}
        <section className="text-grey text-sm gap-y-4 border rounded border-gray-3 py-2 px-4 mb-7">
          <div className="grid grid-flow-dense grid-rows-[min-content,1fr] grid-cols-[1fr,2fr] gap-x-3 gap-y-4">
            <CellLabel>Status</CellLabel>
            <div>
              <BoLStatusIcon status={data.status} className="inline" />
              <span className="inline-block align-middle ml-2">
                {t(`bolStatusList.${data.status}`)}
              </span>
              {data.status === BoLStatus.Error && data.registerMessage ? (
                <div className="text-xs text-error mt-2">
                  {data.registerMessage}
                </div>
              ) : null}
            </div>

            <CellLabel>{t("bolUploadOn")}</CellLabel>
            <div>
              {getDateStringOrEmpty(data.uploadTime, "yyyy/MM/dd, hh:mm:ss")}
            </div>

            <CellLabel>{t("eShippingUpdateOn")}</CellLabel>
            <div>
              {getDateStringOrEmpty(data.updateTime, "yyyy/MM/dd, hh:mm:ss")}
            </div>
          </div>
        </section>

        {/* shipping dates status */}
        <ShippingDatesSection data={data} />

        {/* departure/arrival/import tab */}
        <InfoTab data={data} />
      </div>
    </section>
  )
}

/**
 * @description the information of
 * atd/etd/ata/eta
 * @type {React.FC<{
 *  data: import("domain/bol").BillOfLading
 * }>}
 */
const ShippingDatesSection = ({data}) => {
  const {t} = useTranslation()

  const keys = ["ATD", "ETD", "ATA", "ETA"]

  /**
   * @param {Date | undefined} d
   * @returns
   */
  const isEmptyDate = d => !d || !d.valueOf()

  // if date has no data, don't display this section
  if (
    keys.every(key =>
      // @ts-ignore
      isEmptyDate(data[key]),
    )
  ) {
    return null
  }

  return (
    <section className="grid grid-cols-2 grid-flow-row text-grey text-sm gap-y-4 border rounded border-gray-3 py-4 px-4 mb-7">
      {keys.map(key => (
        <p
          key={key}
          className="col-span-1 grid grid-cols-4 items-center gap-x-5"
        >
          <span className="text-xs font-bold text-grey col-span-1">
            {t(key)}
          </span>
          <span className="col-span-3">
            {getDateStringOrEmpty(
              // @ts-ignore
              data[key],
              "yyyy/MM/dd",
            )}
          </span>
        </p>
      ))}
    </section>
  )
}

/**
 * @type {React.FC<{
 *  data: import("domain/bol").BillOfLading
 * }>}
 */
const InfoTab = ({data}) => {
  const {t} = useTranslation()

  const [activeTabIndex, setActiveTabIndex] = React.useState(() => {
    if (data.atdRaw || data.etdRaw) return 0
    if (data.ataRaw || data.etaRaw) return 1
    return 2
  })

  const tabs = Object.entries({
    departure: ["atdRaw", "etdRaw"],
    arrival: ["ataRaw", "etaRaw"],
    importDeclaration: ["importDeclaration"],
  })

  // if has no data, don't display this section
  if (
    tabs.every(([_, keys]) =>
      // @ts-ignore
      keys.every(k => !data[k]),
    )
  ) {
    return null
  }

  return (
    <div>
      <div className="flex flex-row gap-x-3 mb-3">
        {tabs.map(([tabName, keys], i) => (
          <TabPill
            // @ts-ignore
            disabled={keys.every(k => !data[k])}
            active={i === activeTabIndex}
            key={tabName}
            onClick={() => setActiveTabIndex(i)}
          >
            {t(`bolPanelTab.${tabName}`)}
          </TabPill>
        ))}
      </div>
      <div className="text-grey text-sm gap-y-4 border rounded border-gray-3 py-2 px-4 mb-2">
        {activeTabIndex === 0 ? (
          <AtaAtdTable
            shippingType={data.shippingType}
            shippingInfo={data.atdRaw ?? data.etdRaw ?? undefined}
          />
        ) : activeTabIndex === 1 ? (
          <AtaAtdTable
            shippingType={data.shippingType}
            shippingInfo={data.ataRaw ?? data.etaRaw ?? undefined}
          />
        ) : (
          <ImportTable data={data} />
        )}
      </div>
    </div>
  )
}

/**
 * @param {{
 *   shippingInfo?: import('@domain').BoL_ShippingInfo,
 *   shippingType: string | undefined
 * }} param0
 */
const AtaAtdTable = ({shippingInfo, shippingType}) => {
  const {t} = useTranslation()
  return (
    <div className="grid grid-flow-dense grid-rows-[min-content,1fr] grid-cols-[1fr,2fr] gap-x-3 gap-y-4">
      <CellLabel>{t("shippingInfo.locationNm")}</CellLabel>
      <div>{getPortOrAirport(shippingInfo)}</div>
      {shippingType === BoLShipTypeEnum.Air ? null : (
        <>
          <CellLabel>{t("shippingInfo.vessel")}</CellLabel>
          <div>{getVessel(shippingInfo, shippingType)}</div>
        </>
      )}
      <CellLabel>{t("shippingInfo.voyage")}</CellLabel>
      <div>{getVoyageOrFlightNo(shippingInfo, shippingType)}</div>
    </div>
  )
}

/**
 * @type {React.FC<{
 *  data: import("domain/bol").BillOfLading
 * }>}
 */
const ImportTable = ({data}) => {
  const {t} = useTranslation()
  return (
    <div className="grid grid-flow-dense grid-rows-[min-content,1fr] grid-cols-[1fr,2fr] gap-x-3 gap-y-4">
      <CellLabel>{t("importInfo.importDeclaration")}</CellLabel>
      <div className="break-all">{data.importDeclaration}</div>

      <CellLabel>{t("importInfo.customsClearanceDate")}</CellLabel>
      <div>{formatCDDate(data.customsClearanceDate, "yyyy/MM/dd")}</div>

      <CellLabel>{t("importInfo.invoices")}</CellLabel>
      <div>
        {data.invoices.map((invoice, i) => (
          <div key={`${invoice}-${i}`}>{invoice}</div>
        ))}
      </div>
    </div>
  )
}

/**
 *
 * @param {{
 *   children?: string | React.ReactNode
 *   active?: boolean
 *   disabled?: boolean
 *   onClick?: import("react").DOMAttributes<HTMLButtonElement>["onClick"]
 * }} param0
 * @returns
 */
const TabPill = ({
  children = "Label",
  active = false,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        active ? "bg-gray-3 text-blue font-medium" : ""
      } enabled:hover:bg-gray-3 enabled:hover:font-medium disabled:text-grey disabled:opacity-40 text-sm rounded py-1 px-4`}
    >
      {children}
    </button>
  )
}

/**
 * @description a simple wrapper for table td for label
 * @param {{
 *   children?: string | React.ReactNode,
 *   className?: string
 * }} param0
 */
const CellLabel = ({children = "Label", className = ""}) => {
  return (
    <div
      className={`text-xs font-bold text-grey whitespace-nowrap ${className}`}
    >
      <span className="align-sub">{children}</span>
    </div>
  )
}

/**
 * @param {Date | null | undefined} date
 * @param {string} format
 */
const getDateStringOrEmpty = (date, format) => {
  if (!date || +date < 100) return ""
  return formatUTCDate(date, format)
}

export default BoLStatusEditPanel
