import React from "react"
import {useTranslation} from "react-i18next"

import {useDroppedFiles} from "lib/store/useDroppedFiles"
import {
  useBolUploadingWindow,
  toggleBolUploadingWindowCollapse,
} from "lib/store/useBolUploadingWindow"
import {DropFileTypeEnum} from "lib/store/useDroppedFiles"

import {FileUploadingWindow} from "./UploadingWindow.layout"
import {BoLUploadingList} from "./BoLUploadingList"
import {useBoLUploadingList} from "./BoLUploadingList.hook"

/**
 * @type {React.FC<{}>}
 */
const UploadingWindowContainer = () => {
  const {t} = useTranslation()
  const fileType = useDroppedFiles(state => state.fileType)
  const {showWindow, collapseWindow} = useBolUploadingWindow()
  const {messageKey, allAnalyzingFiles, allUploadingFiles} =
    useBoLUploadingList({enable: fileType === DropFileTypeEnum.BOL})

  if (!showWindow) return null

  return (
    <FileUploadingWindow
      collapse={collapseWindow}
      onToggleCollapse={toggleBolUploadingWindowCollapse}
      previewMessage={t(...messageKey)}
      mdFixedWith
    >
      <BoLUploadingList
        uploadHandlers={allUploadingFiles}
        convertHandlers={allAnalyzingFiles}
      />
    </FileUploadingWindow>
  )
}

export default UploadingWindowContainer
