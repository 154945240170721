import React from "react"

import {Portal} from "./Portal"

// TODO: trap focus event in modal (if needed)

/**
 * @type {React.FC<React.PropsWithChildren<{
 *  visible: boolean
 *  backdropEvent?: boolean
 *  onRequestClose?: (event: any) => void
 * }>>}
 */
export const Modal = ({
  visible,
  backdropEvent = true,
  onRequestClose,
  children,
}) => {
  const modalRef = React.useRef(/** @type {HTMLDivElement?} */ (null))
  const idRef = React.useRef(getRandomIDfromMath())

  /** @type {React.CSSProperties} */
  const displayingStyle = {
    display: visible ? "block" : "none",
  }

  React.useEffect(() => {
    if (visible) {
      /** @param {KeyboardEvent} event */
      const handleModalKeydown = event => {
        try {
          if (event.key === "Escape") {
            const elements = event.composedPath().filter(isElement)

            const modalElIndex = elements.findIndex(
              el => el === modalRef.current,
            )
            const isFocusInsideModal = modalElIndex > 0

            const portalElIndex = elements.findIndex(el => el.id === "portal")
            const isUnderSameContainer =
              portalElIndex > 0 &&
              elements[portalElIndex - 1].id === idRef.current

            // console.log(
            //   modalElIndex,
            //   isFocusInsideModal,
            //   isUnderSameContainer,
            //   idRef.current,
            // )
            if (!isFocusInsideModal && isUnderSameContainer) {
              onRequestClose?.(event)
            }
          }
        } catch (error) {
          console.error("modal keydown error:", error)
        }
      }
      window.addEventListener("keydown", handleModalKeydown)
      return () => {
        window.removeEventListener("keydown", handleModalKeydown)
      }
    }
  }, [visible, onRequestClose])

  const BackDrop = (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      tabIndex={0}
      role="button"
      style={{
        ...displayingStyle,
        pointerEvents: backdropEvent ? "auto" : "none",
      }}
      className="backdrop fixed inset-0 cursor-default z-50"
      onClick={onRequestClose}
      onKeyDown={event => {
        if (backdropEvent && event.key === "Escape") {
          onRequestClose?.(event)
        }
      }}
    ></div>
  )

  return (
    <Portal id={idRef.current}>
      {BackDrop}
      <div
        ref={modalRef}
        tabIndex={-1}
        role="dialog"
        style={displayingStyle}
        className="modal"
      >
        {children}
      </div>
    </Portal>
  )
}

function getRandomIDfromMath() {
  return Math.random().toFixed(10).substring(2)
}

/**
 * @param {any} target
 * @returns {target is Element}
 */
function isElement(target) {
  return typeof target.tagName !== "undefined"
}
