import * as React from "react"

import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"
import TextInput from "components/shared/fields/TextInputField"

import {useUpdateVendor, useBolInitValue} from "./bol-edit.hook"

/**
 * @description the vendor input editor
 */
export const VendorTextInput = ({}) => {
  const update = useUpdateVendor()
  const bol = useBolInitValue()
  const isUpdating = useIsUpdatingEditableBillOfLading()

  return (
    <TextInput
      name="vendor"
      defaultValue={bol.vendor}
      onChange={e => update(e.target.value)}
      disabled={isUpdating}
    />
  )
}

export default VendorTextInput
