import * as React from "react"
import {useTranslation} from "react-i18next"

import {useConfirmModal} from "lib/store/useConfirmModal"
import {useIsValidateCustomDeclaration} from "services/custom-declaration"

import {CloseButtonIcon} from "components/shared/icons/btn"

/**
 * @type {React.FC<{
 *  onRequestClose?: () => void
 * }>}
 */
export const CloseCDUploadModalButton = ({onRequestClose}) => {
  const {t} = useTranslation()
  const createConfirm = useConfirmModal(state => state.createConfirm)
  const isValidating = useIsValidateCustomDeclaration()

  return (
    <button
      className="absolute right-4 top-4"
      onClick={() => {
        if (isValidating) {
          createConfirm(t("confirmLeave"), {
            confirmLabel: t("btnLeave"),
            cancelLabel: t("btnStay"),
            onConfirm: () => {
              onRequestClose?.()
            },
            onCancel: () => {},
          })
        } else {
          onRequestClose?.()
        }
      }}
      data-testid="ic-close"
    >
      <CloseButtonIcon />
    </button>
  )
}

export default CloseCDUploadModalButton
