import {useEffect, useMemo, useRef, useState} from "react"

/**
 * @typedef {Omit<DOMRectReadOnly, 'toJSON'>} ObserverRect
 */

/** @type {ObserverRect} */
const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

const browser = typeof window !== "undefined"

/**
 * @template {HTMLElement} [T=any]
 * @returns
 */
export function useResizeObserver() {
  const frameID = useRef(0)
  const ref = useRef(/** @type {T?} */ (null))

  const [rect, setRect] = useState(defaultState)

  const observer = useMemo(
    () =>
      browser
        ? new ResizeObserver(entries => {
            const entry = entries[0]
            // console.log(entry)

            if (entry) {
              cancelAnimationFrame(frameID.current)

              frameID.current = requestAnimationFrame(() => {
                if (ref.current) {
                  setRect(entry.contentRect)
                }
              })
            }
          })
        : null,
    [],
  )

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current)
    }

    return () => {
      observer?.disconnect()

      if (frameID.current) {
        cancelAnimationFrame(frameID.current)
      }
    }
  }, [ref.current])

  return /** @type {const} */ ([ref, rect])
}

/**
 * @returns
 */
export function useElementSize() {
  const [ref, {width, height}] = useResizeObserver()
  return {ref, width, height}
}
