import {useEffect, useRef} from "react"
import {createPortal} from "react-dom"

const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null

/**
 * @type {React.FC<React.PropsWithChildren<{
 *  id?: string
 * }>>}
 */
export const Portal = ({children, id}) => {
  const el = useRef(
    typeof document !== "undefined" ? document.createElement("div") : null,
  )

  useEffect(() => {
    if (el.current) {
      const containerEl = el.current
      if (id) {
        containerEl.id = id
      }
      portalRoot?.appendChild(containerEl)
      return () => {
        portalRoot?.removeChild(containerEl)
      }
    }
  }, [id])

  if (el.current) {
    return createPortal(children, el.current)
  } else {
    return null
  }
}
