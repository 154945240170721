import type {UseBoundStore, StoreApi} from "zustand"
import type {BillOfLading} from "domain/bol"

export enum DropFileTypeEnum {
  BOL = "BOL",
  Customs = "Customs",
}
export type DropFileType = `${DropFileTypeEnum}`

export type ModalSlice = {
  showModal: boolean
  openModal: () => void
  closeModal: () => void
  toggleModal: () => void
}

export type FileListSlice = {
  files: File[]
  resetFiles: () => void
  filterFiles: (fileFilter: (file: File) => boolean) => void
  addFiles: (newFiles: File[]) => void
}

export type useDropFileModalStore = UseBoundStore<StoreApi<ModalSlice>>

export type useDroppedFiles = UseBoundStore<
  StoreApi<
    FileListSlice & {site: string | null; setSite: (site: string) => void} & {
      fileType: DropFileType
      setFileType: (fileType: DropFileType) => void
    }
  >
>

export type useEditBoLModal = UseBoundStore<
  StoreApi<
    ModalSlice & {
      bolRowData: BillOfLading | null
      setBoLRowData: (row: BillOfLading) => void
      fileIndex: number
      setFileIndex: (index: number) => void
    }
  >
>

export type ConfirmHandler = {
  /** @description default is OK */
  confirmLabel?: string
  /** @description default is cancel */
  cancelLabel?: string
  /** @description optional, callback handler when confirm button is pressed, confirm button will always display */
  onConfirm?: () => void
  /** @description optional, callback handler when cancel button is pressed, if onCancel is not provided, then it will not display cancel button */
  onCancel?: () => void
}
export type useConfirmModal = UseBoundStore<
  StoreApi<
    ModalSlice & {
      message: string
      handler: ConfirmHandler | null
      createConfirm: (message: string, handler?: ConfirmHandler) => void
    }
  >
>

export type LoadingModalParam = {
  name: string | symbol
  initialLoading?: boolean
}

export type LoadingModalRef = string

export type LoadingModalStore = UseBoundStore<
  StoreApi<{
    modalVisible: Record<string | symbol, boolean | undefined>
    openModal: (id?: LoadingModalRef) => void
    closeModal: (id?: LoadingModalRef) => void
    toggleModal: (id?: LoadingModalRef) => void
  }>
>

export type BolUploadingWindowStore = UseBoundStore<
  StoreApi<{
    showWindow: boolean
    collapseWindow: boolean
  }>
>

export type BolCsvPrecheckModalStore = UseBoundStore<
  StoreApi<{
    showModal: boolean
    openModal: () => void
    closeModal: () => void
  }>
>
