import * as React from "react"

/**
 * @typedef {object} RadioOwnProps
 * @property {string} label
 */

/**
 * @type {React.FC<
 *  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,HTMLInputElement> & RadioOwnProps
 * >}
 */
const Radio = ({id, label, ...props}) => {
  const _id = React.useId()
  const radioLabel = label || props["aria-label"]

  return (
    <div className="flex flex-row items-center">
      <input type="radio" {...props} id={id ?? _id} />
      <label
        className="ml-1 text-grey text-sm font-medium min-w-[164px]"
        htmlFor={id ?? _id}
      >
        {radioLabel}
      </label>
    </div>
  )
}

export default Radio
