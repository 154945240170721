import {z} from "zod"
import sortedUniq from "lodash-es/sortedUniq"

import {useMutation, useIsMutating} from "lib/query"
import {giantRequest} from "lib/apiClient"

import {BoLShipTypeZEnum} from "domain/bol-meta"
import {useRefreshDistinctFn} from "services/bol/distinct"
import {useRefreshBillOfLadingsFn} from "services/bol/bols"

const MAIN_MUTATION_KEY = ["UpdateEditableBillOfLading"]

const UpdateEditableBillOfLadingParam = z.object({
  /**
   * @example
   * "DWTCICBHX3751201"
   */
  id: z.string(),
  /**
   * @example
   * "GIANT(KUNSHAN) CO.,LTD"
   */
  vendor: z.string(),
  /**
   * @example
   * "Sea"
   */
  transportation: BoLShipTypeZEnum.optional(),
  /**
   * @example
   * "Container Lines Company Limited"
   */
  carrier: z.string(),
  /**
   * @example
   * ["TLLU4633575", "TLLU4633335"]
   */
  containerIds: z.array(z.string()),
})
/** @typedef {z.infer<typeof UpdateEditableBillOfLadingParam>} UpdateEditableBillOfLadingParam */

/**
 * @description
 *   api request to update the editable bol data
 * @param {UpdateEditableBillOfLadingParam} updateParam
 * @param {AxiosRequestConfig} options
 * @returns {Promise<UpdateEditableBillOfLadingParam>}
 */
export async function updateEditableBillOfLading(updateParam, options = {}) {
  const processParam = {
    ...updateParam,
    containerIds: sortedUniq(updateParam.containerIds.sort()).filter(
      s => s.length > 0,
    ),
  }

  /** @type {import('axios').AxiosResponse<string>} */
  await giantRequest({
    ...options,
    url: `/editor/bols/${updateParam.id}`,
    method: "PUT",
    data: {
      ...processParam,
      transportation: processParam.transportation ?? "",
    },
  })

  return processParam
}

/**
 * @description hook for update editable bol
 * @param {import('lib/query').UseMutationOptions<
 *  Awaited<ReturnType<updateEditableBillOfLading>>,
 *  import('axios').AxiosError<{}>,
 *  UpdateEditableBillOfLadingParam,
 *  undefined
 * >=} options
 */
export function useUpdateEditableBillOfLading(options = {}) {
  const {onSettled, ...restOptions} = options
  const refreshDistinct = useRefreshDistinctFn()
  const refreshBOL = useRefreshBillOfLadingsFn()
  return useMutation(updateEditableBillOfLading, {
    ...restOptions,
    mutationKey: MAIN_MUTATION_KEY,
    onSettled: (...args) => {
      refreshDistinct()
      refreshBOL()
      onSettled?.(...args)
    },
  })
}

/**
 * @description
 *   hook for checking if it is updating
 */
export function useIsUpdatingEditableBillOfLading() {
  return !!useIsMutating({
    mutationKey: MAIN_MUTATION_KEY,
  })
}

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
