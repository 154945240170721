import React from "react"
import {useTranslation} from "react-i18next"

import BolCsvPrecheckModalLayout from "./BolCsvPrecheckModal.layout"
import {useBolCsvPrecheckModal} from "lib/store/useBolCsvPrecheckModal"
import {useConfirmModal} from "lib/store/useConfirmModal"
import useBolCsvPrecheck from "./BolCsvPrecheck.hook"

const BolCsvPrecheckModal = () => {
  const {t} = useTranslation()
  const {showModal, closeModal} = useBolCsvPrecheckModal()
  const createConfirm = useConfirmModal(state => state.createConfirm)
  const {startConvertBoL, isDoingRequest, validateResult, resultHasError} =
    useBolCsvPrecheck()

  const handleUpload = () => {
    startConvertBoL()
    closeModal()
  }

  const handleOnCloseModal = () => {
    if (!isDoingRequest) {
      closeModal()
    } else {
      createConfirm(t("confirmLeave"), {
        confirmLabel: t("btnLeave"),
        cancelLabel: t("btnStay"),
        onConfirm: () => {
          closeModal()
        },
        onCancel: () => {},
      })
    }
  }

  return (
    <BolCsvPrecheckModalLayout
      visible={showModal}
      onClose={handleOnCloseModal}
      isLoading={isDoingRequest}
      result={validateResult}
      uploadable={!resultHasError}
      onUpload={handleUpload}
    />
  )
}

export default BolCsvPrecheckModal
