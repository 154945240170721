import React from "react"
import {useTranslation} from "react-i18next"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"

import {useRefreshTextractFailedFilesFn} from "services/textract-failed"
import Header from "../navigations/MainNavigation"

/**
 * @type {React.FC<{
 *  children: React.ReactNode,
 *  className?: string
 * }>}
 */
const Layout = ({children}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteEnv
          }
        }
      }
    `,
  )

  const {t} = useTranslation()
  const invalidateErrorList = useRefreshTextractFailedFilesFn()
  const title = site.siteMetadata?.title ?? "Giant eShipping"
  const siteEnv = site.siteMetadata?.siteEnv ?? ""

  const links = [
    {title: t("siteName"), url: "/"},
    {
      title: t("navErrorList"),
      url: "/error-list/",
      onClick: invalidateErrorList,
    },
  ]

  return (
    <>
      <Helmet
        // htmlAttributes={{
        //   lang="en",
        // }}
        title={title}
        meta={
          [
            // {
            //   name: `description`,
            //   content: '',
            // },
          ]
        }
      />
      <Header links={links} siteEnv={siteEnv} />
      {children}
    </>
  )
}

export default Layout
