import axios from "axios"
import {navigate} from "gatsby"
import {isBrowser} from "domain/browser"

import {oauthToken} from "./oauth"

// axios.interceptors.request.use()
// axios.interceptors.response.use()

export const baseRequest = axios.create()

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */

const apiHost = process.env.API_HOST
const apiBaseURL = process.env.API_BASE_URL || ""
const awsApiKey = process.env.AWS_API_KEY || ""

/** @type {AxiosRequestConfig} */
const giantOptions = {
  baseURL: apiHost ? `${apiHost}${apiBaseURL}` : undefined,
  headers: {
    "x-api-key": awsApiKey,
  },
}

export const giantAxiosClient = axios.create(giantOptions)

/**
 * @param {import('axios').AxiosRequestConfig<unknown>} config
 */
const _authorizeConfig = async config => {
  try {
    const session = await oauthToken()
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
    }
  } catch (err) {
    if (isBrowser) {
      navigate("/auth/login")
    }
    throw err
  }
}

giantAxiosClient.interceptors.request.use(_authorizeConfig)

/**
 * @template TData
 * @param {import('axios').AxiosRequestConfig<TData>} config
 */
export async function giantRequest(config) {
  return giantAxiosClient(config).catch(async err => {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === 401) {
        if (isBrowser) {
          navigate("/auth/login")
        }
      }
      throw err
    } else {
      throw err
    }
  })
}
