import * as React from "react"
import Lottie from "lottie-react"

// @ts-ignore
import loadingAnimation from "assets/lotties/loading.json"

/**
 *
 * @param {Omit<import('lottie-react').LottieComponentProps, "animationData">=} props
 * @returns
 */
export const Loading = props => {
  const size = props?.size ?? 16
  return (
    <Lottie
      data-test-id="ic-loading"
      style={{
        width: size,
        height: size,
      }}
      {...props}
      animationData={loadingAnimation}
    />
  )
}

export default Loading
