import * as React from "react"

import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"

import {AddButtonIcon} from "components/shared/icons/btn"

import {useAddContainer} from "./bol-edit.hook"

/**
 * @param {React.ButtonHTMLAttributes<HTMLButtonElement>} props
 */
export const AddContainerButton = props => {
  const {onClick, disabled, ...restProps} = props
  const isUpdating = useIsUpdatingEditableBillOfLading()
  const addContainer = useAddContainer()

  return (
    <button
      {...restProps}
      disabled={isUpdating || disabled}
      onClick={() => {
        addContainer("")
      }}
    >
      <AddButtonIcon />
    </button>
  )
}

export default AddContainerButton
