import type {
  RequestHandler,
  FileUploadHandler,
  BoLConvertHandler,
} from "./handler"
export {RequestHandler, FileUploadHandler, BoLConvertHandler}

export enum ProcessState {
  Idle = "idle",
  Pending = "pending",
  Success = "success",
  Failed = "failed",
}

export type RequestHandlerListeners = {
  progress: ((progress: number) => void)[]
  state: ((state: ProcessState) => void)[]
  onSuccess: ((result: any) => void)[]
  onError: ((error: any) => void)[]
}

export type RequestHandlerStatus = {
  progress: number
  state: ProcessState
}

export enum BOLFileProcessingStateEnum {
  AllCompleted = "allCompleted",
  Identifying = "identifying",
  Uploading = "uploading",
  Idle = "idle",
}

export type BOLFileProcessingState = `${BOLFileProcessingStateEnum}`
