import {giantRequest} from "lib/apiClient"

import url from "services/url"

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */

/**
 * @template T
 * @typedef {import('axios').AxiosPromise<T>} AxiosPromise
 */

/**
 * @param {{bolId: string}} params
 * @param {AxiosRequestConfig} options
 * @returns {AxiosPromise<any>}
 */
export function deleteBillOfLading({bolId}, options = {}) {
  return giantRequest({
    ...options,
    url: `${url.BOLS}/${bolId}`,
    method: "DELETE",
  })
}
