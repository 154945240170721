import {z} from "zod"
import head from "lodash/head"

import {dateOrEarliest} from "./date-format"
import {
  SeaCarrierAbbrTable,
  AirCarrierAbbrTable,
  ExpressCarrierAbbrTable,
} from "./carrier"

export const BoLStatus = Object.freeze({
  NoData: 0,
  WaitingOnBoard: 1,
  Transiting: 2,
  Complete: 3,
  Clearance: 4,
  Suspect: 5,
  Error: 6,
})
export const BoLStatusZEnum = z.nativeEnum(BoLStatus)
/** @typedef {typeof BoLStatus[keyof typeof BoLStatus]} BoLStatus*/

export const BoLStatusStr = Object.entries(BoLStatus).reduce(
  (obj, [key, value]) => {
    return {
      ...obj,
      [value]: key,
    }
  },

  /** @type {{[x: number]: string}} */
  ({}),
)

export const BoLShipTypeZEnum = z.enum(["Sea", "Air", "Express"])
export const BoLShipTypeEnum = BoLShipTypeZEnum.enum
/** @typedef {z.infer<typeof BoLShipTypeZEnum>} BoLShipType */

/** @type {BoLShipType[]} */
export const BolShipTypes = [
  BoLShipTypeZEnum.enum.Sea,
  BoLShipTypeZEnum.enum.Air,
  BoLShipTypeZEnum.enum.Express,
]

export const BoLUploadTypeEnum = Object.freeze({
  CSV: "csv",
  BOL: "bol",
  CD: "custom declaration",
})
export const BoLUploadTypeZEnum = z.nativeEnum(BoLUploadTypeEnum)
/** @typedef {z.infer<typeof BoLUploadTypeZEnum>} BoLUploadType */

/**
 * this is for ata/atd/... from api response
 */
export const BoL_ShippingInfo = z.object({
  id: z.number(),
  masterNo: z.string().catch(""),
  cntrNo: z.string().catch(""),
  code: z.string().catch(""),
  evenDate: z.string().optional().nullable().transform(dateOrEarliest), // example, "2022-07-15"
  flightNo: z.string().optional().nullable(),
  location: z.string().default("").catch(""),
  /** @description use this for port/airport */
  locationNm: z.string().default("").catch(""),
  updateTime: z.number(),
  vessel: z.string().nullable().optional(),

  /**
   * @description use this voyage for Sea type
   * https://bctmobile.atlassian.net/jira/software/projects/GE/boards/56?selectedIssue=GE-100
   **/
  voyage: z.string().optional().nullable(),
  /**
   * @description use this for flight No.
   * https://bctmobile.atlassian.net/jira/software/projects/GE/boards/56?selectedIssue=GE-100
   */
  descp: z.string().optional().nullable(),
})
/** @typedef {z.infer<typeof BoL_ShippingInfo>} BoL_ShippingInfo */

const carrierAbbrTables = {
  [BoLShipTypeEnum.Sea]: SeaCarrierAbbrTable,
  [BoLShipTypeEnum.Air]: AirCarrierAbbrTable,

  // todo: later phase
  [BoLShipTypeEnum.Express]: ExpressCarrierAbbrTable,
}

export const getCarriersByShipType = (shipType = BoLShipTypeEnum.Sea) => {
  return carrierAbbrTables[shipType] ?? []
}

export const getDefaultCarrierByShipType = (shipType = BoLShipTypeEnum.Sea) => {
  const table = carrierAbbrTables[shipType]
  if (shipType === BoLShipTypeEnum.Sea) return undefined
  return table[0]
}

/**
 * locationNm could be: "SHANGHAI, SHANGHAI, CHINA"
 * and we only want the first part "SHANGHAI"
 * @param {BoL_ShippingInfo|null|undefined} shippingInfo
 * @param {string=} shippingType
 */
export const getVessel = (shippingInfo, shippingType = BoLShipTypeEnum.Sea) =>
  shippingType === BoLShipTypeEnum.Sea ? shippingInfo?.vessel ?? "" : ""

/**
 * locationNm could be: "SHANGHAI, SHANGHAI, CHINA"
 * and we only want the first part "SHANGHAI"
 * @param {BoL_ShippingInfo|null|undefined} shippingInfo
 */
export const getPortOrAirport = shippingInfo =>
  head((shippingInfo?.locationNm ?? "").split(",")) ?? ""

/**
 * descp could be: "192 pieces departed on CV3213P"
 * and we only want the last part "CV3213P"
 * @param {BoL_ShippingInfo|null|undefined} shippingInfo
 * @param {string=} shippingType
 */
export const getVoyageOrFlightNo = (
  shippingInfo,
  shippingType = BoLShipTypeEnum.Sea,
) =>
  shippingType === BoLShipTypeEnum.Sea
    ? shippingInfo?.voyage ?? ""
    : shippingInfo?.flightNo ?? ""
