import React from "react"
import shallow from "zustand/shallow"
import {useTranslation} from "react-i18next"

import {useDropFileModal, closeDropFileModal} from "lib/store/useDropFileModal"
import {
  useDroppedFiles,
  DropFileTypeEnum,
  defaultDropdownOptions,
  defaultFileTypeOptions,
} from "lib/store/useDroppedFiles"
import {openBolUploadingWindow} from "lib/store/useBolUploadingWindow"
import {useBolCsvPrecheckModal} from "lib/store/useBolCsvPrecheckModal.js"
import {createConfirm} from "lib/store/useConfirmModal.js"

import {DropFileModalLayout} from "./DropFileModal.layout.js"
import {isCsvFile} from "utils/file.js"

/**
 * @type {React.FC<{}>}
 * @returns
 */
const DropFileModalContainer = () => {
  const {t} = useTranslation()
  const showModal = useDropFileModal(state => state.showModal)
  const {openModal: openBolCsvPrecheckModal} = useBolCsvPrecheckModal()
  const [site, setSite, addFiles, uploadType, setFileType] = useDroppedFiles(
    state => [
      state.site,
      state.setSite,
      state.addFiles,
      state.fileType,
      state.setFileType,
    ],
    shallow,
  )

  /**
   * @type {import("./DropFileModal.layout.js").DropFileModalLayoutProps["onSelectFiles"]}
   */
  const onSelectFilesHandler = fileList => {
    let closeModalFlag = true
    if (fileList) {
      const files = Array.from(fileList)
      addFiles(files)

      if (uploadType === DropFileTypeEnum.BOL) {
        const csvFiles = files.filter(isCsvFile)
        const pdfImageFiles = files.filter(file => !isCsvFile(file))

        if (csvFiles.length >= 2) {
          closeModalFlag = false
          createConfirm(t("bolUploadPrecheckModal.multipleCsvWarning"))
        } else {
          if (pdfImageFiles.length > 0) {
            // Handle PDFs and Images
            requestAnimationFrame(openBolUploadingWindow)
          }

          if (csvFiles.length > 0) {
            // Handle CSV
            requestAnimationFrame(openBolCsvPrecheckModal)
          }
        }
      }
    }
    if (closeModalFlag) closeDropFileModal()
  }

  return (
    <DropFileModalLayout
      visible={showModal}
      siteOptions={defaultDropdownOptions}
      selectedType={uploadType}
      typeOptions={defaultFileTypeOptions}
      onSelectType={setFileType}
      selectFileAble={site !== null}
      onRequestClose={closeDropFileModal}
      onSelectSite={siteOption => setSite(siteOption.value)}
      onSelectFiles={onSelectFilesHandler}
    />
  )
}

export default DropFileModalContainer
