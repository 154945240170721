import {useMemo} from "react"
import create from "zustand"
import shallow from "zustand/shallow"
import {omit} from "lodash-es"

/**
 * @type {import('./types').useConfirmModal}
 */
export const useConfirmModal = create(set => ({
  showModal: false,
  openModal: () => set({showModal: true}),
  closeModal: () => set({showModal: false}),
  toggleModal: () => set(state => ({showModal: !state.showModal})),

  message: "",
  handler: null,
  createConfirm: (message, handler) => {
    set({message, handler, showModal: true})
  },
}))

export function closeAllConfirm() {
  return useConfirmModal.getState().closeModal()
}

/**
 * @param {string} message
 * @param {import('./types').ConfirmHandler=} handler
 */
export function createConfirm(message, handler) {
  useConfirmModal.getState().createConfirm(message, handler)
}

export function useConfirmHandler() {
  const [closeModal, handler] = useConfirmModal(
    state => [state.closeModal, state.handler],
    shallow,
  )

  return useMemo(
    () => ({
      ...omit(handler, ["onConfirm", "onCancel"]),
      handleConfirm: () => {
        handler?.onConfirm?.()
        closeModal()
      },
      handleCancel: handler?.onCancel
        ? () => {
            handler?.onCancel?.()
            closeModal()
          }
        : undefined,
    }),
    [handler],
  )
}
