import React from "react"
import {isArray, isFunction} from "lodash-es"
import {useTranslation} from "react-i18next"

import {useDrop} from "hooks/useDrop"
import {createConfirm} from "lib/store/useConfirmModal"

/**
 *
 * @type {React.FC<import('./types').DropFileZoneProps>}
 */
const DropFileZone = ({
  inputId,
  children,
  onFileChanges,
  keepPreviousSelectedFiles = false,
  disabled = false,
  multiple = true,
  className = "",
  accept,
}) => {
  const ref = React.useRef(/** @type {HTMLInputElement | null} */ (null))
  const {t} = useTranslation()

  const handleFileChange = (
    /** @type {React.ChangeEvent<HTMLInputElement>} */ e,
  ) => {
    if (!disabled) {
      const newFiles = e.target.files
      onFileChanges(newFiles)
      if (!keepPreviousSelectedFiles) {
        // @ts-ignore
        e.target.value = null
      }
    }
  }
  const handleDropProps = useDrop(
    event => {
      const dt = event.dataTransfer

      // drop multiple files when multiple is false
      if (!multiple && dt.files.length > 1) {
        createConfirm(t("dropZoneSingleFileWarning"))
        return
      }

      // check file match accept
      if (accept) {
        const acceptMimeTypes = accept.split(",").map(s => s.trim())
        if (
          !Array.from(dt.files).every(file =>
            acceptMimeTypes.some(mime => file.type.match(mime)),
          )
        ) {
          createConfirm(t("dropZoneFileFormatWarning"))
          return
        }
      }

      onFileChanges(dt.files)
    },
    {enable: !disabled},
  )

  const selectFile = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  // console.log(children)

  const renderChildren = isFunction(children)
    ? children({selectFile})
    : isArray(children)
    ? children.map((child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {key: index})
          : isFunction(child)
          ? React.createElement(child, {key: index, selectFile})
          : child,
      )
    : children

  return (
    <form
      className={className}
      onSubmit={e => {
        e.preventDefault()
      }}
      {...handleDropProps}
    >
      {renderChildren}

      <input
        ref={ref}
        className="hidden"
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={handleFileChange}
        id={inputId}
      />
    </form>
  )
}

export default DropFileZone
