import {atom, useAtom} from "jotai"
import {atomFamily} from "jotai/utils"
import type {PrimitiveAtom} from "jotai"

export type NamedQueueParam<T> = {
  name: string
  limit: number
  initialValues: T[] | undefined
}

export type QueueAtomType<T> = PrimitiveAtom<{
  state: T[]
  queue: T[]
}>

declare type ShouldRemove<Param> = (createdAt: number, param: Param) => boolean

const queueAtomFamily = atomFamily(
  param => {
    const {limit, initialValues = []} = param
    return atom({
      state: initialValues.slice(0, limit),
      queue: initialValues.slice(limit),
    })
  },
  (a, b) => a.name === b.name,
) as {
  // the following is just to extends the type of atom family
  // reference: https://github.com/pmndrs/jotai/issues/997
  <T>(param: NamedQueueParam<T>): QueueAtomType<T>
  remove<T>(param: NamedQueueParam<T>): void
  setShouldRemove<T>(
    shouldRemove: ShouldRemove<NamedQueueParam<T>> | null,
  ): void
}

export function useNamedQueue<T>(params: NamedQueueParam<T>) {
  const {limit} = params
  const [currentState, setState] = useAtom(queueAtomFamily(params))
  const length = currentState.state.length + currentState.queue.length

  const add = (values: T[]) => {
    setState(current => {
      const result = [...current.state, ...current.queue, ...values]
      return {
        state: result.slice(0, limit),
        queue: result.slice(limit),
      }
    })
  }

  const update = (fn: (current: T[]) => T[]) => {
    setState(current => {
      const result = fn([...current.state, ...current.queue])
      return {
        state: result.slice(0, limit),
        queue: result.slice(limit),
      }
    })
  }

  const cleanQueue = () => {
    setState(current => ({
      state: current.state,
      queue: [],
    }))
  }

  return /** @type {const} */ {
    state: currentState.state,
    queue: currentState.queue,
    length,
    add,
    update,
    cleanQueue,
  }
}
