import {z} from "zod"

import {giantRequest} from "lib/apiClient"
import {useQuery, useQueryClient} from "lib/query"
import {BillOfLading} from "domain/bol"

const MAIN_KEY = "bols"

export const url = "/bols/filter"
export const SEARCH_URL = "/search"
export const method = "POST"

const defaultLimit = 5

/**
 * @description schema for query the bols
 */
const QueryBoLsAPIParams = z.object({
  page: z.number().optional(),
  pageSize: z.number().optional(),
  status: z.string().optional(),
  transportation: z.array(z.string()).optional(),
  order: z.string().optional(),
  searchPhrase: z.string().optional(),
  vendor: z.array(z.string()).optional(),
  carrier: z.array(z.string()).optional(),
})
/** @typedef {z.infer<typeof QueryBoLsAPIParams>} QueryBoLsAPIParams */

/**
 * @description schema for the result of bols query
 */
const QueryBoLsResult = z.object({
  list: z.array(BillOfLading).default([]).catch([]),
  page: z.number().catch(0),
  totalPages: z.number().catch(0),
  totalItems: z.number().catch(0),
})
/** @typedef {z.infer<typeof QueryBoLsResult>} QueryBoLsResult */

/**
 * @param {QueryBoLsAPIParams} params
 * @param {import('axios').AxiosRequestConfig=} config
 */
export async function getBOLs(params, config) {
  const {
    page = 1,
    pageSize = defaultLimit,
    searchPhrase: _search, // this one is not used
    ...dataParams
  } = params
  return giantRequest({
    ...config,
    url,
    method,
    params: {
      page,
      pageSize,
    },
    data: dataParams ?? {},
  }).then(r => QueryBoLsResult.parse(r.data))
}

/**
 * @param {QueryBoLsAPIParams} params
 * @param {import('axios').AxiosRequestConfig=} config
 */
export async function searchBOLs(params, config) {
  const {searchPhrase = ""} = params
  return giantRequest({
    ...config,
    url: SEARCH_URL,
    method,
    data: {
      id: searchPhrase,
    },
  }).then(r => QueryBoLsResult.parse(r.data))
}

/**
 * @param {QueryBoLsAPIParams} params
 * @returns {[string, QueryBoLsAPIParams]}
 */
const _getKey = params => [MAIN_KEY, params]

/**
 * @type {import('@tanstack/react-query').QueryFunction<
 *  Awaited<ReturnType<getBOLs>>,
 *  [string, Parameters<getBOLs>[0]],
 * >}
 */
const _queryFn = async ({queryKey: [_primaryKey, queryParams], signal}) => {
  if (queryParams.searchPhrase) return searchBOLs(queryParams, {signal})
  return getBOLs(queryParams, {signal})
}

/**
 * @template [T=QueryBoLsResult]
 * @param {QueryBoLsAPIParams} params
 * @param {import('lib/query').PUseQueryOptions<QueryBoLsAPIParams, QueryBoLsResult, T>} [options]
 */
export function useBOLs(params, options = {}) {
  return useQuery(_getKey(params), _queryFn, options)
}
useBOLs.getKey = _getKey
useBOLs.queryFn = _queryFn

export function useRefreshBillOfLadingsFn() {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries([MAIN_KEY])
}
