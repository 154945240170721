import {useEffect} from "react"

/**
 * probably due to security reason,
 * most of modern browser will not show custom message
 * @param {{
 *  enable?: boolean
 *  message?: string
 * }} [options]
 */
export default function usePreventDirectUnloadPage(options = {}) {
  const {enable = false, message = ""} = options
  useEffect(() => {
    if (enable) {
      /** @param {BeforeUnloadEvent} e */
      const handler = e => {
        e.preventDefault()
        return (e.returnValue = message)
      }
      window.addEventListener("beforeunload", handler, {capture: false})
      return () => {
        window.removeEventListener("beforeunload", handler, {capture: false})
      }
    }
  }, [enable, message])
}
