import {z} from "zod"

import {getCarrier} from "./carrier"

import {
  BoLStatus,
  BoLUploadTypeEnum,
  BoLShipTypeZEnum,
  BoL_ShippingInfo,
  BoLUploadTypeZEnum,
  BoLShipTypeEnum,
} from "./bol-meta"
import {
  BoL_ContainerInfo,
  normalizeContainersInfo,
  isContainerSuspect,
  isOver24Hours,
} from "./bol-container"
import {dateOrEarliest} from "./date-format"
import {getFileTypeFromString} from "utils/file"

const SITE_ENV = process.env.SITE_ENV || ""
const MIN_MILLISECONDS = 1 /* min */ * 60 * 1000
const DAY_MINS = 24 * 60

// "2022-09-13T13:39:45.000Z", transform to Date
const DateDTO = z
  .string()
  .datetime()
  .or(z.number())
  .optional()
  .nullable()
  .catch(null)
  .transform(dateOrEarliest)

/**
 * @description this is the object from API
 */
export const BillOfLadingDTO = z.object({
  /**
   * @example
   * "DWTCICBHX3751201"
   */
  id: z.string(),
  /**
   * @example
   * "GVM1"
   */
  site: z.string(),
  /**
   * @example
   * "GIANT(KUNSHAN) CO.,LTD"
   */
  vendor: z.string().catch(""),

  /**
   * @example
   * "Sea"
   */
  transportation: BoLShipTypeZEnum.optional()
    .nullable()
    .default(null)
    .catch(null),

  /**
   * @example
   * "Container Lines Company Limited"
   */
  carrier: z.string().optional().nullable().default("").catch(""),

  /**
   * @example
   * ["TLLU4633575", "TLLU4633335"]
   */
  containerIds: z.array(z.string()).catch([]).default([]),
  containers: z.array(BoL_ContainerInfo).catch([]).default([]),

  /**
   * @example
   * "a.png,b.png"
   */
  uploadFiles: z.string().optional().nullable().default("").catch(""),

  /**
   * @description custom declaration date
   */
  customsClearanceDate: DateDTO,

  /**
   * @description custom declaration id
   */
  importDeclaration: z.string().optional().nullable().catch("").default(""),

  /**
   * @description custom declaration invoice id, separate by ";"
   */
  invoices: z.string().optional().nullable().default("").catch(""),

  // "2022-09-13T13:39:45.000Z", transform to Date
  updateTime: DateDTO,
  // "2022-09-13T13:39:45.000Z", transform to Date
  uploadTime: DateDTO,

  eta: BoL_ShippingInfo.optional().nullable().catch(null),
  etd: BoL_ShippingInfo.optional().nullable().catch(null),
  ata: BoL_ShippingInfo.optional().nullable().catch(null),
  atd: BoL_ShippingInfo.optional().nullable().catch(null),

  registerSuccess: z.boolean().optional().nullable(),

  registerMessage: z.string().optional().nullable(),

  uploadType: BoLUploadTypeZEnum.nullable().catch(null),
})
/** @typedef {z.infer<typeof BillOfLadingDTO>} BoL_DTO */

/**
 * @param {BoL_DTO} bolDTO
 */
export const getUploadType = bolDTO =>
  bolDTO.uploadType ??
  (bolDTO.uploadFiles ? BoLUploadTypeEnum.BOL : BoLUploadTypeEnum.CD)

/**
 * @description return true if the bol is registered failed
 * only for BOL upload type
 * @param {BoL_DTO} bol
 */
const isRegisterFailed = bol => {
  const uploadType = getUploadType(bol)
  return uploadType !== BoLUploadTypeEnum.CD && !bol.registerSuccess
}
export const BillOfLading = BillOfLadingDTO.transform(bill => {
  const uploadType = getUploadType(bill)
  const registerFailed = isRegisterFailed(bill)

  return {
    id: bill.id,
    bolSN: {
      id: bill.id,
      registerFailed,
    },
    site: bill.site,
    registerFailed,
    uploadType,
    registerSuccess: bill.registerSuccess,
    registerMessage: bill.registerMessage,
    vendor: bill.vendor,
    shippingType: bill.transportation ?? undefined,
    carrier: getCarrier(bill.carrier ?? ""),
    containerSN: bill.containerIds.filter(c => typeof c === "string").sort(),
    containersInfo: normalizeContainersInfo(
      {
        uploadType,
        uploadTime: bill.uploadTime,
      },
      bill.containers,
    ),

    status: calcBoLStatus(bill),
    deletable: isBoLDeletable(bill, uploadType),

    ATD: bill.atd?.evenDate,
    ATA: bill.ata?.evenDate,
    ETA: bill.eta?.evenDate,
    ETD: bill.etd?.evenDate,
    updateTime: bill.updateTime,
    uploadTime: bill.uploadTime,

    uploadedFiles: (bill.uploadFiles ?? "")
      .split(",")
      .filter(file => getFileTypeFromString(file) !== "csv")
      .sort(),
    atdRaw: bill.atd,
    ataRaw: bill.ata,
    etaRaw: bill.eta,
    etdRaw: bill.etd,
    customsClearanceDate: bill.customsClearanceDate,
    importDeclaration: bill.importDeclaration,
    invoices: (bill.invoices ?? "").split(";").sort(),
  }
})
/** @typedef {z.infer<typeof BillOfLading>} BillOfLading */

/** @param {BoL_DTO} bill */
export const isBoLSuspect = bill => {
  const uploadType = getUploadType(bill)

  if (uploadType === BoLUploadTypeEnum.CD) return false

  if (bill.transportation === BoLShipTypeEnum.Sea) {
    return (bill.containers ?? []).some(c =>
      isContainerSuspect(c, bill.uploadTime),
    )
  } else {
    const targetTime = bill.uploadTime.valueOf()

    return (
      !bill.ata &&
      !bill.atd &&
      !bill.eta &&
      !bill.etd &&
      isOver24Hours(Date.now(), targetTime)
    )
  }
}

/** @param {BoL_DTO} bill */
export const calcBoLStatus = bill => {
  const registerFailed = isRegisterFailed(bill)

  // original logic
  if (bill.customsClearanceDate && bill.importDeclaration) {
    return BoLStatus.Clearance
  } else if (bill.ata) {
    return BoLStatus.Complete
  } else if (bill.atd) {
    return BoLStatus.Transiting
  } else if (bill.etd || bill.eta) {
    return BoLStatus.WaitingOnBoard
  } else if (registerFailed) {
    return BoLStatus.Error
  } else if (isBoLSuspect(bill)) {
    return BoLStatus.Suspect
  }
  return BoLStatus.NoData
}

/**
 * @description return true if the bol is deletable
 * @param {BoL_DTO} bol
 * @param {import('./bol-meta').BoLUploadType} uploadType
 */
const isBoLDeletable = (bol, uploadType) => {
  return (
    uploadType === BoLUploadTypeEnum.CD ||
    (!bol.ata && !bol.atd && !bol.eta && !bol.etd)
  )
}

/**
 * @type {Record<number, string>}
 */
export const BolPrecheckWarningCode = Object.freeze({
  201: "WARNING_BOL_EXIST",
})

/**
 * @type {Record<number, string>}
 */
export const BolPrecheckErrorCode = Object.freeze({
  400: "ERROR_EMPTY_FIELD",
  401: "ERROR_NOT_SUPPORT",
  402: "ERROR_DUPLICATE",
  403: "ERROR_FORMAT_ERROR",
  404: "ERROR_SHOULD_EMPTY",
})

const splitContainer = (containers = "") =>
  containers
    .split(";")
    .map(s => s.trim())
    .filter(s => !!s)
    .sort()

export const BolPrecheckDataDTO = z.object({
  data: z.string(),
  warning: z
    .number()
    .transform(n => BolPrecheckWarningCode[n])
    .optional(),
  error: z
    .number()
    .transform(n => BolPrecheckErrorCode[n])
    .optional(),
})
export const BolPrecheckResultRowDTO = z.object({
  row: z.number(),
  bol: BolPrecheckDataDTO,
  carrier: BolPrecheckDataDTO,
  container: BolPrecheckDataDTO.transform(c => ({
    ...c,
    data: splitContainer(c.data),
  })),
  shipType: BolPrecheckDataDTO,
  vendor: BolPrecheckDataDTO,
})
export const BolPrecheckResultDTO = z.array(BolPrecheckResultRowDTO)
/** @typedef {z.infer<typeof BolPrecheckResultDTO>} TBolPrecheckResult */
/** @typedef {z.infer<typeof BolPrecheckResultRowDTO>} TBolPrecheckResultDetailedRow */
/** @typedef {Omit<TBolPrecheckResultDetailedRow, 'row'>} TBolPrecheckResultRow */
