import React from "react"

/**
 * @param {React.ButtonHTMLAttributes<HTMLButtonElement>} props
 */
export function TextButton(props) {
  const {className, ...restProps} = props
  return (
    <button
      className={`${
        className ?? ""
      } p-2 text-sm rounded-lg text-primary hover:ring-2 hover:ring-primary
      `}
      {...restProps}
    />
  )
}

export default TextButton
