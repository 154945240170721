import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const NoBolFile = props => (
  <svg
    width={305}
    height={331}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect x={24} y={20} width={257} height={283} rx={16} fill="#fff" />
    </g>
    <path
      d="M24 36c0-8.837 7.163-16 16-16h225c8.837 0 16 7.163 16 16v148H24V36Z"
      fill="#FAFCFE"
    />
    <path
      d="M120.94 159.817c0 1.006-7.636 1.832-17.078 1.832-9.417 0-17.079-.826-17.079-1.832 0-1.006 7.637-1.832 17.079-1.832s17.078.826 17.078 1.832ZM219.512 159.817c0 1.006-7.636 1.832-17.078 1.832s-17.078-.826-17.078-1.832c0-1.006 7.636-1.832 17.078-1.832s17.078.826 17.078 1.832Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path
      d="M103.886 159.946C86.292 159.946 72 145.628 72 128.06c0-17.595 14.318-31.886 31.886-31.886 17.594 0 31.886 14.317 31.886 31.886 0 17.568-14.318 31.886-31.886 31.886Zm0-61.219c-16.175 0-29.332 13.157-29.332 29.333 0 16.175 13.157 29.332 29.332 29.332s29.332-13.157 29.332-29.332c0-16.176-13.157-29.333-29.332-29.333Z"
      fill="#99B5CC"
    />
    <path
      d="M103.886 156.721c-15.814 0-28.661-12.847-28.661-28.661 0-15.814 12.873-28.662 28.661-28.662 15.814 0 28.662 12.873 28.662 28.662 0 15.788-12.874 28.661-28.662 28.661Zm0-56.059c-15.091 0-27.397 12.28-27.397 27.398 0 15.117 12.28 27.397 27.397 27.397 15.092 0 27.398-12.28 27.398-27.397 0-15.118-12.306-27.398-27.398-27.398Z"
      fill="#C1C1C1"
    />
    <path
      d="M202.434 159.946c-17.594 0-31.886-14.318-31.886-31.886 0-17.594 14.318-31.886 31.886-31.886 17.595 0 31.887 14.317 31.887 31.886.025 17.568-14.292 31.886-31.887 31.886Zm0-61.218c-16.175 0-29.332 13.157-29.332 29.332s13.157 29.332 29.332 29.332c16.176 0 29.333-13.157 29.333-29.332s-13.157-29.332-29.333-29.332Z"
      fill="#99B5CC"
    />
    <path
      d="M202.435 156.721c-15.814 0-28.662-12.847-28.662-28.661 0-15.814 12.873-28.662 28.662-28.662 15.788 0 28.687 12.848 28.687 28.662 0 15.788-12.873 28.661-28.687 28.661Zm0-56.059c-15.092 0-27.398 12.28-27.398 27.398 0 15.117 12.28 27.397 27.398 27.397 15.091 0 27.397-12.28 27.397-27.397 0-15.118-12.28-27.398-27.397-27.398Z"
      fill="#C1C1C1"
    />
    <path
      d="M203.621 126.821c-7.894-7.687-11.686-20.431-11.918-21.205l-8.901-38.749h8.746a1.702 1.702 0 1 0 0-3.405h-10.861c-.516 0-1.006.232-1.341.645-.336.413-.439.929-.336 1.445l2.657 11.583-45.817 5.03-3.998-17.826a1.694 1.694 0 0 0-2.038-1.29 1.693 1.693 0 0 0-1.29 2.038l4.179 18.6-30.235 43.392c-.026.026-.026.052-.052.078l-.077.155-.077.154c-.026.052-.026.104-.052.155-.026.052-.026.129-.026.181 0 .026-.026.051-.026.077v.387c0 .052.026.103.026.155.026.052.026.103.052.155a.36.36 0 0 1 .051.155l.078.154c.026.052.051.103.103.129.026.052.077.078.103.129.026.052.078.078.103.103.052.052.104.078.129.129l.052.052c.026.026.077.026.103.052.026.025.052.025.103.051a.902.902 0 0 0 .284.103c.103.026.207.052.31.052h.026l41.379 4.05h.155c.103 0 .232 0 .335-.026h.052c.026 0 .052-.025.077-.025.052-.026.129-.026.181-.052.026 0 .026 0 .052-.026.025-.026.077-.026.103-.051l.155-.078c.025 0 .025-.025.051-.025l.078-.078c.051-.026.077-.077.129-.103l.051-.052 38.594-41.328 3.353 14.55c0 .026 0 .052.026.078.155.567 4.076 14.214 12.822 22.753.335.336.774.49 1.186.49.439 0 .877-.18 1.213-.516a1.713 1.713 0 0 0-.052-2.425Zm-69.938-38.593 9.417 41.947-36.143-3.534 26.726-38.413Zm12.538 40.348-9.648-43.005 45.868-5.057 1.677 7.353-37.897 40.709Z"
      fill="#DBDBDB"
    />
    <path
      d="M198.177 83.687c-1.677 0-2.941-.154-3.277-.206a2.143 2.143 0 0 1-1.831-2.4 2.144 2.144 0 0 1 2.399-1.83c1.96.257 7.61.463 10.216-1.833.98-.85 1.444-1.96 1.444-3.482 0-1.806-.541-3.173-1.625-4.231-2.734-2.606-8.023-2.4-8.075-2.4h-5.856a2.136 2.136 0 0 1-2.141-2.14c0-1.187.955-2.142 2.141-2.142h5.702c.774-.026 7.223-.18 11.144 3.535 1.987 1.883 2.967 4.334 2.967 7.326 0 2.735-1.006 5.057-2.915 6.708-2.889 2.58-7.275 3.095-10.293 3.095ZM141.423 65.552h-14.37c-5.624 0-9.081-4.98-9.21-5.212a2.126 2.126 0 0 1 .568-2.966 2.126 2.126 0 0 1 2.967.567c.051.078 2.373 3.328 5.701 3.328h14.369c1.187 0 2.142.955 2.142 2.141a2.175 2.175 0 0 1-2.167 2.142Z"
      fill="#99B5CC"
    />
    <path
      d="m147.82 128.163 3.38-11.042-2.451-.748-3.379 11.042h-.103a4.723 4.723 0 0 0-4.721 4.721c0 1.651.851 3.121 2.167 3.947l-3.38 11.041 2.451.748 3.379-11.041h.104a4.723 4.723 0 0 0 4.721-4.721 4.708 4.708 0 0 0-2.168-3.947Z"
      fill="#C1C1C1"
    />
    <path
      d="M154.219 118.231h-8.487a1.498 1.498 0 0 1-1.497-1.497c0-.825.671-1.496 1.497-1.496h8.487a1.497 1.497 0 0 1 0 2.993ZM144.803 148.956h-8.488a1.498 1.498 0 0 1 0-2.993h8.488a1.498 1.498 0 0 1 0 2.993Z"
      fill="#99B5CC"
    />
    <path
      d="M145.267 141.707c-5.288 0-9.597-4.309-9.597-9.597 0-5.289 4.309-9.597 9.597-9.597 5.289 0 9.597 4.308 9.597 9.597 0 5.288-4.308 9.597-9.597 9.597Zm0-17.93c-4.592 0-8.332 3.741-8.332 8.333 0 4.592 3.74 8.332 8.332 8.332 4.592 0 8.333-3.74 8.333-8.332 0-4.592-3.741-8.333-8.333-8.333Z"
      fill="#C1C1C1"
    />
    <path
      d="M104.635 123.984a.639.639 0 0 1-.645-.645c0-.361.284-.645.645-.645l40.632-.207c.361 0 .645.284.645.645a.638.638 0 0 1-.645.645l-40.632.207ZM142.636 141.32c-.052 0-.104 0-.155-.026l-39.161-9.261c-.336-.078-.568-.439-.465-.774.078-.336.439-.568.774-.465l39.161 9.262c.336.077.568.438.465.774-.052.283-.336.49-.619.49Z"
      fill="#C1C1C1"
    />
    <path
      d="M109.046 127.389a4.724 4.724 0 0 1-4.721 4.721 4.724 4.724 0 0 1-4.721-4.721 4.723 4.723 0 0 1 4.721-4.721c2.605.026 4.721 2.115 4.721 4.721Z"
      fill="#C1C1C1"
    />
    <rect x={56} y={216} width={148} height={8} rx={2} fill="#EFEFEF" />
    <rect x={56} y={236} width={193} height={8} rx={2} fill="#EFEFEF" />
    <rect x={56} y={256} width={193} height={8} rx={2} fill="#EFEFEF" />
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={305}
        height={331}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1058_18774"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1058_18774"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
