import {isBrowser} from "./browser"

class SearchParamsHelper extends URLSearchParams {
  /**
   * @param {Location['search']=} initSearch
   */
  constructor(initSearch = "") {
    if (isBrowser) super(initSearch || window?.location?.search)
    else super(initSearch)
  }

  /**
   *
   * @param {string[]} searchParamKeys
   */
  getMany(...searchParamKeys) {
    return Object.fromEntries(searchParamKeys.map(key => [key, this.get(key)]))
  }

  /**
   *
   * @param {Record<string, string>} searchParamPairs
   */
  setMany(searchParamPairs) {
    Object.entries(searchParamPairs).forEach(([key, value]) =>
      this.set(key, String(value)),
    )
  }

  /**
   *
   * @param {Record<string, string>} searchParamPairs
   */
  appendMany(searchParamPairs) {
    Object.entries(searchParamPairs).forEach(([key, value]) =>
      this.append(key, String(value)),
    )
  }
}

export default SearchParamsHelper

export {SearchParamsHelper}
