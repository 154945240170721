import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusClearanceIcon = (props = {}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-clearance"
    {...props}
  >
    <rect width="20" height="20" rx="4" fill="#97D700" />
    <path
      d="M5.502 9.578a.857.857 0 1 0-1.29 1.13l3 3.428a.857.857 0 0 0 1.268.024l7.286-7.714a.857.857 0 0 0-1.246-1.177L7.88 12.297 5.502 9.579z"
      fill="#000"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusCompleteIcon = (props = {}) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-complete"
    {...props}
  >
    <rect width={20} height={20} rx={4} fill="#04A9FB" />
    <rect
      x={4.7}
      y={4.7}
      width={10.6}
      height={10.6}
      rx={1.3}
      stroke="#000"
      strokeWidth={1.4}
    />
    <path
      d="M7.5 5v4L10 7.5 12.5 9V5"
      stroke="#000"
      strokeWidth={1.4}
      strokeLinejoin="round"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusNoDataIcon = (props = {}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-nodata"
    {...props}
  >
    <rect width="20" height="20" rx="4" fill="#B1B5BD" />
    <path
      d="M9.055 12.655V12.16C9.055 11.64 9.15 11.205 9.34 10.855C9.54 10.505 9.89 10.135 10.39 9.745C10.89 9.365 11.24 9.05 11.44 8.8C11.65 8.55 11.755 8.24 11.755 7.87C11.755 7.49 11.62 7.2 11.35 7C11.09 6.8 10.715 6.7 10.225 6.7C9.785 6.7 9.365 6.765 8.965 6.895C8.565 7.025 8.165 7.19 7.765 7.39L7.09 5.98C7.55 5.73 8.05 5.53 8.59 5.38C9.13 5.22 9.725 5.14 10.375 5.14C11.385 5.14 12.165 5.385 12.715 5.875C13.265 6.355 13.54 6.99 13.54 7.78C13.54 8.21 13.47 8.58 13.33 8.89C13.2 9.19 13 9.47 12.73 9.73C12.47 9.99 12.155 10.265 11.785 10.555C11.475 10.795 11.24 11 11.08 11.17C10.92 11.34 10.81 11.51 10.75 11.68C10.69 11.84 10.66 12.04 10.66 12.28V12.655H9.055ZM8.77 15.04C8.77 14.62 8.88 14.325 9.1 14.155C9.33 13.975 9.605 13.885 9.925 13.885C10.235 13.885 10.5 13.975 10.72 14.155C10.95 14.325 11.065 14.62 11.065 15.04C11.065 15.45 10.95 15.745 10.72 15.925C10.5 16.105 10.235 16.195 9.925 16.195C9.605 16.195 9.33 16.105 9.1 15.925C8.88 15.745 8.77 15.45 8.77 15.04Z"
      fill="black"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusOnTransitIcon = (props = {}) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-transiting"
    {...props}
  >
    <rect width={20} height={20} rx={4} fill="#F8C630" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.958 5.5a.52.52 0 0 0-.518.518V7.58h-1.612a.52.52 0 0 0-.468.518v1.563a.52.52 0 0 0-.053 0 .52.52 0 0 0-.412.288l-.376.752h-.2v-4.16a.52.52 0 0 0-.522-.523H4.6a.52.52 0 0 0-.522.522v4.161H2.519a.52.52 0 0 0-.434.806l2.081 3.12a.52.52 0 0 0 .434.231h10.398a.518.518 0 0 0 .47-.295l1.987-4.162a.52.52 0 0 0-.468-.74h-.43V6.018a.52.52 0 0 0-.518-.518h-2.08Zm.522 1.04h1.041v1.04h-1.04V6.54Zm-9.362.518H9.28v3.643H5.12V7.058Zm1.536.522c-.694.036-.64 1.076.054 1.04H7.72c.694 0 .694-1.04 0-1.04H6.708a.52.52 0 0 0-.054 0ZM12.4 8.62h1.04v1.041H12.4v-1.04Zm2.08 0h1.041v1.041h-1.04v-1.04Zm-2.798 2.077h4.48l-1.49 3.121H4.877l-1.387-2.08h7.348a.522.522 0 0 0 .469-.284l.375-.757Z"
      fill="#000"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusWaitingIcon = (props = {}) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-waitingonboard"
    {...props}
  >
    <rect width={20} height={20} rx={4} fill="#99B5CC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-.923a5.077 5.077 0 1 1 0-10.154 5.077 5.077 0 0 1 0 10.154Zm0-8.123c.255 0 .461.207.461.462v3.008l1.5 1.5a.462.462 0 0 1-.653.652l-1.634-1.634a.461.461 0 0 1-.136-.327v-3.2c0-.254.207-.46.462-.46Z"
      fill="#000"
    />
    <path
      d="M10.461 10.424h-.2v.083l.06.059.14-.142Zm1.5 1.5-.142.14.141-.14Zm-.653.652-.142.141.142-.141ZM15.8 10a5.8 5.8 0 0 1-5.8 5.8v.4a6.2 6.2 0 0 0 6.2-6.2h-.4ZM10 4.2a5.8 5.8 0 0 1 5.8 5.8h.4A6.2 6.2 0 0 0 10 3.8v.4ZM4.2 10A5.8 5.8 0 0 1 10 4.2v-.4A6.2 6.2 0 0 0 3.8 10h.4Zm5.8 5.8A5.8 5.8 0 0 1 4.2 10h-.4a6.2 6.2 0 0 0 6.2 6.2v-.4ZM4.723 10A5.277 5.277 0 0 0 10 15.277v-.4A4.877 4.877 0 0 1 5.123 10h-.4ZM10 4.723A5.277 5.277 0 0 0 4.723 10h.4A4.877 4.877 0 0 1 10 5.123v-.4ZM15.277 10A5.277 5.277 0 0 0 10 4.723v.4A4.877 4.877 0 0 1 14.877 10h.4ZM10 15.277A5.277 5.277 0 0 0 15.277 10h-.4A4.877 4.877 0 0 1 10 14.877v.4Zm.662-7.861A.661.661 0 0 0 10 6.754v.4c.144 0 .261.117.261.262h.4Zm0 3.008V7.416h-.4v3.008h.4Zm1.44 1.358-1.499-1.5-.283.284 1.499 1.498.283-.282Zm0 .935a.662.662 0 0 0 0-.935l-.283.283a.261.261 0 0 1 0 .37l.283.282Zm-.936 0a.661.661 0 0 0 .936 0l-.283-.283a.262.262 0 0 1-.37 0l-.283.283Zm-1.634-1.634 1.634 1.634.283-.283L9.815 10.8l-.283.283Zm-.194-.468c0 .176.07.344.194.468l.283-.283a.262.262 0 0 1-.077-.185h-.4Zm0-3.2v3.2h.4v-3.2h-.4Zm.662-.66a.662.662 0 0 0-.662.66h.4c0-.144.118-.26.262-.26v-.4Z"
      fill="#000"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusErrorIcon = (props = {}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-error"
    {...props}
  >
    <rect width="20" height="20" rx="4" fill="#FF6A39" />
    <path
      d="M10.82 12.655H9.38L9.005 5.29H11.195L10.82 12.655ZM8.945 15.04C8.945 14.62 9.055 14.325 9.275 14.155C9.495 13.975 9.77 13.885 10.1 13.885C10.41 13.885 10.675 13.97 10.895 14.14C11.125 14.31 11.24 14.61 11.24 15.04C11.24 15.45 11.125 15.745 10.895 15.925C10.675 16.105 10.41 16.195 10.1 16.195C9.77 16.195 9.495 16.105 9.275 15.925C9.055 15.745 8.945 15.45 8.945 15.04Z"
      fill="black"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const StatusSuspectIcon = (props = {}) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bol-status-suspect"
    {...props}
  >
    <rect width={20} height={20} rx={4} fill="#FF6A39" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.703 13.405a4.703 4.703 0 1 1 3.651-1.739l3.504 3.503a.486.486 0 1 1-.689.688l-3.503-3.503a4.683 4.683 0 0 1-2.963 1.051Zm0-.973a3.73 3.73 0 1 0 0-7.459 3.73 3.73 0 0 0 0 7.46Z"
      fill="#000"
    />
    <path
      d="m12.354 11.666-.141.142-.128-.128.114-.14.155.126Zm3.504 3.503-.142.142.142-.142Zm0 .688.14.142-.14-.142Zm-.689 0-.14.142.14-.142Zm-3.503-3.503-.126-.155.14-.114.128.128-.142.141ZM4.2 8.703a4.503 4.503 0 0 0 4.503 4.502v.4A4.903 4.903 0 0 1 3.8 8.703h.4ZM8.703 4.2A4.503 4.503 0 0 0 4.2 8.703h-.4A4.903 4.903 0 0 1 8.703 3.8v.4Zm4.502 4.503A4.503 4.503 0 0 0 8.703 4.2v-.4a4.903 4.903 0 0 1 4.902 4.903h-.4ZM12.2 11.54a4.483 4.483 0 0 0 1.006-2.837h.4c0 1.17-.41 2.246-1.096 3.09l-.31-.253Zm.297-.015 3.503 3.503-.283.283-3.503-3.503.283-.283Zm3.503 3.503a.686.686 0 0 1 0 .97l-.283-.282a.287.287 0 0 0 0-.405l.283-.283Zm0 .97a.686.686 0 0 1-.97 0l.282-.282a.287.287 0 0 0 .405 0L16 16Zm-.97 0-3.504-3.502.283-.283 3.503 3.503-.283.283Zm-6.326-2.793A4.483 4.483 0 0 0 11.54 12.2l.252.31a4.883 4.883 0 0 1-3.09 1.096v-.4Zm3.93-4.502a3.93 3.93 0 0 1-3.93 3.93v-.4a3.53 3.53 0 0 0 3.53-3.53h.4Zm-3.93-3.93a3.93 3.93 0 0 1 3.93 3.93h-.4a3.53 3.53 0 0 0-3.53-3.53v-.4Zm-3.93 3.93a3.93 3.93 0 0 1 3.93-3.93v.4a3.53 3.53 0 0 0-3.53 3.53h-.4Zm3.93 3.93a3.93 3.93 0 0 1-3.93-3.93h.4a3.53 3.53 0 0 0 3.53 3.53v.4Z"
      fill="#000"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const RegisterFailedIcon = (props = {}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    aria-label="bol-register-failed"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#xg0uwn7kna)">
      <circle cx="10" cy="10" r="8" fill="#99B5CC" />
      <path
        d="M9.124 11.822h1.652L11 5.06H8.886l.238 6.762zm2.058 2.128c0-.644-.504-1.148-1.19-1.148-.714 0-1.218.504-1.218 1.148 0 .644.504 1.148 1.218 1.148.686 0 1.19-.504 1.19-1.148z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="xg0uwn7kna">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
