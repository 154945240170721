import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const SolidCircleExclamationIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M10.856 14.368H12.744L13 6.64H10.584L10.856 14.368ZM13.208 16.8C13.208 16.064 12.632 15.488 11.848 15.488C11.032 15.488 10.456 16.064 10.456 16.8C10.456 17.536 11.032 18.112 11.848 18.112C12.632 18.112 13.208 17.536 13.208 16.8Z"
      fill="white"
    />
  </svg>
)
