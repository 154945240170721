import * as React from "react"
import {useTranslation} from "react-i18next"

import usePreventDirectUnloadPage from "hooks/usePreventDirectUnloadPage"
import {useConfirmModal} from "lib/store/useConfirmModal"
import {closeEditBoLModal} from "lib/store/useEditBoLModal"

import {CloseButtonIcon} from "components/shared/icons/btn"

import {useIsEdited, useSaveEditedBol, useIsEditInvalid} from "./bol-edit.hook"

export const CloseEditModalButton = () => {
  const {t} = useTranslation()
  const createConfirm = useConfirmModal(state => state.createConfirm)
  const isEdited = useIsEdited()
  const isInvalid = useIsEditInvalid()
  const {save, isLoading} = useSaveEditedBol({
    onSuccess: () => {
      closeEditBoLModal()
    },
  })

  usePreventDirectUnloadPage({
    enable: isLoading || isEdited,
  })

  return (
    <button
      onClick={() => {
        if (isEdited) {
          if (isInvalid) {
            createConfirm(t("confirmLeave"), {
              confirmLabel: t("btnLeave"),
              cancelLabel: t("btnCancel"),
              onConfirm: () => {
                closeEditBoLModal()
              },
              onCancel: () => {},
            })
            return
          }

          createConfirm(t("confirmExitBOLEdit"), {
            confirmLabel: t("btnSave"),
            onConfirm: () => {
              save()
            },
            onCancel: () => {
              closeEditBoLModal()
            },
          })
        } else {
          closeEditBoLModal()
        }
      }}
      disabled={isLoading}
      data-testid="ic-close"
    >
      <CloseButtonIcon />
    </button>
  )
}

export default CloseEditModalButton
