import * as React from "react"
import {atom, createStore, Provider} from "jotai"

import {
  BoLStatus,
  BoLShipTypeEnum,
  BoLUploadTypeEnum,
  getDefaultCarrierByShipType,
  isOver24Hours,
  checkContainerIdFormat,
} from "@domain"
import {updateBolRowDataStatus} from "lib/store/useEditBoLModal"
import {useHydrateAtoms} from "jotai/utils"

// this atom will hold the original value of bol (before edited)
// will match against this value to check if user changes data
export const bolInitValueAtom = atom(
  // the default value is just for "DEV" fast refresh, it won't have the value in real site
  // @ts-ignore
  /** @type {import('domain/bol').BillOfLading} */ ({
    bolSN: {
      id: "temp",
    },
    vendor: "test",
    shippingType: "Sea",
    carrier: {
      abbr: "ONE",
      carrier: "O",
    },
    containerSN: [],
    uploadType: "bol",
  }),
)
export const editedVendorAtom = atom("")

export const editedShipTypeAtom = atom(
  /** @type {BoLShipType|undefined} */ (undefined),
)

export const editedCarrierAtom = atom(
  /** @type {BillOfLading["carrier"]} */ (undefined),
)

// each container id will be an atom,
export const editedContainersAtomsInAtom = atom(
  /** @type {Array<typeof editedVendorAtom>} */ ([]),
)

// this atom compute parameter for updating
export const paramForUpdateBoLAtom = atom(get => {
  const bol = get(bolInitValueAtom)
  const vendor = get(editedVendorAtom)
  const transportation = get(editedShipTypeAtom)
  const editedCarrier = get(editedCarrierAtom)
  const editedContainersAtoms = get(editedContainersAtomsInAtom)

  return {
    id: bol.bolSN.id,
    vendor,
    transportation,
    carrier: editedCarrier?.carrier ?? "",
    containerIds: editedContainersAtoms.map(editedContainerAtom =>
      get(editedContainerAtom),
    ),
  }
})

// TODO: must remove this, only a temporary fix
/** @param {import('domain/bol').BillOfLading} bol */
const calcBoLStatus = bol => {
  const {
    uploadType,
    containerSN,
    shippingType,
    containersInfo,
    customsClearanceDate,
    importDeclaration,
  } = bol

  let isSuspect = false
  if (uploadType !== BoLUploadTypeEnum.CD) {
    if (shippingType === BoLShipTypeEnum.Sea) {
      isSuspect = containerSN.some(containerId => {
        const data = containersInfo.get(containerId)
        return !!data && data.isSuspect
      })
    } else {
      isSuspect =
        !bol.ataRaw &&
        !bol.atdRaw &&
        !bol.etaRaw &&
        !bol.etdRaw &&
        isOver24Hours(Date.now(), bol.uploadTime?.valueOf())
    }
  }

  if (isSuspect) {
    return BoLStatus.Suspect
  }

  // original logic
  if (customsClearanceDate && importDeclaration) {
    return BoLStatus.Clearance
  } else if (bol.ataRaw) {
    return BoLStatus.Complete
  } else if (bol.atdRaw) {
    return BoLStatus.Transiting
  } else if (bol.etdRaw || bol.etaRaw) {
    return BoLStatus.WaitingOnBoard
  }
  return BoLStatus.NoData
}

/**
 * @typedef {import('services/bol-edit').UpdateEditableBillOfLadingParam} UpdateParam
 * @description reset the editor value
 *   this is called after a save success
 */
export const updateEditorsValueAtom = atom(
  null,
  (get, set, /** @type {UpdateParam | undefined} */ updateParam) => {
    if (!updateParam) return

    set(bolInitValueAtom, bolInitValue => {
      const newContainersInfo = new Map(bolInitValue.containersInfo)
      updateParam.containerIds.forEach(containerId => {
        if (!newContainersInfo.get(containerId)) {
          newContainersInfo.set(containerId, {
            id: containerId,
            isSuspect: false,
            isEditable: true,
          })
        }
      })

      const updatedBol = {
        ...bolInitValue,
        vendor: updateParam.vendor,
        shippingType: updateParam.transportation,
        carrier: get(editedCarrierAtom),
        containerSN: updateParam.containerIds,
        containersInfo: newContainersInfo,
      }

      // TODO: MUST remove this, only a temporary fix
      updateBolRowDataStatus(calcBoLStatus(updatedBol))

      return updatedBol
    })
    set(
      editedContainersAtomsInAtom,
      updateParam.containerIds.map(c => atom(c)),
    )
  },
)

/**
 * @typedef {import('components/shared/fields/Dropdown').OptionItem<BoLShipType>} OptionItem
 * @description
 *  when selecting ship type, we need to change other atom values
 *  carrier, and containers
 */
export const selectShipTypeOptionAtom = atom(
  null,
  (_get, set, /** @type {OptionItem | undefined} */ option) => {
    if (!option) return

    const newShipType = option.value
    set(editedShipTypeAtom, newShipType)

    // @ts-ignore
    set(editedCarrierAtom, getDefaultCarrierByShipType(newShipType))

    if (newShipType !== BoLShipTypeEnum.Sea) {
      set(editedContainersAtomsInAtom, [])
    }
  },
)

// the atoms hold
export const isEditedContainersInvalidAtomsInAtom = atom(get => {
  const editedContainersAtoms = get(editedContainersAtomsInAtom)
  const bol = get(bolInitValueAtom)

  return editedContainersAtoms
    .map(editedContainerAtom => get(editedContainerAtom))
    .map(containerId => {
      const isEditable = bol.containersInfo.get(containerId)?.isEditable ?? true

      return atom(
        containerId && isEditable && !checkContainerIdFormat(containerId)
          ? true
          : false,
      )
    })
})

export const isInvalidAtom = atom(get => {
  const isSomeContainerInvalid = get(isEditedContainersInvalidAtomsInAtom)
    .map(get)
    .some(s => s)

  return isSomeContainerInvalid
})

export const isEditedAtom = atom(get => {
  const bol = get(bolInitValueAtom)
  const editedVendor = get(editedVendorAtom)
  const editedShipType = get(editedShipTypeAtom)
  const editedCarrier = get(editedCarrierAtom)

  const editedContainersAtoms = get(editedContainersAtomsInAtom)

  return (
    bol.vendor !== editedVendor ||
    bol.shippingType !== editedShipType ||
    bol.carrier?.abbr !== editedCarrier?.abbr ||
    bol.containerSN.length !== editedContainersAtoms.length ||
    bol.containerSN.some(
      (containerId, index) => get(editedContainersAtoms[index]) !== containerId,
    )
  )
})

// @ts-ignore
const HydrateAtoms = ({initialValues, store, children}) => {
  // initializing edit data with bol value
  useHydrateAtoms(initialValues, {store})
  return children
}

// the real value of the store context would be passed in the BolEditProvider
const AtomStoreContext = React.createContext(createStore())
export const useAtomStore = () => React.useContext(AtomStoreContext)

/**
 *
 * @param {{
 *   children: React.ReactNode,
 *   bol: import('domain/bol').BillOfLading,
 * }} param0
 * @returns
 */
export function BolEditProvider({bol, children}) {
  // each time the bol changes, we need to create a new store
  const [atomStore] = React.useState(() => {
    return createStore()
  })

  return (
    <Provider store={atomStore}>
      <AtomStoreContext.Provider value={atomStore}>
        <HydrateAtoms
          initialValues={[
            [bolInitValueAtom, bol],
            [editedVendorAtom, bol.vendor ?? ""],
            [editedShipTypeAtom, bol.shippingType],
            [editedCarrierAtom, bol.carrier],
            [editedContainersAtomsInAtom, bol.containerSN.map(c => atom(c))],
          ]}
          store={atomStore}
        >
          {children}
        </HydrateAtoms>
      </AtomStoreContext.Provider>
    </Provider>
  )
}

/**
 * @typedef { import('domain/bol').BillOfLading} BillOfLading
 * @typedef {import('@domain').BoLShipType} BoLShipType
 */
