import React from "react"
import {ErrorBoundary} from "react-error-boundary"
// import {useSSR as useI18nSSR, I18nextProvider} from "react-i18next"

import {QueryProvider} from "./query"
import {ToastContainer} from "./toast"
// import i18n, {defaultLng, defaultResource} from "./i18n"

import DropFileModal from "components/features/drop-file/DropFileModal"
import UploadingWindow from "components/features/upload-file/UploadingWindow"
import BoLEditModal from "components/features/bol-edit/BoLEditModal"
import ConfirmModal from "components/features/confirm-modal/ConfirmModal"
import LoadingModal from "components/features/loading-modal/LoadingModal"
import CDUploadModal from "components/features/cd-upload"

import MainLayout from "components/shared/layouts/MainLayout"
import DefaultPageFallback from "components/shared/overlays/DefaultPageFallback"

/**
 * @type {React.FC<React.PropsWithChildren<any>>}
 */
export const RootProvider = ({children, ...props}) => {
  return (
    <QueryProvider>
      {children}
      <ToastContainer />
    </QueryProvider>
  )
}

/**
 * @type {React.FC<React.PropsWithChildren<{
 * }>>}
 */
export const RootPageElement = ({children, ...props}) => {
  // for gatsby-node createPage
  // useI18nSSR(defaultResource, defaultLng)
  return (
    <ErrorBoundary
      FallbackComponent={DefaultPageFallback}
      onError={(error, info) => {
        console.log("boundary catch", error, info)
      }}
    >
      <MainLayout>
        {children}
        <DropFileModal />
        <UploadingWindow />
        <CDUploadModal />
        <BoLEditModal />

        <ConfirmModal />
        <LoadingModal />
      </MainLayout>
    </ErrorBoundary>
  )
}
