import {differenceWith, noop} from "lodash-es"
import {useEffect, useRef} from "react"
import create from "zustand"

import {DropFileTypeEnum} from "./types"

export {DropFileTypeEnum}

const defaultSiteList = ["GVM1"] //, "GEM1", "GHM1", "DTE1", "DTV1", "GTM1"]
export const defaultDropdownOptions = defaultSiteList.map(site => ({
  title: site,
  value: site,
}))

export const defaultFileTypeOptions = [
  DropFileTypeEnum.BOL,
  DropFileTypeEnum.Customs,
]

/** @type {import('./types').useDroppedFiles} */
export const useDroppedFiles = create((set, get) => ({
  files: [],
  resetFiles: () => set({files: []}),
  filterFiles: fileFilter =>
    set(state => ({files: state.files.filter(fileFilter)})),
  addFiles: newFiles => set({files: [...newFiles]}),

  site: defaultDropdownOptions[0].value,
  setSite: site => set({site}),

  fileType: DropFileTypeEnum.BOL,
  setFileType: fileType => set({fileType}),
}))

/**
 * @param {{
 *  enable?: boolean
 *  onUpdate?: (newFiles: File[]) => void
 * }} [options]
 */
export function useListenDroppedFiles({enable = true, onUpdate = noop} = {}) {
  const files = useDroppedFiles(state => state.files)
  const prevFiles = useRef(files)
  const onUpdateCBRef = useRef(onUpdate)

  useEffect(() => {
    onUpdateCBRef.current = onUpdate
  }, [onUpdate])

  useEffect(() => {
    if (enable) {
      const newFiles = differenceWith(files, prevFiles.current)
      if (newFiles.length > 0) {
        onUpdateCBRef.current(newFiles)
      }
    }
    prevFiles.current = files
  }, [enable, files])
}
