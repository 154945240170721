import React from "react"
import {useTranslation, Trans} from "react-i18next"

import {Modal} from "components/core/Modal"
import Container from "components/core/Container"
import DropdownList from "components/shared/fields/Dropdown"
import {CloseButtonIcon} from "components/shared/icons/btn"
import {
  UploadFileZoneIcon,
  UploadCSVZoneIcon,
} from "components/shared/icons/upload"

import DropFileZone from "./DropFileZone"
import Radio from "components/shared/fields/Radio"
import {DropFileTypeEnum} from "lib/store/types"
import BolCsvPrecheckModal from "./BolCsvPrecheckModal"

/**
 * @typedef {import('lib/store/types').DropFileType}  DropFileType
 */

/**
 * @type {React.FC<{
 *  onRequestClose?: () => void
 * }>}
 * @returns
 */
const CloseButton = ({onRequestClose}) => {
  return (
    <button
      className="absolute right-4 top-4"
      onClick={onRequestClose}
      data-testid="ic-close"
    >
      <CloseButtonIcon />
    </button>
  )
}
/**
 * @typedef {import("components/shared/fields/Dropdown").OptionItem<string>} siteOption
 * @typedef {{
 *  visible: boolean
 *  siteOptions: siteOption[]
 *  typeOptions: import('lib/store/types').DropFileType[]
 *  selectedType: import('lib/store/types').DropFileType
 *  onSelectType?: (fileType: import("lib/store/types").DropFileType) => void
 *  selectFileAble: boolean
 *  onRequestClose?: () => void
 *  onSelectSite?: (siteOption: siteOption) => void
 *  onSelectFiles: (newFiles: FileList | null) => void
 * }} DropFileModalLayoutProps
 * @type {React.FC<DropFileModalLayoutProps>}
 * @returns
 */
export const DropFileModalLayout = ({
  visible,
  siteOptions,
  typeOptions,
  selectedType,
  onSelectType,
  selectFileAble,
  onRequestClose,
  onSelectSite,
  onSelectFiles,
}) => {
  const {t} = useTranslation()
  const allowMultipleFiles = selectedType === DropFileTypeEnum.BOL

  const fileZoneMask = selectFileAble ? null : (
    <div className="absolute z-10 inset-0 bg-white opacity-50"></div>
  )

  const disableDropdown = siteOptions.length === 1

  return (
    <Modal visible={visible}>
      <Container className="p-6 pt-16 xl:px-24 xl:pb-16 shadow-lg">
        <CloseButton onRequestClose={onRequestClose} />

        <div className="text-grey-70 font-bold text-xs mb-3">
          {t("fileSelectSite")}
        </div>
        <DropdownList
          disabled={disableDropdown}
          name="site"
          options={siteOptions}
          defaultOption={siteOptions[0]}
          containerClassName="mb-6 w-full"
          placeholder={t("siteDropdownPH")}
          onSelectOption={onSelectSite}
        />

        <div className="text-grey-70 font-bold text-xs mb-3">
          {t("fileUploadFile")}
        </div>
        <div className="flex gap-x-8 mb-6">
          {typeOptions.map((typeOption, i) => (
            <Radio
              onChange={e =>
                onSelectType?.(/** @type {DropFileType} */ (e.target.value))
              }
              key={typeOption}
              name="file-type"
              value={typeOption}
              label={t(`fileUploadTypes.${typeOption}`)}
              checked={selectedType === typeOption}
            />
          ))}
        </div>
        <DropFileZone
          className="bg-light md:py-14 md:w-[600px] lg:w-[888px] border border-gray-3 rounded-lg relative"
          disabled={!selectFileAble}
          onFileChanges={onSelectFiles}
          multiple={selectedType === DropFileTypeEnum.BOL}
          inputId={`input_file_upload_${selectedType}`}
          accept={
            selectedType === DropFileTypeEnum.Customs
              ? "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,image/*"
          }
        >
          {React.createElement(
            selectedType === DropFileTypeEnum.BOL
              ? UploadFileZoneIcon
              : UploadCSVZoneIcon,
            {
              className: "mb-10 mx-auto",
            },
            null,
          )}
          {({selectFile}) => (
            <p className="mb-5 text-center text-2xl leading-normal text-black font-medium">
              <Trans
                i18nKey="fileDragAndDropHint"
                count={allowMultipleFiles ? 2 : 1}
              >
                Drag and drop your files, or
                <button
                  className="inline-block font-semibold text-primary"
                  onClick={selectFile}
                >
                  Browse
                </button>
              </Trans>
            </p>
          )}
          <div className="text-center text-sm text-grey-70 font-medium">
            {selectedType === DropFileTypeEnum.BOL ? (
              <>
                <p>{t("fileUploadHint")}</p>
                <Trans i18nKey="fileUploadHintCustoms">
                  {" "}
                  <a
                    download="BOL_Template.csv"
                    className="inline-block font-semibold text-primary"
                    href="/BOL_Template.csv"
                  >
                    Download the template
                  </a>
                  {" (Please DO NOT adjust the sequence)"}
                </Trans>
              </>
            ) : (
              <>
                <p>&nbsp;</p>
                <Trans i18nKey="fileUploadHintCustoms">
                  {" "}
                  <a
                    download="Customs_Declaration_Template.csv"
                    className="inline-block font-semibold text-primary"
                    href="/Customs_Declaration_Template.csv"
                  >
                    Download the template
                  </a>
                  {" (Please DO NOT adjust the sequence)"}
                </Trans>
              </>
            )}
          </div>
          {fileZoneMask}
        </DropFileZone>
        <BolCsvPrecheckModal />
      </Container>
    </Modal>
  )
}
