import {useMutation} from "lib/query"
import {giantRequest} from "lib/apiClient"

const MAIN_MUTATION_KEY = ["UpdateCustomDeclaration"]

/**
 * @typedef {object} UpdateCustomsDeclarationParams
 * @property {string} fileName
 * @property {File} file
 * @property {string} site
 */

/**
 * @param {UpdateCustomsDeclarationParams} params
 * @param {AxiosRequestConfig=} options
 */
export async function updateCustomsDeclaration(
  {file, fileName, site},
  options,
) {
  // currently, the result of this api is not important, don't need to process it
  return giantRequest({
    ...options,
    url: "/customDeclare",
    method: "Post",
    data: {
      doc: fileName,
      site,
      options: {
        needCreate: true,
        needOverwrite: true,
      },
    },
  })
}

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  Awaited<ReturnType<typeof updateCustomsDeclaration>>,
 *  Error,
 *  UpdateCustomsDeclarationParams,
 *  undefined
 * >=} options
 */
export function useUpdateCustomDeclaration(options) {
  return useMutation(updateCustomsDeclaration, {
    mutationKey: MAIN_MUTATION_KEY,
    ...options,
  })
}

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
