import {
  GetDocumentAnalysisCommand,
  StartDocumentAnalysisCommand,
} from "@aws-sdk/client-textract"

import {giantRequest} from "lib/apiClient"
import {awsConfig, textractClient} from "lib/awsClient"

/**
 * Object representing the result of a BoL conversion
 * @typedef {Object} PostBoLResult
 * @property {string[]} success - List of successfully converted BoL SNs
 * @property {string[]} fail - List of failed file names
 */

/**
 * Parameters for posting BoL data
 * @typedef {Object} PostBoLParams
 * @property {string} [filePrefix] - AWS Folder Name
 * @property {string} site - Update for specific site
 * @property {Array<{fileKey: string, id: string}>} [jobs] - List of jobs
 * @property {"csv" | "bol"} [format]
 *
 * @example
 * {
 *  filePrefix: "GH22010GCK",
 *  site: "GVM1",
 *  jobs: [{fileKey: "file1", id: "1"}, {fileKey: "file2", id: "2"}]
 * }
 */

/**
 * @param {PostBoLParams} query
 * @param {AxiosRequestConfig} options
 * @returns {AxiosPromise<PostBoLResult>}
 */
export function postStartConvertBoL(query, options = {}) {
  return giantRequest({
    ...options,
    url: "/bol",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      doc: query.filePrefix,
      site: query.site,
      jobs: query.jobs,
      format: query.format,
    },
  })
}

/**
 * Parameters for starting Textract on a document
 * @typedef {Object} PostStartTextractDocumentParams
 * @property {string} fileName - The name of the file to be processed
 * @property {string} folder - The name of the folder containing the file
 */

/**
 * @param {PostStartTextractDocumentParams} input
 * @param {{
 *  signal?: AbortSignal
 * }} [options]
 * @returns
 */
export async function postStartTextractDoc({folder, fileName}, options) {
  return textractClient.send(
    new StartDocumentAnalysisCommand({
      DocumentLocation: {
        S3Object: {
          Bucket: awsConfig.bucketName,
          Name: `${folder}/${fileName}`,
        },
      },
      OutputConfig: {
        S3Bucket: awsConfig.bucketName,
        S3Prefix: "result",
      },
      FeatureTypes: ["FORMS"],
    }),
  )
}

/**
 * @see https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-textract/classes/getdocumentanalysiscommand.html
 * @param {import("@aws-sdk/client-textract").GetDocumentAnalysisCommandInput} input
 * @param {{
 *  signal?: AbortSignal
 * }} [options]
 * @returns
 */
export async function getTextractDocAnalysis(input, options) {
  return textractClient.send(new GetDocumentAnalysisCommand(input))
}

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
/**
 * @template T
 * @typedef {import('axios').AxiosPromise<T>} AxiosPromise
 */
