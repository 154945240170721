import React from "react"
import {Modal} from "components/core/Modal"
import Container from "components/core/Container"
import {CloseButtonIcon} from "components/shared/icons/btn"
import {useTranslation} from "react-i18next"
import BolCsvPrecheckTable from "./BolCsvPrecheckTable"
import {z} from "zod"
import {BolPrecheckResultDTO} from "@domain"
import {TextButton} from "components/shared/buttons"
import {openDropFileModal} from "lib/store/useDropFileModal"
import ICError from "components/shared/icons/error"

/**
 * @typedef {object} BolCsvPrecheckModalLayoutProps
 * @property {boolean} visible
 * @property {() => void} [onClose]
 * @property {boolean} [isLoading]
 * @property {z.infer<typeof BolPrecheckResultDTO>} [result]
 * @property {boolean} [uploadable]
 * @property {() => void} [onUpload]
 */

/**
 * @type {React.FC<BolCsvPrecheckModalLayoutProps>}
 */
const BolCsvPrecheckModalLayout = ({
  visible,
  onClose,
  isLoading,
  result,
  uploadable,
  onUpload,
}) => {
  const {t} = useTranslation()

  const handleCancel = () => {
    onClose?.()
    openDropFileModal()
  }

  return (
    <Modal visible={visible}>
      <Container className="p-4 flex flex-col items-center shadow-lg">
        <button
          className="self-end mb-6"
          onClick={onClose}
          data-testid="ic-close"
        >
          <CloseButtonIcon />
        </button>

        <div className="px-2 xl:px-24 w-full">
          <h1 className="text-grey font-medium text-xl mb-10 self-start">
            {t("bolUploadPrecheckModal.title")}
          </h1>

          <BolCsvPrecheckTable
            isLoading={isLoading}
            className="mb-6"
            result={result}
          />

          {!isLoading ? (
            <div className="flex justify-between items-center">
              {!uploadable ? (
                <div className="flex flex-row items-center">
                  <ICError className="mr-1" />
                  <span className="text-grey text-sm">
                    {t("bolUploadPrecheckModal.warning")}
                  </span>
                </div>
              ) : (
                <div />
              )}
              <div className="flex gap-10">
                <TextButton onClick={handleCancel}>{t("btnCancel")}</TextButton>
                {uploadable ? (
                  <TextButton onClick={onUpload}>
                    {t("bolUploadPrecheckModal.upload")}
                  </TextButton>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    </Modal>
  )
}

export default BolCsvPrecheckModalLayout
