import * as React from "react"
import {useTranslation} from "react-i18next"

import {openDropFileModal} from "lib/store/useDropFileModal"
import {closeBolUploadingWindow} from "lib/store/useBolUploadingWindow"
import {
  useRequestHandlersState,
  UploadFileQueueEnum,
  BOLFileProcessingStateEnum,
} from "services/uploadFile"
import {useConfirmModal} from "lib/store/useConfirmModal"
import {useCDUploadModalVisible as useIsCustomLoading} from "lib/store/useCDUploadModal"

import {UploadFileIcon} from "../../shared/icons/upload"

export function useBOLUploadStatus() {
  const {current: uploadingFiles, isCompleted: isUploadCompleted} =
    useRequestHandlersState({
      name: UploadFileQueueEnum.UploadFiles,
    })

  const {current: analyzingFiles, isCompleted: isAnalyzingCompleted} =
    useRequestHandlersState({
      name: UploadFileQueueEnum.UploadedBOLFiles,
    })

  const status = isUploadCompleted
    ? analyzingFiles.length === 0 && isAnalyzingCompleted
      ? BOLFileProcessingStateEnum.AllCompleted
      : BOLFileProcessingStateEnum.Identifying
    : uploadingFiles.length > 0
    ? BOLFileProcessingStateEnum.Uploading
    : BOLFileProcessingStateEnum.Idle

  // console.log(
  //   "###useBOLUploadStatus",
  //   status,
  //   isUploadCompleted,
  //   analyzingFiles,
  //   isAnalyzingCompleted,
  // )

  return {
    status,
  }
}

/**
 * @type {React.FC<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>}
 * @returns
 */
export const UploadFileButton = buttonProps => {
  const {t} = useTranslation()
  const isCustomDeclareLoading = useIsCustomLoading()
  const {status} = useBOLUploadStatus()
  const createConfirm = useConfirmModal(state => state.createConfirm)

  const ableToUpload =
    !isCustomDeclareLoading &&
    (status === BOLFileProcessingStateEnum.AllCompleted ||
      status === BOLFileProcessingStateEnum.Idle)

  return (
    <button
      className="touchable space-x-1"
      {...buttonProps}
      onClick={
        ableToUpload
          ? () => {
              openDropFileModal()
              requestAnimationFrame(closeBolUploadingWindow)
            }
          : () => {
              createConfirm("Please wait for file processing finished")
            }
      }
    >
      <UploadFileIcon className="inline-block" />
      <span className="align-middle font-semibold text-base leading-5 ">
        {t("btnUploadFile")}
      </span>
    </button>
  )
}
