import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const UploadFileIcon = (props = {}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.517 13.431a.771.771 0 1 0 1.091-1.09l-4.114-4.115a.771.771 0 0 0-1.091 0l-4.115 4.114a.772.772 0 0 0 1.091 1.091l2.798-2.797v7.703c0 .256.345.463.771.463.426 0 .772-.207.772-.463v-7.703l2.797 2.797zm3.94 8.843v-8.96c0-.378.345-.685.772-.685.426 0 .771.307.771.685v8.96c0 1.476-1.25 2.697-2.829 2.697H9.83c-1.58 0-2.829-1.22-2.829-2.697v-8.96c0-.378.345-.685.771-.685.426 0 .772.307.772.685v8.96c0 .746.593 1.326 1.286 1.326H22.17c.693 0 1.286-.58 1.286-1.326z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const UploadFileZoneIcon = props => (
  <svg
    width="179"
    height="164"
    viewBox="0 0 179 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.5278 50.1705H159.992" stroke="black" strokeWidth="0.691944" />
    <circle cx="89.068" cy="82" r="69.8864" fill="url(#paint0_linear_9_8649)" />
    <g filter="url(#filter0_d_9_8649)">
      <rect
        x="19.1816"
        y="34.9478"
        width="141.157"
        height="91.3366"
        rx="2.76778"
        fill="white"
      />
      <rect
        x="19.3546"
        y="35.1208"
        width="140.811"
        height="90.9907"
        rx="2.59479"
        stroke="#EEEEEE"
        strokeWidth="0.345972"
      />
    </g>
    <path
      d="M19.1816 37.7156C19.1816 36.187 20.4208 34.9478 21.9494 34.9478H157.57C159.099 34.9478 160.338 36.187 160.338 37.7156V50.1706H19.1816V37.7156Z"
      fill="#EFEFEF"
    />
    <path
      d="M19.1816 50.1705H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1816 65.3934H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M66.2339 34.9478L66.2339 126.284"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M113.286 34.9478L113.286 126.284"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1816 80.6161H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1816 95.8389H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1816 111.062H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.222 86.6235C107.006 87.8395 105.035 87.8395 103.819 86.6235L92.5275 75.3323V109.505C92.5275 110.747 91.1334 111.754 89.4138 111.754C87.6941 111.754 86.3 110.747 86.3 109.505V75.3323L75.0089 86.6235C73.7929 87.8395 71.8213 87.8395 70.6054 86.6235C69.3894 85.4075 69.3894 83.436 70.6054 82.22L87.212 65.6133C88.428 64.3973 90.3995 64.3973 91.6155 65.6133L108.222 82.22C109.438 83.436 109.438 85.4075 108.222 86.6235Z"
      fill="url(#paint1_linear_9_8649)"
    />
    <defs>
      <filter
        id="filter0_d_9_8649"
        x="0.545277"
        y="19.0792"
        width="178.429"
        height="128.609"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76778" />
        <feGaussianBlur stdDeviation="9.31818" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9_8649"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9_8649"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_9_8649"
        x1="89.068"
        y1="12.1136"
        x2="89.068"
        y2="151.886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.444652" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9_8649"
        x1="89.7597"
        y1="64.7013"
        x2="89.7597"
        y2="111.754"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.520702" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
    </defs>
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const UploadCSVZoneIcon = props => (
  <svg
    width="179"
    height="164"
    viewBox="0 0 179 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.5278 50.1705H159.992" stroke="black" strokeWidth="0.691944" />
    <circle
      cx="89.0682"
      cy="82"
      r="69.8864"
      fill="url(#paint0_linear_481_4207)"
    />
    <g filter="url(#filter0_d_481_4207)">
      <rect
        x="19.1819"
        y="34.9478"
        width="141.157"
        height="91.3366"
        rx="2.76778"
        fill="white"
      />
      <rect
        x="19.3549"
        y="35.1208"
        width="140.811"
        height="90.9907"
        rx="2.59479"
        stroke="#EEEEEE"
        strokeWidth="0.345972"
      />
    </g>
    <path
      d="M19.1819 37.7156C19.1819 36.187 20.4211 34.9478 21.9497 34.9478H157.571C159.099 34.9478 160.339 36.187 160.339 37.7156V50.1706H19.1819V37.7156Z"
      fill="#EFEFEF"
    />
    <path
      d="M19.1819 50.1705H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1819 65.3934H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M66.2341 34.9478L66.2341 126.284"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M113.286 34.9478L113.286 126.284"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1819 80.6161H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1819 95.8389H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M19.1819 111.062H160.338"
      stroke="#EBEBEB"
      strokeWidth="0.345972"
    />
    <path
      d="M53.8958 86.0778C53.8958 93.1609 58.5619 98.1291 65.6785 98.1291C71.6203 98.1291 75.85 94.705 77.0249 89.334H69.9418C69.1026 91.1131 67.5248 92.0866 65.5778 92.0866C62.5566 92.0866 60.576 89.7368 60.576 86.0778C60.576 82.4523 62.5566 80.1024 65.5778 80.1024C67.5248 80.1024 69.1026 81.076 69.9418 82.8551H77.0249C75.85 77.4841 71.6203 74.06 65.6785 74.06C58.5619 74.06 53.8958 79.0282 53.8958 86.0778Z"
      fill="url(#paint1_linear_481_4207)"
    />
    <path
      d="M98.9057 90.7774C98.9057 83.1908 87.5258 84.3322 87.5258 80.8074C87.5258 79.6661 88.2979 79.0954 89.2714 79.1289C90.4799 79.1289 91.3862 79.8003 91.4534 81.2774H98.6036C98.3686 76.5777 94.8103 73.9257 89.3721 73.9257C84.3703 73.9257 80.5098 76.4098 80.5098 81.0424C80.4091 89.2668 91.8898 87.4877 91.9233 91.281C91.9233 92.3888 91.017 92.993 89.8421 92.993C88.6336 92.993 87.5929 92.3888 87.4586 90.6096H80.4427C80.7112 95.6785 84.8067 98.1962 90.077 98.1962C95.6831 98.1962 98.9057 94.94 98.9057 90.7774Z"
      fill="url(#paint2_linear_481_4207)"
    />
    <path
      d="M113.647 91.3817L108.275 74.295H101.226L109.417 97.9613H117.843L126 74.295H118.984L113.647 91.3817Z"
      fill="url(#paint3_linear_481_4207)"
    />
    <defs>
      <filter
        id="filter0_d_481_4207"
        x="0.545521"
        y="19.0792"
        width="178.429"
        height="128.609"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76778" />
        <feGaussianBlur stdDeviation="9.31818" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_481_4207"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_481_4207"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_481_4207"
        x1="89.0682"
        y1="12.1136"
        x2="89.0682"
        y2="151.886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.444652" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_481_4207"
        x1="91.1857"
        y1="72.7843"
        x2="91.1857"
        y2="105.155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.520702" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_481_4207"
        x1="91.1857"
        y1="72.7843"
        x2="91.1857"
        y2="105.155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.520702" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_481_4207"
        x1="91.1857"
        y1="72.7843"
        x2="91.1857"
        y2="105.155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#419AD7" />
        <stop offset="0.520702" stopColor="#1943A6" />
        <stop offset="1" stopColor="#001587" />
      </linearGradient>
    </defs>
  </svg>
)
