import React from "react"
import {StaticImage} from "gatsby-plugin-image"

import IMG_GIANT_GROUP from "../../../assets/images/giant_group.svg"

/**
 * @typedef {React.ImgHTMLAttributes<HTMLImageElement>} ImgProps
 */

/**
 * @type {React.FC<{
 *  className?: string
 *  imgClassName?: string
 *  siteEnv?: string
 * } & ImgProps>}
 */
const GiantLogo = ({
  className,
  siteEnv,
  imgClassName,
  width = 142,
  height = 48,
  alt = "giant",
  ...imgProps
}) => {
  const logoImg = (
    <img
      alt={alt}
      width={width}
      height={height}
      className={className}
      {...imgProps}
      src={IMG_GIANT_GROUP}
    />
  )

  if (!siteEnv) return logoImg
  return (
    <div className="relative">
      {logoImg}
      <span className="absolute bottom-0 left-2 text-skyBlue-70 font-semibold">
        {siteEnv}
      </span>
    </div>
  )
}

export default GiantLogo
