import {S3Client} from "@aws-sdk/client-s3"
import {TextractClient} from "@aws-sdk/client-textract"
import {omit} from "lodash-es"

/**
 * @typedef {{
 *  bucketName: string
 *  dirName?: string
 *  region: string
 *  s3Url?: string
 * }} Config
 */

/**
 * @typedef {{
 *  bucketName: string
 *  dirName?: string
 * }} S3Config
 */

/**
 * @type { Config & {
 *  accessKeyId: string
 *  secretAccessKey: string
 * }}
 */
export const defaultConfig = {
  bucketName: process.env.BOL_S3_BUCKET_NAME ?? "null",
  dirName: process.env.BOL_S3_DIR_NAME,
  region: process.env.BOL_S3_REGION ?? "null",
  accessKeyId: process.env.BOL_S3_ACCESS_KEY_ID ?? "null",
  secretAccessKey: process.env.BOL_S3_SECRET_ACCESS_KEY ?? "null",
}

export const awsConfig = omit(defaultConfig, ["accessKeyId", "secretAccessKey"])
// s3 config for custom declaration
export const cdS3Config = {
  bucketName: process.env.CD_S3_BUCKET_NAME ?? "null",
  dirName: process.env.CD_S3_DIR_NAME,
  region: process.env.CD_S3_REGION ?? "null",
}

export const s3Client = new S3Client({
  region: defaultConfig.region,
  credentials: {
    accessKeyId: defaultConfig.accessKeyId,
    secretAccessKey: defaultConfig.secretAccessKey,
  },
})

export const cds3Client = new S3Client({
  region: cdS3Config.region,
  credentials: {
    accessKeyId: process.env.CD_S3_ACCESS_KEY_ID ?? "",
    secretAccessKey: process.env.CD_S3_SECRET_ACCESS_KEY ?? "",
  },
})

export const textractClient = new TextractClient({
  region: defaultConfig.region,
  credentials: {
    accessKeyId: defaultConfig.accessKeyId,
    secretAccessKey: defaultConfig.secretAccessKey,
  },
})
