import * as React from "react"
import {useTranslation} from "react-i18next"

import {BoLUploadTypeEnum, getCarriersByShipType} from "domain/bol-meta"
import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"

import Combobox from "components/shared/fields/Combobox"

import {
  useEditedShipType,
  useEditedCarrier,
  useBolInitValue,
} from "./bol-edit.hook"

/** @type {import('components/shared/fields/Combobox').ComboboxProps<CarrierAbbrMapping>["showCustomOption"]} */
const showCustomOptionFn = (query = "", options) =>
  query.length > 0 &&
  !options.some(
    o =>
      o.abbr.toUpperCase() === query.trim().toUpperCase() ||
      o.carrier.toUpperCase() === query.trim().toUpperCase(),
  )

/**
 * @param {import('domain/carrier').CarrierAbbrMapping} carrier
 * @param {string} query
 */
const carrierQueryFilter = (carrier, query = "") =>
  carrier.abbr.toLowerCase().includes(query.trim().toLowerCase()) ||
  query.trim().toLowerCase().includes(carrier.carrier.toLowerCase())

export const CarrierSelect = ({}) => {
  const {t} = useTranslation()
  const isUpdating = useIsUpdatingEditableBillOfLading()

  const [carrier, updateCarrier] = useEditedCarrier()
  const editedShipType = useEditedShipType()
  const bol = useBolInitValue()
  const disabled = bol.uploadType !== BoLUploadTypeEnum.CD || isUpdating

  const options = React.useMemo(
    // @ts-ignore
    () => getCarriersByShipType(editedShipType),
    [editedShipType],
  )

  return (
    <Combobox
      name="carrier"
      placeholder={t("bolEditCarrierLabel")}
      searchPlaceholder=""
      options={options}
      selectedOption={carrier}
      defaultSelectedOption={carrier}
      onOptionSelect={updateCarrier}
      disabled={disabled}
      displayOption={o => o.abbr}
      idExtractor={o => o.abbr}
      valueExtractor={o => o.carrier}
      optionQueryFilter={carrierQueryFilter}
      isOptionCustom={o => !!o.isCustom}
      showCustomOption={showCustomOptionFn}
      createCustomOptionFromQuery={query => ({
        abbr: query.trim(),
        carrier: query.trim(),
        isCustom: true,
      })}
    />
  )
}

export default CarrierSelect

/**
 * @typedef {import('domain/carrier').CarrierAbbrMapping} CarrierAbbrMapping
 */
