import create from "zustand"

/**
 * @type {import("./types").BolCsvPrecheckModalStore}
 */
export const useBolCsvPrecheckModal = create(set => ({
  showModal: false,
  openModal: () => set({showModal: true}),
  closeModal: () => set({showModal: false}),
}))

export function openBolCsvPrecheckModal() {
  useBolCsvPrecheckModal.setState({
    showModal: true,
  })
}

export function closeBolCsvPrecheckModal() {
  useBolCsvPrecheckModal.setState({
    showModal: false,
  })
}
