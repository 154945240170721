import {Link} from "gatsby"
import {isNil} from "lodash-es"
import React from "react"

import GiantLogo from "../icons/giant"
import {UploadFileButton} from "components/features/drop-file/UploadFileButton"
import AvatarButtonContainer from "components/features/avatar-button/AvatarButton"

/**
 * @typedef {{
 *  title: string
 *  url: string
 *  target?: string
 *  onClick?: () => any
 * }} LinkItem
 *
 * @type {React.FC<{
 *  links?: LinkItem[]
 *  siteName?: string
 *  siteEnv?: string
 * }>}
 */
const Header = ({links, siteEnv}) => {
  // TODO: enable AccountIcon in future
  return (
    <header className="layout-header">
      <nav className="mx-auto container flex flex-row">
        <GiantLogo className="mr-5" siteEnv={siteEnv} />
        <LinkList links={links} />
        <div className="flex-grow"></div>
        <UploadFileButton />
        <AvatarButtonContainer className="ml-6 self-center" />
      </nav>
    </header>
  )
}

export default Header

// (isBrowser ? window.location.search : "")

/**
 * @type {React.FC<{
 *  links?: LinkItem[]
 * }>}
 */
const LinkList = ({links}) => {
  if (isNil(links)) {
    return null
  }
  return (
    <ul className="flex flex-row justify-center items-center space-x-4">
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.url}
          onClick={link.onClick}
          target={link.target}
          className="font-normal text-grey-70 rounded-lg hover:ring-2 p-1 transition-shadow"
          activeClassName="!text-black"
        >
          {link.title}
        </Link>
      ))}
    </ul>
  )
}
