module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Giant eShipping","short_name":"giant","start_url":"/","background_color":"#005EB8","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"95ba4ada70494f99aa7caff64e043cca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","zh-TW"],"defaultLanguage":"en-US","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en-US","ns":["common","login"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/","languages":["en-US"]},{"matchPath":"/error-list","languages":["en-US"]},{"matchPath":"/404","languages":["en-US"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
