import React from "react"
import {flip, offset, shift, useFloating} from "@floating-ui/react-dom"

import AvatarMenu from "./AvatarMenu"
import Avatar from "./Avatar.layout"
import useUserSession from "hooks/useUserSession"
import {upperCase} from "lodash-es"

const AvatarButtonLayout = React.forwardRef(
  (
    /** @type {React.ButtonHTMLAttributes<HTMLButtonElement>} */
    {className, children, ...restProps},
    ref,
  ) => (
    <button ref={ref} className={className} {...restProps}>
      <Avatar>{children}</Avatar>
    </button>
  ),
)

/**
 * @type {React.FC<{
 *  className?: string
 * }>}
 */
const AvatarButtonContainer = ({className = ""}) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const {x, y, reference, floating, strategy} = useFloating({
    placement: "bottom-end",
    strategy: "absolute",
    middleware: [offset(2), flip(), shift()],
  })
  const {data} = useUserSession()
  const payload = data?.getIdToken().payload
  const username = payload?.["cognito:username"]
  const email = payload?.["email"]

  return (
    <>
      <AvatarButtonLayout
        className={className}
        ref={reference}
        onClick={() => setMenuOpen(open => !open)}
      >
        {upperCase(username?.charAt(0))}
      </AvatarButtonLayout>
      {isMenuOpen ? (
        <AvatarMenu
          username={username}
          email={email}
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: "max-content",
          }}
        />
      ) : null}
    </>
  )
}

export default AvatarButtonContainer
