import {Amplify, Auth} from "aws-amplify"

import {isBrowser} from "domain/browser"

const defaultRedirectCallback = `http://localhost:8000/auth/callback/`
const defaultRedirectLogout = `http://localhost:8000/auth/login/`

function getRedirectLogoutURL() {
  return isBrowser
    ? `${window.location.origin}${process.env.GATSBY_AWS_OAUTH_LOGOUT_URL}`
    : defaultRedirectLogout
}
function getRedirectCallbackURL() {
  return isBrowser
    ? `${window.location.origin}${process.env.GATSBY_AWS_OAUTH_REDIRECT_URL}`
    : defaultRedirectCallback
}

export const awsConfig = () => ({
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: process.env.GATSBY_AWS_IDENTITY_POOL_ID,

    // (required)- Amazon Cognito Region
    region: process.env.GATSBY_AWS_AUTH_REGION,

    // (optional) - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: "XX-XXXX-X",

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.GATSBY_AWS_USER_POOL_ID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID,

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // (optional) - Cookie path
    //   path: '/',
    //   // (optional) - Cookie expiration in days
    //   expires: 365,
    //   // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict' | 'lax',
    //   // (optional) - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // }
    oauth: {
      domain: process.env.GATSBY_AWS_OATH_DOMAIN,
      scope: ["email", "openid", "phone"],
      redirectSignIn: getRedirectCallbackURL(),
      redirectSignOut: getRedirectLogoutURL(),
      responseType: "code",
    },
  },
})

export function initialize() {
  Amplify.configure(awsConfig())
}

export function openHostedLogin() {
  return Auth.federatedSignIn()
}

/**
 * @typedef {Awaited<ReturnType<typeof Auth.currentSession>>} CurrentSession
 */

export async function oauthToken() {
  return Auth.currentSession()
}

export async function logout() {
  return Auth.signOut()
}
