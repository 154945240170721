import {z} from "zod"

import {giantRequest} from "lib/apiClient"
import {useQuery, useQueryClient} from "lib/query"

const MAIN_KEY = "distinct"

/**
 * @description schema for query distinct values
 */
const DistinctAPIParams = z.object({
  carrier: z.enum(["true"]).optional(),
  vendor: z.enum(["true"]).optional(),
  transportation: z.enum(["true"]).optional(),
})
/** @typedef {z.infer<typeof DistinctAPIParams>} DistinctAPIParams */

/**
 * @description schema for single distinct value
 */
const DistinctValue = z
  .array(z.string().catch("").default(""))
  .default([])
  .catch([])
  .transform(v => v.filter(d => !!d?.trim()).sort((a, b) => a.localeCompare(b)))

/**
 * @description schema for the result of distinct values
 */
const DistinctResult = z.object({
  carrier: DistinctValue,
  vendor: DistinctValue,
  transportation: DistinctValue,
})
/** @typedef {z.infer<typeof DistinctResult>} DistinctResult */

/**
 * @param {DistinctAPIParams} params
 * @param {import('axios').AxiosRequestConfig=} config
 */
export async function getDistinct(params, config) {
  return giantRequest({
    ...config,
    url: "/bols/distinct",
    method: "POST",
    data: params,
  }).then(r => DistinctResult.parse(r.data))
}

/**
 * @param {DistinctAPIParams} params
 * @returns {[string, DistinctAPIParams]}
 */
const _getKey = params => [MAIN_KEY, params]

/**
 * @type {import('@tanstack/react-query').QueryFunction<
 *  Awaited<ReturnType<getDistinct>>,
 *  [string, Parameters<getDistinct>[0]],
 * >}
 */
const _queryFn = async ({queryKey: [_primaryKey, queryParams], signal}) => {
  return getDistinct(queryParams, {signal})
}

/**
 * @template [T=DistinctResult]
 * @param {DistinctAPIParams} params
 * @param {import('lib/query').PUseQueryOptions<DistinctAPIParams, DistinctResult, T>} [options]
 */
export function useDistinct(params, options = {}) {
  return useQuery(_getKey(params), _queryFn, options)
}
useDistinct.getKey = _getKey
useDistinct.queryFn = _queryFn

export function useRefreshDistinctFn() {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries([MAIN_KEY])
}
