import * as React from "react"
import {useTranslation} from "react-i18next"
import range from "lodash-es/range"
import ReactTooltip from "react-tooltip"

import ICWrong from "components/shared/icons/wrong"
import {CustomDeclarationCSVKeyMapping} from "domain/custom-declaration"

const TOOLTIP_ID = "cd-upload-pre-check-tooltip"

/**
 * @param {import('domain/custom-declaration').CustomDeclarationKey} key
 */
const widthClassByKey = key => {
  if (key === "row") return "w-[120px]" // 64 + 28*2
  if (key === "date" || key === "invoice") return "w-[180px]"
  return "w-[204px]"
}

/**
 * @description tr for single custom declare result
 * @param {{
 *   cd: import('domain/custom-declaration').CustomDeclaration,
 *   isLoading?: boolean
 * }} param0
 */
const CDResultRow = ({cd, isLoading = false}) => {
  const {t} = useTranslation()

  /**
   * @param {DataArr_DTO|Data_DTO} cdItem
   * @param {import('domain/custom-declaration').CustomDeclarationKey} key
   */
  const renderData = (cdItem, key) => {
    let {data} = cdItem
    const str = typeof data !== "string" ? data.join(";") : data

    if (!str) return <div>&nbsp;</div>
    return (
      <div
        className="line-clamp-1 text-ellipsis break-all"
        data-for={key === "invoice" ? TOOLTIP_ID : undefined}
        data-tip={str}
      >
        {str}
      </div>
    )
  }

  /**
   * @param {DataArr_DTO|Data_DTO} cdItem
   */
  const renderErrOrWarning = cdItem => {
    if (cdItem.warning) {
      return (
        <div className="mt-1 text-primary font-medium !text-[10px] ">
          {t(`cdUploadingResultWarning`, {
            context: cdItem.warning,
          })}
        </div>
      )
    }
    if (cdItem.error) {
      return (
        <div className="flex row items-center mt-1 text-error font-medium !text-[10px]">
          <ICWrong className="mr-1" />
          {t(`cdUploadingResultError`, {
            context: cdItem.error,
          })}
        </div>
      )
    }

    return null
  }

  return (
    <tr className="border-b last-of-type:border-b-0 border-gray-3">
      {CustomDeclarationCSVKeyMapping.map(key => (
        <td
          className={`${widthClassByKey(
            key,
          )} pr-6 py-2 first:px-7 align-top first:text-center`}
          key={key}
        >
          {isLoading ? (
            <div className="h-3 inset-0 bg-grey-30 opacity-10 animate-pulse my-1 rounded-sm"></div>
          ) : null}
          {isLoading ? null : renderData(cd[key], key)}
          {isLoading ? null : renderErrOrWarning(cd[key])}
        </td>
      ))}
    </tr>
  )
}

const InvoicesTip = ({}) => {
  return (
    <ReactTooltip
      id={TOOLTIP_ID}
      place="bottom"
      effect="solid"
      backgroundColor="transparent"
      border={false}
      className="!opacity-100"
      clickable
      delayHide={200}
      offset={{top: 0, bottom: 0, left: 0, right: 0}}
      getContent={arrStr => {
        if (!arrStr) return null

        const arr = arrStr.split(";")

        return (
          <div className="px-4 py-3 rounded-lg bg-white border border-gray-3 shadow-md">
            <p className="text-black text-sm font-medium">
              {arr.map((arrValue, i) => (
                <div key={i}>{arrValue}</div>
              ))}
            </p>
          </div>
        )
      }}
    />
  )
}

/** @type {import('domain/custom-declaration').CustomDeclarationResult} */
// @ts-ignore
const cdResultPlaceholder = range(5).map(i =>
  CustomDeclarationCSVKeyMapping.reduce(
    (obj, key) => ({
      ...obj,
      [key]: {
        data: key === "invoice" ? [] : "",
      },
    }),
    {},
  ),
)

/**
 * @type {React.FC<{
 *   isLoading?: boolean,
 *   cdResult?: import('domain/custom-declaration').CustomDeclarationResult,
 *   className?: string,
 * }>}
 */
export const CDUploadResultTable = ({
  isLoading = true,
  cdResult = cdResultPlaceholder,
  className,
}) => {
  const {t} = useTranslation()

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [cdResult])

  return (
    <div
      className={`${
        className ?? ""
      }  rounded-lg border overflow-x-auto border-gray-3 overflow-y-hidden bg-light`}
    >
      <table className="flex flex-col min-w-[888px] h-[calc(100vh*0.475)]">
        <thead className="block text-left text-grey-70 text-xs font-bold">
          <tr className="border-b border-gray-3">
            {CustomDeclarationCSVKeyMapping.map(key => (
              <th
                className={`${widthClassByKey(
                  key,
                )} pr-6 py-2 first:px-7 first:text-center`}
                key={key}
              >
                {t(`cdUploadingResultTable.${key}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="block text-left text-grey text-sm font-normal overflow-y-auto">
          {cdResult.map((cd, i) => (
            <CDResultRow cd={cd} key={i} isLoading={isLoading} />
          ))}
        </tbody>
      </table>
      <InvoicesTip />
    </div>
  )
}

/**
 * @typedef {import('domain/custom-declaration').CustomDeclaration["custom_declaration"]} Data_DTO
 * @typedef {import('domain/custom-declaration').CustomDeclaration["invoice"]} DataArr_DTO
 */
