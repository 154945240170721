import {JobStatus} from "@aws-sdk/client-textract"
import {cds3Client, cdS3Config} from "lib/awsClient"
import {sortBy, isArray} from "lodash-es"
import {uuid} from "utils/getRandomID"

import {
  getTextractDocAnalysis,
  postStartConvertBoL,
  postStartTextractDoc,
} from "../textract"

import {postDocUploadRequest} from "./request"

// eslint-disable-next-line import/no-unresolved
import {ProcessState} from "./types"

/**
 * @typedef {import('./types').RequestHandlerListeners} RequestHandlerListeners
 * @typedef {import('./types').RequestHandlerStatus} RequestHandlerStatus
 */

export class RequestHandler {
  static ProcessState = ProcessState

  /**
   * @param {number | string} id
   */
  constructor(id) {
    this.id = String(id)
    /** @type {RequestHandlerListeners} */
    this.listeners = {
      state: [],
      progress: [],
      onSuccess: [],
      onError: [],
    }
    /** @type {RequestHandlerStatus} */
    this._status = {
      state: ProcessState.Idle,
      progress: 0,
    }
    this.statusProxy = new Proxy(this._status, {
      set: (obj, prop, value) => {
        if (prop === "state" || prop === "progress") {
          this._notifySubscribeUpdate(prop, value)
          // @ts-ignore
          obj[prop] = value
          return true
        }
        return false
      },
    })
    this._callbackFrameMap = new WeakMap()
    this.request = null
    this.abortController = null
    this.result = null
    this.createAt = Date.now()
  }

  get state() {
    return this.statusProxy.state
  }

  get progress() {
    return this.statusProxy.progress
  }

  /**
   * @typedef {{
   *  signal: AbortSignal
   *  notifyProgressUpdate: (progress: number) => void
   * }} RequestContext
   * @param {(ctx: RequestContext) => Promise<any>} request
   * @returns
   */
  createRequest(request) {
    this.abortController = new AbortController()
    this.request = request({
      signal: this.abortController.signal,
      notifyProgressUpdate: progress => {
        this.statusProxy.progress = progress
      },
    })
      .then(result => {
        this.statusProxy.state = ProcessState.Success
        this.result = result
        this._notifySubscribeUpdate("onSuccess", result)
        return result
      })
      .catch(error => {
        this.statusProxy.state = ProcessState.Failed
        this.result = null
        this._notifySubscribeUpdate("onError", error)
        console.log("### request error", error)
      })
      .finally(() => {
        this.cleanupRequest()
      })
    this.statusProxy.state = ProcessState.Pending

    return this.request
  }

  cancelRequest() {
    if (this.abortController) {
      this.abortController.abort()
    }
    // maybe need to do in next event-loop ?
    this.cleanupRequest()
  }

  cleanupRequest() {
    this.request = null
    this.abortController = null
  }

  /**
   * @template {keyof RequestHandlerListeners} T
   * @param {T} event
   * @param {RequestHandlerListeners[T][number] } callback
   */
  subscribeUpdate(event, callback) {
    console.log("## subscribe %s:: %s", event, this.id)
    // @ts-ignore
    this.listeners[event].push(callback)

    const unsubscribe = () => {
      console.log("## unsubscribe %s:: %s", event, this.id)
      // @ts-ignore
      this.listeners[event] = this.listeners[event].filter(
        // @ts-ignore
        listener => listener !== callback,
      )

      // cleanup animationFrame callback, avoid call when unmounted
      this._cleanCallbackAnimationFrame(callback)
      this._callbackFrameMap.delete(callback)
    }

    return unsubscribe
  }

  /**
   * @template {keyof RequestHandlerListeners} T
   * @param {T} event
   * @param {Parameters<RequestHandlerListeners[T][number]> } value
   */
  _notifySubscribeUpdate(event, value) {
    for (let callback of this.listeners[event]) {
      // only keep newest animationFrame callback
      // ? probably will cause state loss
      // ? maybe save frameId to a array, if array.length arrive waiting limit
      // ? then flush array and direct call callback
      // ? otherwise, push frameId to array

      this._cleanCallbackAnimationFrame(callback)

      const frameId = requestAnimationFrame(() => {
        // @ts-ignore
        callback(value)
      })
      this._callbackFrameMap.set(callback, frameId)
    }
  }

  /**
   * @param {*} callback
   */
  _cleanCallbackAnimationFrame(callback) {
    const prevFrameId = this._callbackFrameMap.get(callback)
    if (prevFrameId) {
      cancelAnimationFrame(prevFrameId)
    }
  }
}

export class FileUploadHandler extends RequestHandler {
  /**
   * @param {number | string} id
   * @param {File} file
   * @param {string} groupName
   */
  constructor(id, file, groupName) {
    super(id)
    this.file = file
    this.groupName = groupName
  }

  createUploadRequest() {
    const request = this.createRequest(context => {
      const {signal, notifyProgressUpdate} = context
      return postDocUploadRequest(
        {
          key: `${this.groupName}/${this.file.name}`,
          file: this.file,
        },
        {
          onUploadProgress: progressEvent => {
            const {loaded, total} = progressEvent
            if (!total) return
            const progress = Math.ceil((loaded / total) * 100)
            console.log(`${this.file.name}: ${progress}/100%`)
            notifyProgressUpdate(progress)
          },
          signal,
        },
      )
    })
    return request
  }
}

// CD stands for Custom Declaration
export class CDUploadHandler extends RequestHandler {
  /**
   * @param {number | string} id
   * @param {File} file
   */
  constructor(id, file) {
    super(id)
    this.file = file
    this.uploadKey = `${uuid()}_${file.name}`
  }

  createUploadRequest() {
    const request = this.createRequest(context => {
      const {signal, notifyProgressUpdate} = context
      return postDocUploadRequest(
        {
          key: this.uploadKey,
          file: this.file,
        },
        {
          onUploadProgress: progressEvent => {
            const {loaded, total} = progressEvent
            if (!total) return
            const progress = Math.ceil((loaded / total) * 100)
            notifyProgressUpdate(progress)
          },
          signal,
          s3Config: cdS3Config,
          s3Client: cds3Client,
        },
      )
    })
    return request
  }
}

export class BoLConvertHandler extends RequestHandler {
  /**
   * @param {string} groupName
   * @param {File[]} files
   * @param {string} site
   */
  constructor(groupName, files, site) {
    super(groupName) // as id
    this.groupName = groupName
    this.files = sortBy(files, ["name"])
    this.site = site
    /** @type {Set<string>} */
    this.failSet = new Set()
  }

  /**
   * @param {import('../textract').PostBoLParams} params
   */
  createConvertRequest(params) {
    this.failSet.clear()
    const request = this.createRequest(async ({signal}) => {
      const notFinishedJobs = await this._startAnalyzeDocument(this.files)
      const finishedJobs = await this._waitingDocumentAnalysis(notFinishedJobs)
      console.log("# finished jobs", finishedJobs)
      console.log("# start fetch analyzed bol data")
      const response = await postStartConvertBoL(
        {
          ...params,
          jobs: finishedJobs,
        },
        {
          signal,
        },
      )
      console.log("# bol convert result:", response.data)
      const {fail: failedFiles} = response.data
      if (isArray(failedFiles)) {
        for (let failedFileName of failedFiles) {
          this._addFailedFile(failedFileName)
        }
      }
      return response
    })
    return request
  }

  /**
   * @param {File[]} files
   */
  async _startAnalyzeDocument(files) {
    /** @type {Array<{ fileKey: string; id: string }>} */
    let jobs = []
    try {
      // trigger AWS start textract documents
      const results = await Promise.all(
        files.map(file =>
          postStartTextractDoc({
            fileName: file.name,
            folder: this.groupName,
          }).then(result => ({
            data: result,
            key: file.name,
          })),
        ),
      )
      for (let result of results) {
        if (result.data.JobId) {
          jobs.push({id: result.data.JobId, fileKey: result.key})
        }
      }
    } catch (error) {
      console.log("# error happened when StartTextractDoc")
      throw error
    }

    return jobs
  }

  /**
   * @param {{ fileKey: string; id: string }[]} jobs
   */
  async _waitingDocumentAnalysis(jobs) {
    // TODO: consider use chunk (worry connection limit)
    /** @type {Map<string,{ fileKey: string; id: string; status: JobStatus}>} */
    const finishedJobMap = new Map()

    const retryInterval = 2000
    const maxRetryTimes = 100
    let notFinishedJobs = [...jobs]
    let count = 0
    while (count < maxRetryTimes && notFinishedJobs.length > 0) {
      console.log(
        `## waiting for Textract Analysis finished, No.${count + 1} try`,
      )
      try {
        // check status of jobs
        const responses = await Promise.all(
          notFinishedJobs.map(job =>
            getTextractDocAnalysis({
              JobId: job.id,
              MaxResults: 1, // reduce the block number in response, give 0 will lead to http 400
            }).then(result => ({
              job,
              result,
            })),
          ),
        )

        for (let {result, job} of responses) {
          if (
            result.JobStatus === JobStatus.SUCCEEDED ||
            result.JobStatus === JobStatus.PARTIAL_SUCCESS ||
            result.JobStatus === JobStatus.FAILED
          ) {
            finishedJobMap.set(job.id, {...job, status: result.JobStatus})
          }

          if (result.JobStatus === JobStatus.FAILED) {
            this._addFailedFile(job.fileKey)
          }
        }

        notFinishedJobs = notFinishedJobs.filter(
          job => finishedJobMap.has(job.id) === false,
        )
      } catch (error) {
        console.log("# error happened when GetTextractDocAnalysis")
        throw error
      }
      // delay
      await new Promise(resolve => setTimeout(resolve, retryInterval))
      count++
    }

    // remain not finished job will count to failed
    notFinishedJobs.forEach(job => {
      this._addFailedFile(job.fileKey)
    })

    const finishedJobList = Array.from(finishedJobMap.values())
    finishedJobList.forEach(job => {
      if (job.status === JobStatus.FAILED) {
        this._addFailedFile(job.fileKey)
      }
    })

    return finishedJobList.map(job => ({
      id: job.id,
      fileKey: `${this.groupName}/${job.fileKey}`,
    }))
  }

  /**
   * @param {string} fileName
   */
  _addFailedFile(fileName) {
    return this.failSet.add(fileName)
  }
}
