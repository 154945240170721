import React, {useCallback} from "react"
import toast from "react-hot-toast"
import {useTranslation} from "react-i18next"
import Axios from "axios"

import {isCsvFile} from "utils/file"
import {useValidateBol} from "services/bol/validate"
import {useStartConvertBoL} from "services/bol/hook"
import {useUploadBolCsvFile} from "services/uploadFile"
import {DropFileTypeEnum, useDroppedFiles} from "lib/store/useDroppedFiles"
import {createConfirm} from "lib/store/useConfirmModal"
import {openDropFileModal} from "lib/store/useDropFileModal"
import {closeBolCsvPrecheckModal} from "lib/store/useBolCsvPrecheckModal"

const TOAST_ID = "bol-upload-status"

const useBolCsvPrecheck = () => {
  const {t} = useTranslation()

  // open dorp file zone modal and close this modal
  const retry = useCallback(() => {
    openDropFileModal()
    closeBolCsvPrecheckModal()
  }, [])

  const [site, files, uploadType] = useDroppedFiles(state => [
    state.site,
    state.files,
    state.fileType,
  ])
  const csvFiles = files.filter(isCsvFile)
  const csvFile = csvFiles[0]

  const {mutate: startConvert} = useStartConvertBoL({
    onMutate: () => {
      toast.loading(t("bolUploadStatus.loading"), {
        id: TOAST_ID,
      })
      return undefined
    },
    onError: () => {
      toast.error(t("bolUploadStatus.error"), {
        id: TOAST_ID,
      })
    },
    onSuccess: () => {
      toast.success(t("bolUploadStatus.success"), {
        id: TOAST_ID,
      })
    },
  })
  const {
    data: uploadedFileName,
    mutate: upload,
    isLoading: isUploading,
  } = useUploadBolCsvFile({
    onSuccess: fileName => {
      if (site) {
        validate({file: csvFile, fileName, site})
      } else {
        console.error(
          "onPreStartUpload_BolPrecheck:: Unintended missing `site` param",
        )
      }
    },
    onError: () => {
      retry()
      createConfirm(t("bolUploadPrecheckModal.bolUploadFailed"))
    },
  })
  const {
    data: validateResult,
    mutate: validate,
    isLoading: isValidating,
    resultHasError,
    resultHasWarning,
  } = useValidateBol({
    onError: err => {
      // open the drop file modal for user to upload again
      retry()
      if (Axios.isAxiosError(err) && err.response?.status) {
        if (err.response.status === 504) {
          createConfirm(t("timeoutMessage"))
          return
        }
      }
      createConfirm(t("bolUploadPrecheckModal.bolValidateFailed"))
    },
  })

  React.useEffect(() => {
    if (
      csvFiles.length === 1 &&
      csvFile &&
      uploadType === DropFileTypeEnum.BOL
    ) {
      upload({file: csvFile})
    }
  }, [csvFile])

  const startConvertBoL = () => {
    if (site) {
      startConvert({
        filePrefix: uploadedFileName,
        site,
        format: "csv",
      })
    } else {
      console.error(
        "onPreStartConvert_BolPrecheck:: Unintended missing `site` param",
      )
    }
  }

  return {
    isDoingRequest: isUploading || isValidating,
    resultHasError,
    resultHasWarning,
    validateResult,
    startConvertBoL,
  }
}

export default useBolCsvPrecheck
