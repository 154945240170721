import {GetObjectCommand} from "@aws-sdk/client-s3"
import {createPresignedPost} from "@aws-sdk/s3-presigned-post"
import {getSignedUrl} from "@aws-sdk/s3-request-presigner"

import {baseRequest} from "../../lib/apiClient"
import {s3Client as bolS3Client, awsConfig} from "../../lib/awsClient"

/**
 * @param {{
 *  key: string
 *  s3Config?: import('lib/awsClient').S3Config,
 *  s3Client?: import("@aws-sdk/client-s3").S3Client,
 * }} input
 */
export async function getUploadedFileURL({
  key,
  s3Config = awsConfig,
  s3Client = bolS3Client,
}) {
  return getSignedUrl(
    s3Client,
    new GetObjectCommand({
      Key: key,
      Bucket: s3Config.bucketName,
    }),
  )
}

/**
 * @param {{
 *  key: string
 *  file: File
 * }} param
 * @param {{
 *  onUploadProgress?: (event: import('axios').AxiosProgressEvent) => void
 *  signal?: AbortSignal
 *  s3Config?: import('lib/awsClient').S3Config,
 *  s3Client?: import("@aws-sdk/client-s3").S3Client,
 * }} [options]
 */
export async function postDocUploadRequest({key, file}, options) {
  const s3config = options?.s3Config ?? awsConfig
  const {url, fields} = await createPresignedPost(
    options?.s3Client ?? bolS3Client,
    {
      Bucket: s3config.bucketName,
      Key: s3config.dirName ? `${s3config.dirName}/${key}` : key,
      Conditions: [["starts-with", "$Content-Type", ""]],
    },
  )

  /** @type {Record<string,any>} */
  const data = {
    ...fields,
    "Content-Type": file.type,
    file,
  }

  const formData = new FormData()
  for (let name of Object.keys(data)) {
    formData.append(name, data[name])
  }

  return baseRequest({
    url,
    method: "POST",
    data: formData,
    signal: options?.signal,
    onUploadProgress: options?.onUploadProgress,
  })
}
