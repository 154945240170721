import React from "react"

/**
 * @typedef {object} ButtonOwnProps
 * @property {string=} target
 * @property {string=} href
 */

/**
 * @param {React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonOwnProps} props
 */
export function Button(props) {
  const {className, children, ...restProps} = props
  return React.createElement(
    restProps.href ? "a" : "button",
    {
      className: `p-1.5 rounded
        font-medium text-sm text-white text-center
        bg-primary
        hover:opacity-90 active:opacity-90
        disabled:bg-gray-3 disabled:text-grey-70
        min-w-[64px] block
        ${className ?? ""}`,
      ...restProps,
    },

    children,
  )
}

export default Button
