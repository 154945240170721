import React from "react"
import {useTranslation} from "react-i18next"
import {
  useConfirmHandler,
  useConfirmModal,
} from "../../../lib/store/useConfirmModal"
import Container from "../../core/Container"
import {Modal} from "../../core/Modal"

/**
 * @type {React.FC<{}>}
 */
const ConfirmModal = () => {
  const {t} = useTranslation()
  const [message, showModal] = useConfirmModal(state => [
    state.message,
    state.showModal,
  ])
  const {handleCancel, handleConfirm, confirmLabel, cancelLabel} =
    useConfirmHandler()
  return (
    <Modal visible={showModal}>
      <Container className="p-6 py-4 w-[420px] shadow-lg z-20">
        <p className="text-grey text-sm">{message}</p>
        <hr className="h-px w-full border-gray-3 my-4" />
        <div className="-my-2 flex flex-row items-center justify-end space-x-10">
          {handleCancel ? (
            <button
              className="p-2 text-sm rounded-lg text-primary hover:ring-2 hover:ring-primary"
              onClick={handleCancel}
            >
              {cancelLabel || t("btnCancel")}
            </button>
          ) : null}
          <button
            className="p-2 text-sm rounded-lg text-primary hover:ring-2 hover:ring-primary font-medium"
            onClick={handleConfirm}
          >
            {confirmLabel || t("btnOK")}
          </button>
        </div>
      </Container>
    </Modal>
  )
}

export default ConfirmModal
