import create from "zustand"

/** @type {import('./types').BolUploadingWindowStore} */
export const useBolUploadingWindow = create(() => ({
  showWindow: false,
  collapseWindow: true,
}))

export function toggleBolUploadingWindow() {
  useBolUploadingWindow.setState(({showWindow}) => ({
    showWindow: !showWindow,
  }))
}

export function toggleBolUploadingWindowCollapse() {
  useBolUploadingWindow.setState(({collapseWindow}) => ({
    collapseWindow: !collapseWindow,
  }))
}

export function openBolUploadingWindow() {
  useBolUploadingWindow.setState(() => ({
    showWindow: true,
    collapseWindow: false,
  }))
}

export function closeBolUploadingWindow() {
  useBolUploadingWindow.setState(() => ({
    showWindow: false,
  }))
}
