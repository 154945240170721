import React, {useCallback, useEffect, useState} from "react"
import {useQuery, useMutation} from "@tanstack/react-query"
import {noop} from "lodash-es"

import {cds3Client, cdS3Config, defaultConfig, s3Client} from "lib/awsClient"
import {uuid} from "utils/getRandomID"
import {useNamedQueue} from "../../hooks/useNamedQueue"
import {getUploadedFileURL, postDocUploadRequest} from "./request"
import {useNamedRef, useNamedRef as useRef} from "../../hooks/useNamedRef"

import {FileUploadHandler, BoLConvertHandler} from "./handler"

// eslint-disable-next-line import/no-unresolved
import {ProcessState, RequestHandler, BOLFileProcessingStateEnum} from "./types"

export const UploadFileQueueEnum = {
  UploadFiles: "UploadFiles",
  UploadedBOLFiles: "UploadedBOLFiles",
}

/**
 * @template {RequestHandler} [T=RequestHandler]
 * @param {{
 *  name: string,
 *  limit: number,
 *  initialValues?: T[],
 *  enable?: boolean
 *  onCreateRequest: (handler: T) => Promise<any>
 *  onCompleted?: () => void
 * }} params
 * @returns
 */
export function useRequestHandlers({
  name,
  limit,
  initialValues,
  enable = false,
  onCompleted = noop,
  onCreateRequest,
}) {
  const working = React.useRef(enable)
  const {add, update, state, queue, length} = useNamedQueue({
    name,
    initialValues,
    limit,
  })
  const completed = useRef(`${name}_completed`, /** @type {T[]} */ ([]))
  const failed = useRef(`${name}_failed`, /** @type {T[]} */ ([]))

  const runIdleHandlers = useCallback(
    /** @param {T[]} handlers */
    handlers => {
      const idleHandlers = handlers.filter(
        handler => handler.state === ProcessState.Idle,
      )
      console.log(
        "### [useRequestHandlers] runIdleHandlers",
        name,
        idleHandlers.length,
        idleHandlers.map(i => i.id),
        idleHandlers.map(i => i.state),
      )
      if (idleHandlers.length) {
        idleHandlers.forEach(handler => {
          const request = onCreateRequest(handler)
          request.then(() => {
            if (working.current) {
              // follow queue update,
              // in async callback this will be not batched
              if (handler.state === ProcessState.Success) {
                completed.current.push(handler)
              } else if (handler.state === ProcessState.Failed) {
                failed.current.push(handler)
              }
              // remove this handler in queue
              update(handlers =>
                handlers.filter(item => item.id !== handler.id),
              )
            }
          })
        })
      }
    },
    [],
  )

  const cleanHandlers = useCallback(() => {
    working.current = false
    update(handlers => {
      handlers.forEach(handler => handler.cancelRequest())
      return []
    })
    completed.current = []
    failed.current = []
  }, [])

  useEffect(() => {
    working.current = enable
  }, [enable])

  const prevLengthRef = React.useRef(
    /** @type {number|undefined} */ (undefined),
  )
  useEffect(() => {
    if (
      prevLengthRef.current !== undefined &&
      length === 0 &&
      prevLengthRef.current !== 0
    ) {
      if (working.current) {
        onCompleted?.()
      }
      working.current = false
    }
    prevLengthRef.current = length
  }, [length])

  useEffect(() => {
    console.log(
      "state",
      state.map(handler => handler.id),
      name,
      working.current,
    )
    console.log(
      "queue",
      queue.map(handler => handler.id),
    )

    if (working.current) {
      runIdleHandlers(state)
    }

    return () => {
      if (working.current === false) {
        state.forEach(handler => handler.cancelRequest())
      }
    }
  }, [state, queue, runIdleHandlers])

  const isCompleted =
    state.length + queue.length === 0 &&
    failed.current.length + completed.current.length > 0

  return {
    addHandlers: add,
    runHandlers: () => {
      console.log("### [useRequestHandlers] run handlers", name)
      runIdleHandlers(state)
      working.current = true
    },
    cleanHandlers: cleanHandlers,
    current: state,
    queued: queue,
    completed: completed.current,
    failed: failed.current,
    isCompleted,
  }
}

/**
 * @template {RequestHandler} [T=RequestHandler]
 * @param {{
 *  name: string,
 *  onCompleted?: () => void
 * }} params
 * @returns
 */
export function useRequestHandlersState({name}) {
  const {state, queue} = useNamedQueue({
    name,
    initialValues: /** @type {T[]} */ ([]),
    limit: 1, // not important here
  })

  const completed = useNamedRef(`${name}_completed`, /** @type {T[]} */ ([]))
  const failed = useNamedRef(`${name}_failed`, /** @type {T[]} */ ([]))

  return {
    current: state,
    queued: queue,
    completed: completed,
    failed: failed,
    isCompleted:
      state.length + queue.length === 0 &&
      failed.current.length + completed.current.length > 0,
  }
}

/**
 * @param {{
 *  limit: number,
 *  initialData?: Record<string, File[]>
 *  enable?: boolean,
 *  onUploadCompleted?: () => void
 * }} params
 * @returns
 */
export function useUploadFiles({
  limit,
  enable,
  initialData,
  onUploadCompleted,
}) {
  /**
   * @param {Record<string, File[]>} groupedFiles
   */
  const fileToUploadHandler = groupedFiles => {
    const newHandlers = []
    for (let [group, files] of Object.entries(groupedFiles)) {
      newHandlers.push(
        ...files.map(file => new FileUploadHandler(file.name, file, group)),
      )
    }
    return newHandlers
  }

  const {addHandlers, ...rest} = useRequestHandlers({
    name: UploadFileQueueEnum.UploadFiles,
    limit,
    enable,
    initialValues: initialData ? fileToUploadHandler(initialData) : [],
    onCompleted: onUploadCompleted,
    onCreateRequest: handler => handler.createUploadRequest(),
  })
  return {
    ...rest,
    /**
     * @param {Record<string, File[]>} groupedFiles
     */
    addFiles: groupedFiles => {
      addHandlers(fileToUploadHandler(groupedFiles))
    },
  }
}

/**
 * @param {{
 *  limit: number,
 *  enable?: boolean,
 *  onCreateRequest?: (handler: BoLConvertHandler) => Promise<any>
 *  onCompleted?: () => void
 * }} params
 * @returns
 */
export function useUploadedFiles({
  limit,
  enable,
  onCreateRequest,
  onCompleted,
}) {
  const {addHandlers, ...rest} = useRequestHandlers({
    name: UploadFileQueueEnum.UploadedBOLFiles,
    limit,
    enable,
    initialValues: /** @type {BoLConvertHandler[]} */ ([]),
    onCreateRequest: handler => {
      if (onCreateRequest) {
        return onCreateRequest(handler)
      }
      return handler.createConvertRequest({
        filePrefix: handler.groupName,
        site: handler.site,
        format: "bol",
      })
    },
    onCompleted,
  })
  return {
    ...rest,
    /**
     * @param {Record<string, File[]>} groupedFiles
     * @param {string} site
     */
    addBoLHandlers: (groupedFiles, site) => {
      const entries = Object.entries(groupedFiles)
      addHandlers(
        entries.map(
          ([group, files]) => new BoLConvertHandler(group, files, site),
        ),
      )
    },
  }
}

/**
 * @param {RequestHandler} handler
 * @param {{
 *  enable?: boolean
 * }} [options]
 */
export function useHandlerProgress(handler, options = {}) {
  const {enable = true} = options
  const [progress, setProgress] = useState(handler.progress)

  useEffect(() => {
    if (enable) {
      const unsubscribe = handler.subscribeUpdate("progress", newProgress =>
        setProgress(newProgress),
      )
      return () => {
        unsubscribe()
      }
    }
  }, [handler, enable])

  return progress
}

/**
 * @param {RequestHandler} handler
 */
export function useHandlerState(handler) {
  const [state, setState] = useState(handler.state)

  useEffect(() => {
    const unsubscribe = handler.subscribeUpdate("state", newState =>
      setState(newState),
    )
    return () => {
      unsubscribe()
    }
  }, [handler])

  return state
}

/**
 * @param {{
 *   bolId?: string,
 *   fileName?: string,
 * }} param
 * @param {import('@tanstack/react-query').UseQueryOptions<string, Error, string, any>} options
 * @returns
 */
export function useUploadedFileURL(param, options = {}) {
  const {fileName, bolId} = param
  const {enabled, ...queryOptions} = options

  return useQuery(
    [
      "uploadedFileURL",
      {
        fileName,
        bolId,
      },
    ],
    () =>
      getUploadedFileURL({
        key: `${bolId ?? ""}/${fileName ?? ""}`,
      }),
    {
      staleTime: Infinity,
      cacheTime: 10000,
      enabled:
        (enabled === undefined || enabled) &&
        !!bolId &&
        !!fileName &&
        fileName !== "undefined",
      ...queryOptions,
    },
  )
}

const UPLOAD_CUSTOM_DECLARATION_KEY = ["UploadCustomDeclaration"]
const UPLOAD_BOL_CSV_KEY = ["UploadBolCSV"]

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  string,
 *  Error,
 *  {file: File},
 *  undefined
 * >=} options
 */
export function useUploadCDFile(options = {}) {
  return useMutation(
    /**
     * @param {{file: File}} param
     */
    async param => {
      const fileName = `${uuid()}_${param.file.name}`
      return postDocUploadRequest(
        {
          key: fileName,
          file: param.file,
        },
        {
          s3Config: cdS3Config,
          s3Client: cds3Client,
        },
      ).then(() => fileName)
    },
    {
      mutationKey: UPLOAD_CUSTOM_DECLARATION_KEY,
      retry: 10,
      ...options,
    },
  )
}

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  string,
 *  Error,
 *  {file: File},
 *  undefined
 * >=} options
 */
export function useUploadBolCsvFile(options = {}) {
  return useMutation(
    /**
     * @param {{file: File}} param
     */
    async param => {
      const fileName = `${uuid()}_${param.file.name}`
      return postDocUploadRequest(
        {
          key: fileName,
          file: param.file,
        },
        {
          s3Config: defaultConfig,
          s3Client: s3Client,
        },
      ).then(() => fileName)
    },
    {
      mutationKey: UPLOAD_BOL_CSV_KEY,
      retry: 10,
      ...options,
    },
  )
}
