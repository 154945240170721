import Axios from "axios"

import {CustomDeclarationResult} from "domain/custom-declaration"
import {useMutation, useIsMutating} from "lib/query"
import {giantRequest} from "lib/apiClient"

const MAIN_MUTATION_KEY = ["ValidateCustomDeclaration"]

/**
 * @typedef {object} ValidateCustomsDeclarationParams
 * @property {string} fileName
 * @property {File} file
 * @property {string} site
 */

/**
 * @param {ValidateCustomsDeclarationParams} params
 * @param {AxiosRequestConfig=} options
 */
export async function validateCustomsDeclaration(
  {file, fileName, site},
  options,
) {
  const response = await giantRequest({
    ...options,
    url: "/customDeclare/validate",
    method: "Post",
    data: {
      doc: fileName,
      site,
    },
  }).catch(err => {
    if (Axios.isAxiosError(err) && err.response?.status) {
      if (err.response.status >= 400 && err.response.status < 500) {
        return err.response
      }
    }
    throw err
  })

  const content = await file.text()
  const lines = content.split(/\r?\n/)

  if (!response.data?.length || lines.length < response.data.length + 4) {
    throw new Error()
  }

  return CustomDeclarationResult.parse(response.data)
}

/**
 * @param {import('lib/query').UseMutationOptions<
 *  Awaited<ReturnType<typeof validateCustomsDeclaration>>,
 *  import('axios').AxiosError<{}>,
 *  ValidateCustomsDeclarationParams,
 *  undefined
 * >=} options
 */
export function useValidateCustomDeclaration(options) {
  return useMutation(validateCustomsDeclaration, {
    ...options,
    mutationKey: MAIN_MUTATION_KEY,
  })
}

/**
 * @description
 *   hook for checking if it is validating
 */
export function useIsValidateCustomDeclaration() {
  return !!useIsMutating({
    mutationKey: MAIN_MUTATION_KEY,
  })
}

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
