/**
 * @description please only use this when necessary, only when global state is required
 */
import {useRef, createRef} from "react"

/** @type {{[x: string]: React.MutableRefObject<*>}} */
const globalRefs = {}

/**
 * @template [T=undefined]
 * @param {string} name
 * @param {T=} initValue
 */
export function useNamedRef(name, initValue) {
  if (!globalRefs[name]) {
    globalRefs[name] = createRef()
    globalRefs[name].current = initValue
  }

  /** @type {React.MutableRefObject<React.MutableRefObject<T>>} */
  const theRef = useRef(globalRefs[name])

  return theRef.current
}
