import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
const ICSuccessful = props => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={6.4} fill="#005EB8" />
    <path
      d="M5.35 7.709a.62.62 0 0 0-.933.816l1.818 2.078a.62.62 0 0 0 .917.017l4.415-4.675a.62.62 0 0 0-.9-.85l-3.948 4.18-1.37-1.566Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.2}
    />
  </svg>
)

export default ICSuccessful
