import React from "react"
import {useTranslation} from "react-i18next"

import {useHandlerState, useHandlerProgress} from "services/uploadFile/hook"
import {ProcessState} from "services/uploadFile"

import ProcessStateIcon from "./components/ProcessStateIcon"

/**
 * @type {React.FC<{
 *  uploadHandlers: FileUploadHandler[]
 *  convertHandlers: BoLConvertHandler[]
 * }>}
 * @returns
 */
export const BoLUploadingList = ({uploadHandlers, convertHandlers}) => {
  return (
    <>
      {uploadHandlers.map(handler => (
        <UploadingFileListItem key={handler.id} handler={handler} />
      ))}
      {convertHandlers.map(handler => (
        <AnalyzingFileListItem key={handler.id} handler={handler} />
      ))}
    </>
  )
}

/** @type {React.FC<{children?: React.ReactNode}>} */
const ListItemWrapper = ({children}) => (
  <div className="py-4 flex flex-row justify-between items-center">
    {children}
  </div>
)

/**
 * @type {React.FC<{
 *  handler: FileUploadHandler
 * }>}
 */
const UploadingFileListItem = ({handler}) => {
  const state = useHandlerState(handler)
  const progress = useHandlerProgress(handler, {
    enable: state === ProcessState.Idle || state === ProcessState.Pending,
  })
  return (
    <ListItemWrapper>
      <div className="overflow-hidden text-grey text-base leading-normal">
        {handler.file.name}
      </div>
      <div>
        <ProcessStateIcon state={state} progress={progress} />
      </div>
    </ListItemWrapper>
  )
}

/**
 * @type {React.FC<{
 *  handler: BoLConvertHandler
 * }>}
 */
const AnalyzingFileListItem = ({handler}) => {
  const {t} = useTranslation()
  const state = useHandlerState(handler)
  const itemIdentifyingText = t("itemIdentifying")

  const items = handler.files.map(file => {
    const isFailed = handler.failSet.has(file.name)
    return (
      <ListItemWrapper key={file.name}>
        <div className="overflow-hidden text-grey text-base leading-normal">
          {file.name}
        </div>
        <div>
          {state === ProcessState.Pending ? (
            itemIdentifyingText
          ) : isFailed ? (
            <ProcessStateIcon state={ProcessState.Failed} />
          ) : state !== ProcessState.Idle ? (
            <ProcessStateIcon state={state} />
          ) : null}
        </div>
      </ListItemWrapper>
    )
  })

  return <>{items}</>
}

/**
 * @typedef {import('services/uploadFile').RequestHandler} RequestHandler
 * @typedef {import('services/uploadFile').FileUploadHandler} FileUploadHandler
 * @typedef {import('services/uploadFile').BoLConvertHandler} BoLConvertHandler
 */
