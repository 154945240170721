import SeaCarrierTable from "./Sea.json"
import AirCarrierTable from "./Air.json"
import ExpressCarrierTable from "./Express.json"

/**
 * @typedef {{abbr: string, carrier: string, isCustom?: boolean}} CarrierAbbrMapping
 */

/**
 * @param {CarrierAbbrMapping} c1
 * @param {CarrierAbbrMapping} c2
 */
const compareFn = (c1, c2) =>
  c1.abbr > c2.abbr ? 1 : c1.abbr < c2.abbr ? -1 : 0

export const SeaCarrierAbbrTable = SeaCarrierTable.sort(compareFn)
export const AirCarrierAbbrTable = AirCarrierTable.sort(compareFn)
export const ExpressCarrierAbbrTable = ExpressCarrierTable.sort(compareFn)
export const AllCarrierAbbrTable =
  AirCarrierAbbrTable.concat(SeaCarrierAbbrTable)

/**
 * @description get shorten carrier
 * @param {string=} carrier
 * @returns {CarrierAbbrMapping | undefined}
 */
export const getCarrier = carrier => {
  carrier = carrier?.trim() ?? ""

  const mapping = AllCarrierAbbrTable.find(
    t => t.carrier === carrier || t.abbr === carrier,
  )

  return (
    mapping ??
    (carrier
      ? {
          carrier,
          abbr: carrier,
          isCustom: true,
        }
      : undefined)
  )
}
