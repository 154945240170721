import {z} from "zod"

import SearchParamsHelper from "./SearchParamsHelper"

import {BoLUploadTypeEnum, BoL_ShippingInfo} from "./bol-meta"

const SITE_ENV = process.env.SITE_ENV || ""
const MIN_MILLISECONDS = 1 /* min */ * 60 * 1000
const DAY_MINS = 24 * 60

export const BoL_ContainerInfo = z.object({
  id: z.string(),
  bolId: z.string(),
  updateTime: z.number().optional().nullable(),
  createTime: z.number().optional().nullable(),

  eta: BoL_ShippingInfo.optional().nullable().catch(null),
  etd: BoL_ShippingInfo.optional().nullable().catch(null),
  ata: BoL_ShippingInfo.optional().nullable().catch(null),
  atd: BoL_ShippingInfo.optional().nullable().catch(null),
})
/** @typedef {z.infer<typeof BoL_ContainerInfo>} BoL_ContainerInfo */

export const normalizedContainersInfoMap = z.map(
  z.string(),
  z.object({
    id: z.string(),
    isSuspect: z.boolean(),
    isEditable: z.boolean(),
    raw: BoL_ContainerInfo.optional(),
  }),
)
/** @typedef {z.infer<typeof normalizedContainersInfoMap>} NormalizedContainersInfo */

/**
 * @description return true if the container is suspect
 * @param {BoL_ContainerInfo} containerInfo
 * @param {Date=} uploadTime, the upload time of a bol info
 */
export const isContainerSuspect = (containerInfo, uploadTime) => {
  if (containerHasData(containerInfo)) return false

  const targetTime =
    containerInfo.createTime ?? (uploadTime ? uploadTime.valueOf() : 0)
  return isOver24Hours(Date.now(), targetTime)
}

/**
 * @description return true if the container is editable
 * @param {import('./bol-meta').BoLUploadType} uploadType
 * @param {BoL_ContainerInfo} containerInfo
 */
export const isContainerEditable = (uploadType, containerInfo) => {
  return uploadType === BoLUploadTypeEnum.CD || !containerHasData(containerInfo)
}

/**
 * @param {BoL_ContainerInfo} containerInfo
 */
export const containerHasData = containerInfo =>
  !!(
    containerInfo.ata ||
    containerInfo.atd ||
    containerInfo.eta ||
    containerInfo.etd
  )

export const isOver24Hours = (now = 0, targetTime = 0) => {
  let SUSPECT_DAY_MINS = DAY_MINS

  // production won't have this env variable
  if (SITE_ENV) {
    const SUSPECT_DAY_MINS_STR = new SearchParamsHelper().get(
      "SUSPECT_DAY_MINS",
    )

    SUSPECT_DAY_MINS = SUSPECT_DAY_MINS_STR
      ? parseInt(SUSPECT_DAY_MINS_STR, 10) || DAY_MINS
      : DAY_MINS
  }

  return now - targetTime > SUSPECT_DAY_MINS * MIN_MILLISECONDS
}

/**
 * @description
 * @param {{
 *   uploadType: import("./bol-meta").BoLUploadType,
 *   uploadTime?: Date,
 * }} bill
 * @param {BoL_ContainerInfo[]=} containerInfos
 * @param {boolean=} rawData
 */
export const normalizeContainersInfo = (
  bill,
  containerInfos = [],
  rawData = false,
) => {
  /** @type {NormalizedContainersInfo} */
  const map = new Map()
  const uploadType = bill.uploadType
  containerInfos.forEach(containerInfo => {
    map.set(containerInfo.id, {
      id: containerInfo.id,
      isSuspect:
        uploadType !== BoLUploadTypeEnum.CD &&
        isContainerSuspect(containerInfo, bill.uploadTime),
      isEditable: isContainerEditable(uploadType, containerInfo),

      ...(rawData
        ? {
            raw: containerInfo,
          }
        : undefined),
    })
  })
  return map
}

export const MAX_CONTAINER_ID_SIZE = 4 + 7
export const checkContainerIdFormat = (containerId = "") => {
  const ContainerIdRegexp = /^[A-Za-z]{4}[0-9]{7}$/

  return !!containerId.match(ContainerIdRegexp)
}
