import {nth} from "lodash-es"

/**
 * @param {File} file
 * @returns
 */
export const isCsvFile = file =>
  [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ].includes(file.type)

export const getFileTypeFromString = (fileName = "") =>
  nth(fileName.toLowerCase().split("."), -1)
