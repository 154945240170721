import {z} from "zod"

export const ValidateCustomsDeclarationErrCode = Object.freeze({
  EMPTY_FIELD: 400,
  INVALID_DATE: 401,
  WRONG_SITE: 402,
})
export const ValidateCustomsDeclarationErrZEnum = z.nativeEnum(
  ValidateCustomsDeclarationErrCode,
)
/** @typedef {typeof ValidateCustomsDeclarationErrCode[keyof typeof ValidateCustomsDeclarationErrCode]} ValidateCustomsDeclarationErrCode*/

export const ValidateCustomsDeclarationWarningCode = Object.freeze({
  BOL_NOT_EXIST: 201,
  CUSTOM_EXIST: 202,
})
export const ValidateCustomsDeclarationWarningZEnum = z.nativeEnum(
  ValidateCustomsDeclarationWarningCode,
)
/** @typedef {typeof ValidateCustomsDeclarationWarningCode[keyof typeof ValidateCustomsDeclarationWarningCode]} ValidateCustomsDeclarationWarningCode*/

export const splitInvoice = (invoice = "") =>
  invoice
    .split(";")
    .map(s => s.trim())
    .filter(s => !!s)
    .sort()

// dto passed from api
export const Data_DTO = z.object({
  data: z.string().catch(""),
  warning: z.number().optional(),
  error: z.number().optional(),
})

// single cd dto passed from api
export const CustomDeclaration = z.object({
  row: z.preprocess(
    r => ({
      data: String(r),
    }),
    Data_DTO,
  ),
  bol: Data_DTO,
  custom_declaration: Data_DTO,
  invoice: Data_DTO.transform(invoiceData => ({
    ...invoiceData,
    data: splitInvoice(invoiceData.data ?? ""),
  })),
  date: Data_DTO,
})
/** @typedef {z.infer<typeof CustomDeclaration>} CustomDeclaration */
/** @typedef {keyof CustomDeclaration} CustomDeclarationKey */

export const CustomDeclarationResult = z
  .array(CustomDeclaration)
  .catch([])
  .default([])
/** @typedef {z.infer<typeof CustomDeclarationResult>} CustomDeclarationResult */

/** @type {Array<keyof Omit<CustomDeclaration, "result"> >} */
export const CustomDeclarationCSVKeyMapping = [
  "row",
  "bol",
  "custom_declaration",
  "invoice",
  "date",
]
