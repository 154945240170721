/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from "react"
import {defaultFallbackInView} from "react-intersection-observer"
import {pdfjs} from "react-pdf/dist/esm/entry.webpack5.js"

import BUILD_VERSION from "./BUILD_VERSION"
import {RootProvider, RootPageElement} from "./src/lib/root"
import {initialize, logout} from "./src/lib/oauth"

import "./src/styles/global.css"

// active global InView error fallback
defaultFallbackInView(true)

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

/**
 * @type {import('gatsby').GatsbyBrowser["wrapPageElement"]}
 */
export const wrapPageElement = ({element, props}) => {
  // https://andremonteiro.pt/gatsby-i18next-wrap-page-element/
  const rootPageElement = React.createElement(
    RootPageElement,
    {},
    element.props.children?.props?.children,
  )

  const newElement = React.cloneElement(
    element, // I18nextProvider
    element.props,
    element.props.children && element.props.children.props
      ? React.cloneElement(
          element.props.children, // I18nextContext.Provider
          element.props.children.props,
          rootPageElement,
        )
      : rootPageElement,
  )

  const isAuthPage = !!props.location.pathname.match(/^(\/[^/]+)?\/auth\/.*$/)

  return isAuthPage ? element : newElement
}

/**
 * @type {import('gatsby').GatsbyBrowser["wrapRootElement"]}
 */
export const wrapRootElement = ({element, ...props}) => {
  // call config here?
  return <RootProvider>{element}</RootProvider>
}

/**
 * @type {import('gatsby').GatsbyBrowser["onClientEntry"]}
 */
export const onClientEntry = async () => {
  console.log("We've started!")
  // print build version under console
  if (process.env.BUILD_VERSION) {
    console.log("BUILD VERSION: ", process.env.BUILD_VERSION)
  } else {
    console.log("BUILD VERSION: ", BUILD_VERSION)
  }

  // some initialization
  initialize()

  // TODO: remove on release
  window.logout = logout

  // do polyfill
  const polyfills = []
  if (typeof Uint8Array.from === "undefined") {
    polyfills.push(async () => {
      await import(`core-js/actual/typed-array/from`)
      console.log("use polyfill for typed-array/from")
    })
  }
  await Promise.all(polyfills.map(run => run())).catch(error => {
    console.log("### polyfill error", error)
  })
}
