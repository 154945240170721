import React from "react"

import Avatar from "./Avatar.layout"
import {logout} from "lib/oauth"
import {upperCase} from "lodash-es"

const AvatarMenu = React.forwardRef(
  (
    /** @type {React.HTMLAttributes<HTMLDivElement> & {email?: string, username?: string}} */
    {className, username, email, ...restProps},
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={`
        rounded border border-gray-3 bg-white 
        flex flex-col divide-y divide-gray-3
        ${className}
      `}
        {...restProps}
      >
        <div className="p-3 gap-3 flex flex-row items-center">
          <Avatar>{upperCase(username?.charAt(0))}</Avatar>
          <div>
            <div className="font-medium">{username}</div>
            <div className="font-medium text-grey-70">{email}</div>
          </div>
        </div>
        <button
          className="appearance-none px-3 py-2 text-left font-medium text-sm"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    )
  },
)

export default AvatarMenu
