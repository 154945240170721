import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const SolidCircleCheckIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M7.5022 11.5784C7.19048 11.2222 6.64897 11.1861 6.29271 11.4978C5.93645 11.8095 5.90035 12.351 6.21207 12.7073L9.21207 16.1359C9.54498 16.5163 10.1332 16.5275 10.4803 16.16L17.766 8.44568C18.091 8.10152 18.0755 7.55903 17.7314 7.23399C17.3872 6.90895 16.8447 6.92445 16.5197 7.26861L9.88135 14.2975L7.5022 11.5784Z"
      fill="#fff"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const SolidCheckIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.21607 11.8641C6.90434 11.5078 6.36283 11.4717 6.00657 11.7834C5.65031 12.0952 5.61421 12.6367 5.92594 12.9929L8.92594 16.4215C9.25885 16.802 9.84704 16.8132 10.1942 16.4456L17.4799 8.73132C17.8049 8.38717 17.7894 7.84467 17.4453 7.51964C17.1011 7.1946 16.5586 7.2101 16.2336 7.55426L9.59522 14.5831L7.21607 11.8641Z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const CheckboxNoneIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="checkbox"
    {...props}
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#767D8B" />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const CheckboxClearIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="checkbox-clear"
    {...props}
  >
    <rect width="20" height="20" rx="4" fill="#005EB8" />
    <path d="M5 10H15" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const CheckboxActivatedIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="checkbox-activated"
    {...props}
  >
    <rect y="0.00012207" width="20" height="20" rx="4" fill="#005EB8" />
    <path
      d="M5.5022 9.57855C5.19048 9.22229 4.64897 9.18619 4.29271 9.49792C3.93645 9.80964 3.90035 10.3512 4.21207 10.7074L7.21207 14.136C7.54498 14.5165 8.13317 14.5276 8.48029 14.1601L15.766 6.4458C16.091 6.10164 16.0755 5.55915 15.7314 5.23411C15.3872 4.90907 14.8447 4.92457 14.5197 5.26873L7.88135 12.2976L5.5022 9.57855Z"
      fill="white"
    />
  </svg>
)

export const CheckboxStatusEnum = Object.freeze({
  none: "none",
  all: "all",
  some: "some",
})
/**
 * @typedef {typeof CheckboxStatusEnum[keyof typeof CheckboxStatusEnum]} CheckboxStatus
 */

/**
 * @type {React.FC<{
 *   status?: CheckboxStatus
 * } & React.SVGAttributes<SVGElement>>}
 */
export const CheckboxIcon = ({status = CheckboxStatusEnum.none, ...props}) => {
  switch (status) {
    case CheckboxStatusEnum.all:
      return <CheckboxActivatedIcon {...props} />
    case CheckboxStatusEnum.some:
      return <CheckboxClearIcon {...props} />
    default:
      return <CheckboxNoneIcon {...props} />
  }
}
