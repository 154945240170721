import * as React from "react"
import {useTranslation} from "react-i18next"

import {BoLStatus, MAX_CONTAINER_ID_SIZE} from "@domain"
import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"

import TextInput from "components/shared/fields/TextInputField"
import {RemoveButtonIcon} from "components/shared/icons/btn"

import {
  useBolInitValue,
  useContainerAtoms,
  useRemoveContainerByIndex,
  useIsContainerInvalid,
  useEditedContainer,
} from "./bol-edit.hook"

export const ContainersEditor = () => {
  const editedContainersAtoms = useContainerAtoms()

  return (
    <>
      {editedContainersAtoms.map((editedContainerAtom, index) => (
        <ContainerEditor
          index={index}
          key={index}
          editedContainerAtom={editedContainerAtom}
        />
      ))}
    </>
  )
}

export default ContainersEditor

/**
 * @param {React.ButtonHTMLAttributes<HTMLButtonElement> & {
 *   index: number
 * }} props
 */
const RemoveContainerButton = props => {
  const {onClick, disabled, index, ...restProps} = props
  const isUpdating = useIsUpdatingEditableBillOfLading()
  const removeContainerByIndex = useRemoveContainerByIndex()

  return (
    <button
      {...restProps}
      disabled={isUpdating || disabled}
      onClick={() => {
        removeContainerByIndex(index)
      }}
    >
      <RemoveButtonIcon />
    </button>
  )
}

/**
 * @description single container editor (text input)
 * @param {{
 *   editedContainerAtom: typeof import('./bol-edit.atom').editedVendorAtom
 *   index: number
 * }} param0
 */
const ContainerEditor = ({index, editedContainerAtom}) => {
  const {t} = useTranslation()
  const isUpdating = useIsUpdatingEditableBillOfLading()

  const [editedContainerId, updateContainerId] =
    useEditedContainer(editedContainerAtom)
  const bol = useBolInitValue()

  const isEditedContainerInvalid = useIsContainerInvalid(index)
  const isSuspect =
    bol.containersInfo.get(editedContainerId)?.isSuspect ?? false
  const isEditable =
    bol.containersInfo.get(editedContainerId)?.isEditable ?? true
  const disabled = !isEditable || isUpdating

  const warningMsg = !isEditable
    ? undefined
    : isEditedContainerInvalid
    ? "containerFormatInvalid"
    : isSuspect
    ? `bolStatusList.${BoLStatus.Suspect}`
    : undefined

  /** @type {React.MutableRefObject<HTMLInputElement>} */
  // @ts-ignore
  const inputRef = React.useRef()
  const cursorRef = React.useRef(/** @type {undefined | number} */ (undefined))

  React.useEffect(() => {
    if (typeof cursorRef.current === "number") {
      inputRef.current?.setSelectionRange(cursorRef.current, cursorRef.current)
    }
  }, [editedContainerId])

  return (
    <div className="mb-3">
      <div className="flex flex-row justify-between items-center ">
        <TextInput
          name="input-container"
          ref={inputRef}
          disabled={disabled}
          error={!!warningMsg}
          value={editedContainerId}
          minLength={MAX_CONTAINER_ID_SIZE}
          maxLength={MAX_CONTAINER_ID_SIZE}
          onChange={e => {
            if (typeof e.target.selectionStart === "number") {
              cursorRef.current = e.target.selectionStart
            }
            updateContainerId(e.target.value.toUpperCase())
          }}
        />
        <RemoveContainerButton
          disabled={disabled}
          className="ml-3"
          index={index}
        />
      </div>
      {warningMsg ? (
        <div className="mt-1 font-medium text-[10px] pl-3 text-error">
          {t(warningMsg)}
        </div>
      ) : null}
    </div>
  )
}
