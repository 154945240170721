import React from "react"
import {useLoadingIds} from "../../../lib/store/useLoadingModal"
import Container from "../../core/Container"
import {Modal} from "../../core/Modal"

/**
 * @type {React.FC<{}>}
 */
const FullLoadingModal = () => {
  const ids = useLoadingIds()
  return (
    <>
      {ids.map((_id, index) => (
        <Modal visible backdropEvent={false} key={index}>
          <Container className="p-6 py-4 shadow-lg">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Container>
        </Modal>
      ))}
    </>
  )
}

export default FullLoadingModal
