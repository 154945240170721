import create from "zustand"

export const useCDUploadModal = create(() => ({
  showModal: false,
}))

export function openCDUploadModal() {
  useCDUploadModal.setState({
    showModal: true,
  })
}

export function closeCDUploadModal() {
  useCDUploadModal.setState({
    showModal: false,
  })
}

export function toggleCDUploadModal() {
  useCDUploadModal.setState(({showModal}) => ({
    showModal: !showModal,
  }))
}

export function useCDUploadModalVisible() {
  return useCDUploadModal().showModal
}
