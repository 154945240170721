import {useSetAtom, useAtomValue} from "jotai"

import {useQueryClient, useMutation} from "lib/query"

import {deleteBillOfLading} from "./request"
import {deletingBolsAtom} from "./atoms"
import {useBOLs, useRefreshBillOfLadingsFn} from "./bols"
import {postStartConvertBoL} from "services/textract"
import {useRefreshDistinctFn} from "./distinct"

/**
 * @typedef {import('./bols').QueryBoLsResult} GetBolsResult
 */

export function useDeletingBols() {
  return useAtomValue(deletingBolsAtom)
}

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  Awaited<ReturnType<typeof deleteBillOfLading>>,
 *  Error,
 *  Parameters<deleteBillOfLading>[0],
 *  undefined
 * >=} options
 */
export function useRemoveBol(options = {}) {
  const {onMutate, onSettled, ...restOptions} = options

  const queryClient = useQueryClient()
  const refreshBOL = useRefreshBillOfLadingsFn()
  const refreshDistinct = useRefreshDistinctFn()
  const setDeletingBols = useSetAtom(deletingBolsAtom)

  return useMutation(deleteBillOfLading, {
    ...restOptions,
    onMutate: (...args) => {
      onMutate?.(...args)

      setDeletingBols(deletingBols => ({
        ...deletingBols,
        [args[0].bolId]: true,
      }))

      // optimistically remove the data from query cache
      queryClient.setQueriesData(
        useBOLs.getKey({}),
        (/** @type {GetBolsResult | undefined}*/ data) => {
          if (!data) return
          return {
            ...data,
            list: data.list.filter(bol => bol.id !== args[0].bolId),
          }
        },
      )
      return undefined
    },
    onSettled: (...args) => {
      setDeletingBols(deletingBols => {
        const nextBols = {...deletingBols}
        delete nextBols[args[2].bolId]
        return nextBols
      })
      refreshBOL()
      refreshDistinct()
      onSettled?.(...args)
    },
  })
}

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  Awaited<ReturnType<typeof postStartConvertBoL>>,
 *  Error,
 *  Parameters<typeof postStartConvertBoL>[0],
 *  undefined
 * >=} options
 */
export const useStartConvertBoL = (options = {}) => {
  const {onSettled, ...restOptions} = options
  const refreshDistinct = useRefreshDistinctFn()
  const refreshBOL = useRefreshBillOfLadingsFn()
  return useMutation({
    ...restOptions,
    mutationFn: postStartConvertBoL,
    mutationKey: ["startConvertBoL"],
    onSettled: (...args) => {
      refreshDistinct()
      refreshBOL()
      onSettled?.(...args)
    },
  })
}
