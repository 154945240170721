import React from "react"

/**
 * @param {React.ButtonHTMLAttributes<HTMLDivElement>} divProps
 */
const Avatar = ({className, children, ...restProps}) => (
  <div
    className={`
      w-8 h-8 rounded-full
      flex justify-center items-center 
      font-semibold text-base text-primary bg-gray-3
      ${className}
    `}
    {...restProps}
  >
    {children}
  </div>
)

export default Avatar
