import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const NextPageIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.21967 7.28033C8.92678 6.98744 8.92678 6.51256 9.21967 6.21967C9.51256 5.92678 9.98744 5.92678 10.2803 6.21967L15.2803 11.2197C15.5732 11.5126 15.5732 11.9874 15.2803 12.2803L10.2803 17.2803C9.98744 17.5732 9.51256 17.5732 9.21967 17.2803C8.92678 16.9874 8.92678 16.5126 9.21967 16.2197L13.6893 11.75L9.21967 7.28033Z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const LastPageIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21967 6.21967C5.92678 6.51256 5.92678 6.98744 6.21967 7.28033L10.6893 11.75L6.21967 16.2197C5.92678 16.5126 5.92678 16.9874 6.21967 17.2803C6.51256 17.5732 6.98744 17.5732 7.28033 17.2803L12.2803 12.2803C12.5732 11.9874 12.5732 11.5126 12.2803 11.2197L7.28033 6.21967C6.98744 5.92678 6.51256 5.92678 6.21967 6.21967ZM12.2197 6.21967C11.9268 6.51256 11.9268 6.98744 12.2197 7.28033L16.6893 11.75L12.2197 16.2197C11.9268 16.5126 11.9268 16.9874 12.2197 17.2803C12.5126 17.5732 12.9874 17.5732 13.2803 17.2803L18.2803 12.2803C18.5732 11.9874 18.5732 11.5126 18.2803 11.2197L13.2803 6.21967C12.9874 5.92678 12.5126 5.92678 12.2197 6.21967Z"
      fill="currentColor"
    />
  </svg>
)
