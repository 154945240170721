import * as React from "react"
import {useTranslation} from "react-i18next"

import {BolShipTypes, BoLUploadTypeEnum} from "domain/bol-meta"
import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"

import DropdownList from "components/shared/fields/Dropdown"

import {useSelectShipType, useBolInitValue} from "./bol-edit.hook"

const shipTypeOptions = BolShipTypes.map(shipType => ({
  title: shipType,
  value: shipType,
}))

/**
 * @description the ShipType Selector
 * disabled when upload type is bol
 */
export const ShipTypeSelect = ({}) => {
  const {t} = useTranslation()
  const isUpdating = useIsUpdatingEditableBillOfLading()

  const selectShipType = useSelectShipType()
  const bol = useBolInitValue()
  const disabled = bol.uploadType !== BoLUploadTypeEnum.CD || isUpdating
  const defaultOption = shipTypeOptions.find(
    option => option.value === bol.shippingType,
  )

  return (
    <DropdownList
      placeholder={t("bolEditShipTypeLabel")}
      name="shipType"
      disabled={disabled}
      containerClassName={disabled ? "pointer-events-none" : ""}
      options={shipTypeOptions}
      defaultOption={defaultOption}
      onSelectOption={selectShipType}
    />
  )
}

export default ShipTypeSelect
