// TODO: function buildDateFormatter

const formatRegexStringList = ["M+", "d+", "h+", "m+", "s+", "q+", "S"]
const formatRegexMap = formatRegexStringList.reduce((acc, regexStr) => {
  acc[regexStr] = new RegExp(`(${regexStr})`)
  return acc
}, /** @type {Record<string,RegExp>} */ ({}))

const yearRegex = new RegExp("(y+)")

/**
 * @type {Record<string,(date: Date) => number>}
 */
const regexCallbackMap = {
  "M+": date => date.getMonth() + 1, // zero-base
  "d+": date => date.getDate(),
  "h+": date => date.getHours(),
  "m+": date => date.getMinutes(),
  "s+": date => date.getSeconds(),
  "q+": date => Math.floor((date.getMonth() + 3) / 3),
  S: date => date.getMilliseconds(),
}

/**
 * @type {Record<string,(date: Date) => number>}
 */
const regexUTCCallbackMap = {
  "M+": date => date.getUTCMonth() + 1, // zero-base
  "d+": date => date.getUTCDate(),
  "h+": date => date.getUTCHours(),
  "m+": date => date.getUTCMinutes(),
  "s+": date => date.getUTCSeconds(),
  "q+": date => Math.floor((date.getUTCMonth() + 3) / 3),
  S: date => date.getUTCMilliseconds(),
}

/**
 * @example formatLocalDate(date,'yyyy-MM-dd hh:mm:ss.S') === "2022-07-27 06:33:40.473"
 * @param {Date} date
 * @param {string} format
 */
export function formatLocalDate(date, format) {
  let str = format
  const yearResult = yearRegex.exec(str)
  if (yearResult) {
    const [matched] = yearResult
    str = str.replace(
      matched,
      String(date.getFullYear()).substring(4 - matched.length),
    )
  }
  for (let regexStr of formatRegexStringList) {
    const regex = formatRegexMap[regexStr]
    const result = regex.exec(str)
    if (result) {
      const [matched] = result
      const timeStr = String(regexCallbackMap[regexStr](date))
      str = str.replace(
        matched,
        matched.length === 1
          ? timeStr
          : `00${timeStr}`.substring(timeStr.length),
      )
    }
  }
  return str
}

/**
 * @example
 * now = "Wed Jul 27 2022 15:00:53 GMT+0800"
 * formatUTCDate(date,'yyyy-MM-dd hh:mm:ss.S') === "2022-07-27 07:00:53.592"
 * @param {Date} date
 * @param {string} format
 */
export function formatUTCDate(date, format) {
  let str = format
  const yearResult = yearRegex.exec(str)
  if (yearResult) {
    const [matched] = yearResult
    str = str.replace(
      matched,
      String(date.getUTCFullYear()).substring(4 - matched.length),
    )
  }
  for (let regexStr of formatRegexStringList) {
    const regex = formatRegexMap[regexStr]
    const result = regex.exec(str)
    if (result) {
      const [matched] = result
      const timeStr = String(regexUTCCallbackMap[regexStr](date))
      str = str.replace(
        matched,
        matched.length === 1
          ? timeStr
          : `00${timeStr}`.substring(timeStr.length),
      )
    }
  }
  return str
}

/**
 *  give Date(timestamp 0) for date sorting in table
 *  @param {string | number | null} [v]
 */
export const dateOrEarliest = v =>
  typeof v === "number" || typeof v === "string" ? new Date(v) : new Date(0)

/**
 * @param { Date} cdDate
 * @param {string=} format
 */
export const formatCDDate = (cdDate, format = "yyyy/MM/dd") => {
  if (!(cdDate instanceof Date)) cdDate = dateOrEarliest(cdDate)
  if (!cdDate || +cdDate < 100 || Number.isNaN(+cdDate)) return ""
  return formatUTCDate(cdDate, format)
}

// /**
//  *
//  * @param {Date | null | undefined} date
//  * @param {{
//  *  format: string
//  *  regexStringList?: string[]
//  *  regexCallbackMap?: Record<string,(date: Date) => number>
//  * }} options
//  */
// function baseFormatDate(
//   date,
//   {
//     format,
//     regexStringList = formatRegexStringList,
//     regexCallbackMap = regexCallbackMap,
//   },
// ) {
//   if (isNil(date)) return ""
//   let str = format
//   const yearResult = yearRegex.exec(str)
//   if (yearResult) {
//     const [matched] = yearResult
//     str = str.replace(
//       matched,
//       String(date.getFullYear()).substring(4 - matched.length),
//     )
//   }
//   for (let regexStr of formatRegexStringList) {
//     const regex = formatRegexMap[regexStr]
//     const result = regex.exec(str)
//     if (result) {
//       const [matched] = result
//       const timeStr = String(regexCallbackMap[regexStr](date))
//       str = str.replace(
//         matched,
//         matched.length === 1
//           ? timeStr
//           : `00${timeStr}`.substring(timeStr.length),
//       )
//     }
//   }
//   return str
// }
