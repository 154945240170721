import create from "zustand"
import {omit} from "lodash-es"

const GLOBAL_LOADING = "global_loading"

/**
 * @type {import('./types').LoadingModalStore}
 */
export const useLoadingModal = create((set, get) => ({
  modalVisible: {},
  openModal: (id = GLOBAL_LOADING) => {
    set(({modalVisible: prevModalVisible}) => {
      return {
        modalVisible: prevModalVisible[id]
          ? prevModalVisible
          : {
              ...prevModalVisible,
              [id]: true,
            },
      }
    })
  },
  closeModal: (id = GLOBAL_LOADING) => {
    set(({modalVisible: prevModalVisible}) => {
      return {
        modalVisible: !prevModalVisible[id]
          ? prevModalVisible
          : omit(prevModalVisible, id),
      }
    })
  },
  toggleModal: (id = GLOBAL_LOADING) => {
    set(({modalVisible: prevModalVisible}) => {
      return {
        modalVisible: !prevModalVisible[id]
          ? {
              ...prevModalVisible,
              [id]: true,
            }
          : omit(prevModalVisible, id),
      }
    })
  },
}))

/**
 * @param {import('./types').LoadingModalRef=} id
 */
export function useIsLoading(id = GLOBAL_LOADING) {
  return useLoadingModal(state => !!state.modalVisible[id])
}

export function useLoadingIds() {
  return useLoadingModal(state =>
    Object.entries(state.modalVisible)
      .filter(([id, visible]) => visible)
      .map(([id]) => id),
  )
}

/**
 * @param {import('./types').LoadingModalRef=} id
 */
export function openLoadingModal(id = GLOBAL_LOADING) {
  return useLoadingModal.getState().openModal(id)
}

/**
 * @param {import('./types').LoadingModalRef=} id
 */
export function closeLoadingModal(id = GLOBAL_LOADING) {
  return useLoadingModal.getState().closeModal(id)
}
