import * as React from "react"
import {useTranslation} from "react-i18next"

import Container from "components/core/Container"
import {Modal} from "components/core/Modal"
import {TextButton} from "components/shared/buttons"
import ICError from "components/shared/icons/error"
import {cdResultHasError, cdResultHasWarning} from "services/custom-declaration"

import {CDUploadResultTable} from "./CDUploadResultTable"
import CloseCDUploadModalButton from "./CloseCDUploadModalButton"

/**
 * @type {React.FC<{
 *  visible: boolean
 *  onRequestClose?: () => void
 *  onRetry?: () => void
 *  onSubmit?: () => void
 *  cdResult?: import('domain/custom-declaration').CustomDeclaration[],
 *  isSettled?: boolean
 * }>}
 */
export const CustomUploadResultModalLayout = ({
  visible,
  cdResult,
  isSettled = false,
  onRequestClose,
  onRetry,
  onSubmit,
}) => {
  const {t} = useTranslation()
  const resultHasError = cdResult ? cdResultHasError(cdResult) : false
  const resultHasWarning = cdResult ? cdResultHasWarning(cdResult) : false

  return (
    <Modal visible={visible}>
      <Container className="p-6 pt-16 xl:px-24 shadow-lg">
        <CloseCDUploadModalButton onRequestClose={onRequestClose} />

        <h1 className="text-grey font-medium text-xl mb-6">
          {t("cdUploadingResultModal.title", {
            context: isSettled ? undefined : "loading",
          })}
        </h1>

        <CDUploadResultTable
          cdResult={cdResult}
          className="mb-6"
          isLoading={!isSettled}
        />

        <div className="flex flex-row justify-between">
          {isSettled && resultHasError ? (
            <div className="flex flex-row items-center">
              <ICError className="mr-1" />
              <span className="text-grey text-sm">
                {t("cdUploadingResultModal.warning")}
              </span>
            </div>
          ) : (
            <div />
          )}

          {isSettled ? (
            <div className="">
              {resultHasError || resultHasWarning ? (
                <TextButton onClick={onRetry}>
                  {t("cdUploadingResultModal.try")}
                </TextButton>
              ) : null}
              {resultHasError ? null : (
                <TextButton onClick={onSubmit}>
                  {t("cdUploadingResultModal.upload")}
                </TextButton>
              )}
            </div>
          ) : null}
        </div>
      </Container>
    </Modal>
  )
}

const mockData = [
  {
    bol: {
      data: "ONEYTPEB9879606",
      warning: 201,
    },
    custom_declaration: {
      data: "",
      error: 400,
    },
    invoice: {
      data: ["bksdf123"],
    },
    date: {
      data: "30.10.2021",
    },
  },
  {
    bol: {
      data: "",
      error: 400,
    },
    custom_declaration: {
      data: "IM-D1360812596",
    },
    invoice: {
      data: [],
      error: 400,
    },
    date: {
      data: "30.10.2021",
    },
  },
  {
    bol: {
      data: "ONEYSINC30756500",
      warning: 202,
    },
    custom_declaration: {
      data: "IM-D1360812596",
    },
    invoice: {
      data: ["bksdf123"],
    },
    date: {
      data: "30.10.2021",
    },
  },
]
