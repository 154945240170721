import create from "zustand"

/**
 * @type {import('./types').useEditBoLModal}
 */
export const useEditBoLModal = create(set => ({
  showModal: false,
  openModal: () => set({showModal: true}),
  closeModal: () => set({showModal: false}),
  toggleModal: () => set(state => ({showModal: !state.showModal})),

  bolRowData: null,
  setBoLRowData: row => set({bolRowData: row, fileIndex: 0}),

  fileIndex: 0,
  setFileIndex: index => set({fileIndex: index}),
}))

export function closeEditBoLModal() {
  useEditBoLModal.getState().closeModal()
}

// TODO: this is only a temporary fix,
// a better approach would be use data in the modal itself
/**
 * @param {number} status
 */
export function updateBolRowDataStatus(status) {
  // @ts-ignore
  useEditBoLModal.setState(({bolRowData}) => {
    return {
      bolRowData: bolRowData
        ? {
            ...bolRowData,
            status,
          }
        : null,
    }
  })
}
