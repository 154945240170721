export function getRandomIDfromMath() {
  return Math.random().toFixed(10).substring(2)
}

export function uuid() {
  var d = Date.now()
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now() //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return r.toString(16)
  })
}
