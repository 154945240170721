import React from "react"

// TODO: add debounce

export const baseTextInputClassName = [
  "border rounded h-8 py-0 px-3 w-full font-medium text-sm leading-normal appearance-none focus:outline-none",
  "border-gray-3 text-black",
  // hover
  "enabled:hover:border-primary",
  // focus
  "focus:border-primary focus:text-black",
].join(" ")

/**
 * @typedef {object} TextInputProps
 * @property {boolean|null=} error
 */

/**
 * @type {React.ForwardRefRenderFunction<
 *  HTMLInputElement,
 *  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,HTMLInputElement> & TextInputProps
 * >}
 */
const TextInputForwardRender = ({className, error, ...props}, ref) => {
  return (
    <input
      className={[
        baseTextInputClassName,
        // active
        !!props.value ? "text-black" : undefined,
        error ? "!border-error" : "",
        "disabled:text-grey-70",
        className,
      ].join(" ")}
      {...props}
      ref={ref}
    />
  )
}

export default React.forwardRef(TextInputForwardRender)
