import {useEffect, useCallback, useRef} from "react"
import {useTranslation} from "react-i18next"
import Axios from "axios"

import usePreventDirectUnloadPage from "hooks/usePreventDirectUnloadPage"
import {useDroppedFiles, useListenDroppedFiles} from "lib/store/useDroppedFiles"
import {closeBolUploadingWindow} from "lib/store/useBolUploadingWindow"
import {openDropFileModal, closeDropFileModal} from "lib/store/useDropFileModal"
import {closeAllConfirm, createConfirm} from "lib/store/useConfirmModal"
import {
  openCDUploadModal,
  closeCDUploadModal,
  useCDUploadModalVisible,
} from "lib/store/useCDUploadModal"
import toast from "lib/toast"

import {useUploadCDFile} from "services/uploadFile"
import {useRefreshBillOfLadingsFn} from "services/bol"
import {
  useValidateCustomDeclaration,
  useUpdateCustomDeclaration,
} from "services/custom-declaration"

const __DEV__ = process.env.NODE_ENV === "development"

const TOAST_ID = "cd-update"

/**
 * @param {{
 *  enable?: boolean
 * }} [options]
 * @returns
 */
export function useProcessCustomUpload(options = {}) {
  const {enable} = options
  const {t} = useTranslation()
  const site = useDroppedFiles(s => s.site)
  const modalVisible = useCDUploadModalVisible()
  const refreshTableData = useRefreshBillOfLadingsFn()
  const updateArgRef = useRef(
    /** @type {import("services/custom-declaration/update").UpdateCustomsDeclarationParams | undefined} */ (
      undefined
    ),
  )

  const {mutate: update, isLoading: isUpdating} = useUpdateCustomDeclaration({
    onMutate: () => {
      toast.loading(t("cdUploadStatus.loading"), {
        id: TOAST_ID,
      })
      return undefined
    },
    onError: () => {
      toast.error(t("cdUploadStatus.error"), {
        id: TOAST_ID,
      })
    },
    onSuccess: result => {
      refreshTableData()
      toast.success(t("cdUploadStatus.success"), {
        id: TOAST_ID,
      })
    },
  })

  const submit = useCallback(() => {
    updateArgRef.current?.fileName && update(updateArgRef.current)
    closeCDUploadModal()
  }, [])

  // open dorp file zone modal and close this modal
  const retry = useCallback(() => {
    openDropFileModal()
    closeCDUploadModal()
  }, [])

  const {
    isLoading: isValidating,
    mutate: validate,
    data: customUpdateResult,
    reset: resetValidate,
    isSuccess: isValidated,
  } = useValidateCustomDeclaration({
    onError: err => {
      // open the drop file modal for user to upload again
      retry()
      if (Axios.isAxiosError(err) && err.response?.status) {
        if (err.response.status === 504) {
          createConfirm(t("timeoutMessage"))
          return
        }
      }
      createConfirm(t("cdValidateFailed"))
    },
    onSuccess: (_result, args) => {
      closeAllConfirm()
      updateArgRef.current = args
    },
  })

  const {
    isLoading: isUploading,
    mutate: upload,
    reset: resetUpload,
  } = useUploadCDFile({
    onSuccess: (fileName, args) => {
      // validate this uploaded csv file
      site &&
        validate({
          fileName,
          file: args.file,
          site,
        })
    },
    onMutate: async args => {
      closeDropFileModal()
      return undefined
    },
    onError: () => {
      retry()
      createConfirm(t("cdUpdateFailed"))
    },
  })

  // !!!Start from this, when some files are dropped
  useListenDroppedFiles({
    enable,
    onUpdate: async newFiles => {
      const {resetFiles} = useDroppedFiles.getState()

      if (site) {
        closeBolUploadingWindow()
        openCDUploadModal()

        // create new handler for the file, we only need to take the first file
        // because the ui is blocked user to only upload 1 file for Custom declaration
        const file = newFiles[0]

        upload({
          file,
        })
        resetFiles()
      } else {
        console.error("onPreStartUpload:: Unintended missing `site` param")
      }
    },
  })

  // clean up result
  useEffect(() => {
    return () => {
      resetUpload()
      resetValidate()
    }
  }, [enable, modalVisible])

  usePreventDirectUnloadPage({
    enable: enable && (isUploading || isValidating || isUpdating),
  })

  __DEV__ &&
    console.log(
      "%cTest Custom Uploader",
      "background: blue; color: yellow",
      isUploading,
      isValidating,
      isValidated,
      customUpdateResult,
    )

  return {
    cdItems: customUpdateResult,
    retry,
    isUploading,
    isValidating,
    isSettled: isValidated,
    submit,
  }
}
