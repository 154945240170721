import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const ForwardIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.21967 7.28033C8.92678 6.98744 8.92678 6.51256 9.21967 6.21967C9.51256 5.92678 9.98744 5.92678 10.2803 6.21967L15.2803 11.2197C15.5732 11.5126 15.5732 11.9874 15.2803 12.2803L10.2803 17.2803C9.98744 17.5732 9.51256 17.5732 9.21967 17.2803C8.92678 16.9874 8.92678 16.5126 9.21967 16.2197L13.6893 11.75L9.21967 7.28033Z"
      fill="currentColor"
    />
  </svg>
)

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const CircleForwardIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 1.84616C17.6078 1.84616 22.1538 6.39219 22.1538 12C22.1538 17.6078 17.6078 22.1538 12 22.1538C6.39219 22.1538 1.84616 17.6078 1.84616 12C1.84616 6.39219 6.39219 1.84616 12 1.84616ZM7.28238 13.4233C6.92397 13.7858 6.92728 14.3703 7.28979 14.7287C7.65232 15.0871 8.23679 15.0838 8.59519 14.7212L12.1982 11.0764L15.8915 14.7349C16.2537 15.0937 16.8382 15.0909 17.1969 14.7286C17.5555 14.3664 17.5527 13.782 17.1904 13.4234L12.6817 8.95882C12.4068 8.68667 11.9633 8.68902 11.6914 8.96407L7.28238 13.4233Z"
      fill="currentColor"
    />
  </svg>
)
