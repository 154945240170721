import {CustomDeclarationCSVKeyMapping} from "domain/custom-declaration"

/**
 * @param {import('domain/custom-declaration').CustomDeclarationResult} cdResult
 */
export function cdResultHasError(cdResult) {
  return cdResult.some(cd =>
    CustomDeclarationCSVKeyMapping.some(key => !!cd[key].error),
  )
}

/**
 * @param {import('domain/custom-declaration').CustomDeclarationResult} cdResult
 */
export function cdResultHasWarning(cdResult) {
  return cdResult.some(cd =>
    CustomDeclarationCSVKeyMapping.some(key => !!cd[key].warning),
  )
}
