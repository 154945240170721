import React from "react"

import {DropFileTypeEnum, useDroppedFiles} from "lib/store/useDroppedFiles"
import {
  closeCDUploadModal,
  useCDUploadModalVisible,
} from "lib/store/useCDUploadModal"

import {useProcessCustomUpload} from "./CustomUploading.hook"
import {CustomUploadResultModalLayout} from "./CDUploadModal.layout"

/**
 * @type {React.FC<{}>}
 */
const CDUploadModalContainer = () => {
  const fileType = useDroppedFiles(state => state.fileType)
  const {cdItems, isSettled, retry, submit} = useProcessCustomUpload({
    enable: fileType === DropFileTypeEnum.Customs,
  })

  const visible = useCDUploadModalVisible()

  return (
    <CustomUploadResultModalLayout
      visible={visible}
      cdResult={cdItems}
      onRequestClose={closeCDUploadModal}
      isSettled={isSettled}
      onRetry={retry}
      onSubmit={submit}
    />
  )
}

export default CDUploadModalContainer
