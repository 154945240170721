import React from "react"

// ! for prototype, can replace with a better one
// i.g. https://github.com/react-dnd/react-dnd/
/**
 * @template {Element} T
 * @param {(event: React.DragEvent<T>) => void} callback
 * @param {{
 *  enable?: boolean
 * }} [options]
 * @returns {Pick<React.DOMAttributes<T>,'onDrop' | 'onDragEnter' | 'onDragOver'>}
 */
export function useDrop(callback, options = {}) {
  const {enable} = options
  const cbRef = React.useRef(callback)
  React.useEffect(() => {
    cbRef.current = callback
  })
  const preventAndStop = React.useCallback(
    (/** @type {React.DragEvent<T>} */ e) => {
      e.preventDefault()
      e.stopPropagation()
    },
    [],
  )
  const handleDrag = React.useCallback(
    (/** @type {React.DragEvent<T>} */ e) => {
      console.log("ondrag", e)
      e.preventDefault()
      e.stopPropagation()
      if (enable) {
        cbRef.current(e)
      }
    },
    [enable],
  )
  return {
    onDrop: handleDrag,
    onDragEnter: preventAndStop,
    onDragOver: preventAndStop,
  }
}
