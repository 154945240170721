import React from "react"
import shallow from "zustand/shallow"

import {useEditBoLModal} from "lib/store/useEditBoLModal"

import Container from "components/core/Container"
import {Modal} from "components/core/Modal"

import BoLStatusEditPanel from "./BoLStatusEditPanel"
import UploadedFilesViewer from "./UploadedFilesViewer"
import CloseEditModalButton from "./CloseBoLEditModalButton"
import RemoveBoLButton from "./RemoveBoLButton"
import {BolEditProvider} from "./bol-edit.atom"

const BoLEditModal = () => {
  const [showModal, bolRowData] = useEditBoLModal(
    state => [state.showModal, state.bolRowData],
    shallow,
  )

  return (
    <Modal visible={showModal}>
      <Container className="!max-w-none shadow-lg pb-9">
        {/* so the state of BolEditProvider can be reset */}
        {showModal && bolRowData ? (
          <BolEditProvider bol={bolRowData}>
            <nav className="p-4 flex justify-end items-center space-x-5">
              {bolRowData.deletable ? <RemoveBoLButton /> : null}
              <CloseEditModalButton />
            </nav>
            <main className="relative flex flex-row h-[65vh]">
              <UploadedFilesViewer />
              <BoLStatusEditPanel data={bolRowData} className="min-w-[460px]" />
            </main>
          </BolEditProvider>
        ) : null}
      </Container>
    </Modal>
  )
}

export default BoLEditModal
