import {flatMap, range, take} from "lodash-es"
import format from "date-fns/format"
import {stringify} from "csv-stringify/browser/esm/sync"

import {useMutation} from "lib/query"
import {download} from "lib/download"
import {formatUTCDate, formatCDDate} from "domain/date-format"
import {getPortOrAirport, getVoyageOrFlightNo, getVessel} from "domain/bol-meta"
import common from "../../locales/en-US/common.json"

import {getBOLs, searchBOLs} from "./bols"

/**
 * @typedef {import('domain/bol').BillOfLading} BillOfLading
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 * @typedef {Omit<import('./bols').QueryBoLsAPIParams, "page" | "pageSize">} DownloadBolParams
 */

/**
 * @param {Date|undefined} date
 * @param {string=} format
 */
const getDateStringOrEmpty = (date, format = "yyyy/MM/dd") => {
  if (!date || +date < 100 || Number.isNaN(+date)) return ""
  return formatUTCDate(date, format)
}

const createBOLCSVProcessor = () => {
  const bolContainerSet = new Set()

  return (/** @type {BillOfLading[]} */ bols = []) => {
    const flattenBolsByContainerId = bols.map(bol => {
      let containerIds = bol.containerSN ?? [""]
      if (!containerIds.length) containerIds = [""]

      return containerIds
        .filter(containerId => {
          const key = `${bol.id}-${containerId}`

          // duplicate bol id and container id, skip
          if (bolContainerSet.has(key)) return false

          bolContainerSet.add(key)
          return true
        })
        .map(containerId => {
          const status = bol.status
          const departure = bol.atdRaw ?? bol.etdRaw
          const arrival = bol.ataRaw ?? bol.etaRaw

          return [
            bol.site ?? "",
            bol.id,
            bol.vendor ?? "",
            bol.shippingType ?? "",
            bol.carrier?.abbr ?? "",
            containerId,
            common.bolStatusList[status] ?? common.bolStatusList[0],
            getDateStringOrEmpty(bol.ATD),
            getDateStringOrEmpty(bol.ETD),
            getDateStringOrEmpty(bol.ATA),
            getDateStringOrEmpty(bol.ETA),
            getDateStringOrEmpty(bol.uploadTime, "yyyy/MM/dd, hh:mm:ss"),
            getDateStringOrEmpty(bol.updateTime, "yyyy/MM/dd, hh:mm:ss"),
            getPortOrAirport(departure),
            getVessel(departure, bol.shippingType),
            getVoyageOrFlightNo(bol.atdRaw, bol.shippingType),
            getPortOrAirport(arrival),
            getVessel(arrival, bol.shippingType),
            getVoyageOrFlightNo(bol.ataRaw, bol.shippingType),
            bol.importDeclaration ?? "",
            formatCDDate(bol.customsClearanceDate),
            bol.invoices.join(";"),
          ]
        })
    })

    return stringify(flatMap(flattenBolsByContainerId), {
      quoted_empty: true,
      quoted: true,
      cast: {
        string: function (value) {
          return value
          // // @ts-ignore
          // if (!value || isNaN(value)) {
          //   return value
          // }
          // return `="${value}"`
        },
      },
    })
  }
}

const CSVHeader =
  "Site,BOL,Vendor,Ship Type,Carrier,Container,Status,ATD,ETD,ATA,ETA,BOL Upload,Status last update,Departure Port/Airport,Departure Vessel,Departure Voyage/Flight No.,Arrival Port/Airport,Arrival Vessel,Arrival Voyage/Flight No.,Customs Declaration,Clearance Date,Vendor Invoice\n"

/**
 * @param {Omit<import('./bols').QueryBoLsAPIParams, "page" | "pageSize">} params
 * @param {AxiosRequestConfig} options
 */
export async function downloadBillOfLadings(params, options = {}) {
  const {signal} = options
  const search = !!params.searchPhrase

  let restPages = [2] // the 2 is just for type inference, later will overwrite the value with real response data

  const processForCSV = createBOLCSVProcessor()

  // fetch first time to get total pages
  const fetchData = ({page = 1, pageSize = 25}) => {
    return search
      ? searchBOLs(params)
      : getBOLs(
          {
            ...params,
            page,
            pageSize,
          },
          {signal},
        )
  }
  const response = await fetchData({page: 1})
  let csvData = processForCSV(response.list)

  restPages = response.totalPages > 1 ? range(2, response.totalPages + 1) : []

  // continue to fetch rest of the pages
  while (restPages.length) {
    // fetch FETCH_NUM of pages at the same time
    const FETCH_NUM = 3

    const responses = await Promise.all(
      take(restPages, FETCH_NUM).map(page =>
        fetchData({
          page,
        }),
      ),
    )

    responses.forEach(r => (csvData = csvData + processForCSV(r.list)))
    restPages.splice(0, FETCH_NUM)
  }

  download({
    filename: `Import_Status_${format(new Date(), "yyyyMMdd")}.csv`,
    data: [CSVHeader, csvData],
    type: "text/csv;charset=utf8",
  })
}

const DOWNLOAD_BOLS_CSV = ["DOWNLOAD_BOLS_CSV"]

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *  Awaited<ReturnType<typeof downloadBillOfLadings>>,
 *  Error,
 *  DownloadBolParams,
 *  undefined
 * >=} options
 */
export function useDownloadBillOfLadings(options) {
  return useMutation(downloadBillOfLadings, {
    mutationKey: DOWNLOAD_BOLS_CSV,
    ...options,
  })
}
