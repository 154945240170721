import React from "react"

import Container from "components/core/Container"
import {Portal} from "components/core/Portal"
import {ForwardIcon} from "components/shared/icons"

/**
 * @type {React.FC<React.PropsWithChildren<{
 *  collapse: boolean
 *  onToggleCollapse: () => void
 *  previewMessage?: string
 *  mdFixedWith?: boolean
 * }>>}
 * @returns
 */
export const FileUploadingWindow = ({
  collapse,
  onToggleCollapse,
  previewMessage,
  children,
  mdFixedWith = true,
}) => {
  return (
    <Portal>
      <div className="fixed z-20 bottom-0 right-0 md:right-[5vw]">
        <Container
          className={`${
            mdFixedWith ? "md:w-[490px]" : "md:min-w-[490px]"
          } shadow-lg`}
        >
          {/* preview */}
          <div className="px-6">
            <button
              style={{borderBottomWidth: collapse ? 0 : 1}}
              className="text-left w-full border-grey-30 py-3 flex flex-row justify-between"
              onClick={onToggleCollapse}
            >
              <span>{previewMessage}</span>
              <ForwardIcon
                className={
                  "text-deepBlue-30 " + (collapse ? "-rotate-180" : "")
                }
              />
            </button>
          </div>
          {/* detail */}
          <div
            style={{height: collapse ? 0 : 210}}
            className="px-6 overflow-y-auto"
          >
            {children}
          </div>
        </Container>
      </div>
    </Portal>
  )
}
