import {isBrowser} from "domain/browser"

/**
 * @param {{
 *   filename: string,
 *   data: string[],
 *   type?: string,
 * }} param
 */
export function download({filename, data, type = "text/csv;charset=utf8"}) {
  if (!isBrowser) return

  const BOM = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([BOM, ...data], {type})

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)
  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a")
  // Passing the blob downloading url
  a.setAttribute("href", url)
  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", filename)
  // Performing a download with click
  a.click()

  setTimeout(() => {
    a.remove()
    window.URL.revokeObjectURL(url)
  }, 30000)
}
