import {atom, useAtom, useAtomValue, useSetAtom} from "jotai"
import {useTranslation} from "react-i18next"

import toast from "lib/toast"

import {
  useUpdateEditableBillOfLading,
  useIsUpdatingEditableBillOfLading,
} from "services/bol-edit"

import * as atoms from "./bol-edit.atom"
import {useAtomStore} from "./bol-edit.atom"

const BOL_SAVE_TOAST_ID = "bol-save-toast"

export const useBolInitValue = () => {
  const store = useAtomStore()
  return useAtomValue(atoms.bolInitValueAtom, {store})
}

export const useEditedShipType = () => {
  const store = useAtomStore()
  return useAtomValue(atoms.editedShipTypeAtom, {store})
}

export const useSelectShipType = () => {
  const store = useAtomStore()
  return useSetAtom(atoms.selectShipTypeOptionAtom, {store})
}

export const useUpdateVendor = () => {
  const store = useAtomStore()
  return useSetAtom(atoms.editedVendorAtom, {store})
}

export const useEditedCarrier = () => {
  const store = useAtomStore()
  return useAtom(atoms.editedCarrierAtom, {store})
}

export const useUpdateContainersAtoms = () => {
  const store = useAtomStore()
  return useSetAtom(atoms.editedContainersAtomsInAtom, {store})
}

export const useAddContainer = () => {
  const updateEditedContainersAtoms = useUpdateContainersAtoms()

  return (container = "") => {
    updateEditedContainersAtoms(curAtoms => [...curAtoms, atom(container)])
  }
}

export const useRemoveContainerByIndex = () => {
  const updateEditedContainersAtoms = useUpdateContainersAtoms()

  return (index = 0) => {
    updateEditedContainersAtoms(curAtoms =>
      // remove the "index" atom
      curAtoms.slice(0, index).concat(curAtoms.slice(index + 1)),
    )
  }
}

export const useContainerAtoms = () => {
  const store = useAtomStore()
  return useAtomValue(atoms.editedContainersAtomsInAtom, {store})
}

/**
 * @description single container atom
 * @param {typeof atoms.editedVendorAtom} editedContainerAtom
 */
export const useEditedContainer = editedContainerAtom => {
  const store = useAtomStore()
  return useAtom(editedContainerAtom, {store})
}

export const useIsContainerInvalid = (index = 0) => {
  const store = useAtomStore()
  const isEditedContainersInvalidAtoms = useAtomValue(
    atoms.isEditedContainersInvalidAtomsInAtom,
    {store},
  )
  return useAtomValue(isEditedContainersInvalidAtoms[index], {store})
}

export const useIsEdited = () => {
  const store = useAtomStore()
  return useAtomValue(atoms.isEditedAtom, {store})
}

export const useIsEditInvalid = () => {
  const store = useAtomStore()
  return useAtomValue(atoms.isInvalidAtom, {store})
}

/**
 * @param {{
 *   onSuccess?: ()=>void
 * }} [options]
 * @returns
 */
export const useSaveEditedBol = (options = {}) => {
  const store = useAtomStore()
  const {onSuccess} = options

  const {t} = useTranslation()
  const param = useAtomValue(atoms.paramForUpdateBoLAtom, {store})
  const updateEditor = useSetAtom(atoms.updateEditorsValueAtom, {store})
  const isUpdating = useIsUpdatingEditableBillOfLading()

  const mutateResult = useUpdateEditableBillOfLading({
    onSuccess: updatedResult => {
      toast.success(t("toastSaveBolSuccess"), {id: BOL_SAVE_TOAST_ID})
      updateEditor(updatedResult)
      onSuccess?.()
    },
    onError: () => {
      toast.error(t("toastSaveBolFailed"), {
        id: BOL_SAVE_TOAST_ID,
      })
    },
  })

  return {
    ...mutateResult,
    save: () => mutateResult.mutate(param),
    isLoading: isUpdating || mutateResult.isLoading,
  }
}
