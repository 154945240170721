import React from "react"
import {noop} from "lodash-es"
import nth from "lodash/nth"
import {useTranslation} from "react-i18next"

import {useElementSize} from "hooks/useElementSize"

import {
  // AddButtonIcon,
  NextButtonIcon,
  PreviousButtonIcon,
  // RemoveButtonIcon,
} from "components/shared/icons/btn"
import {NoBolFile} from "components/shared/icons/nodata"
import {LoadingIndicator} from "components/shared/icons/LoadingIndicator"

import PreviewPDF, {loadingElement} from "./PreviewPDF"
import {getFileTypeFromString} from "utils/file"

/**
 * @type {React.FC<{title: string, message: string}>}
 */
const NoPreviewPlaceholder = ({title, message}) => (
  <div className="w-full h-full flex justify-center items-center flex-col">
    <NoBolFile />
    <div className="text-xl text-grey mt-5">{title}</div>
    <div className="text-sm text-grey mt-5 whitespace-pre text-center">
      {message}
    </div>
  </div>
)

/**
 *
 * @type {React.FC<{
 *  fileName: string
 *  url?: string
 *  nextAble?: boolean
 *  prevAble?: boolean
 *  isLoading?: boolean
 *  onRequestOpenFile?: (url: string | undefined) => void
 *  onRequestDeleteFile?: (fileName: string) => void
 *  onNextFile?: () => void
 *  onPrevFile?: () => void
 * }>}
 */
const UploadedFilesViewer = ({
  url,
  fileName,
  nextAble,
  prevAble,
  isLoading = false,
  onRequestOpenFile = noop,
  onRequestDeleteFile = noop,
  onNextFile = noop,
  onPrevFile = noop,
}) => {
  const {ref: containerRef, width, height} = useElementSize()
  const {t} = useTranslation()
  const fileType = getFileTypeFromString(fileName)

  return (
    <section className="grid grid-cols-[5rem_1fr_5rem] grid-rows-[calc(100%-1.5rem)_1.5rem]">
      {/* main (row-1) */}
      <aside className="flex place-content-center">
        <button
          className="group px-4"
          disabled={!prevAble}
          onClick={onPrevFile}
        >
          <PreviousButtonIcon />
        </button>
      </aside>
      <main className="flex p-2 border border-gray-3 rounded-lg h-full min-w-[30vw] max-w-[500px]">
        <div
          ref={containerRef}
          className="flex-grow relative overflow-x-hidden overflow-y-auto"
        >
          {url ? (
            fileType === "pdf" ? (
              <PreviewPDF url={url} height={height} width={width} />
            ) : (
              <img src={url} alt={fileName} height={height} width={width} />
            )
          ) : (
            <div className="w-full h-full flex justify-center items-center flex-col">
              {!isLoading ? (
                <NoPreviewPlaceholder
                  title={t("noBOLFiles.title")}
                  message={t("noBOLFiles.message")}
                />
              ) : (
                loadingElement
              )}
            </div>
          )}

          <div className="absolute inset-0 pointer-events-none">
            <LoadingIndicator
              className={[
                "absolute left-1/2 -translate-x-1/2 duration-250 transition-transform -translate-y-11",
                isLoading ? "translate-y-5 block" : "hidden",
              ].join(" ")}
            />
          </div>
        </div>
      </main>
      <aside className="flex place-content-center">
        <button
          className="group px-4"
          disabled={!nextAble}
          onClick={onNextFile}
        >
          <NextButtonIcon />
        </button>
      </aside>

      {/* footer (row-2) */}
      <footer className="row-span-1 col-span-full mt-1 flex justify-center items-center space-x-2">
        <button
          disabled={!url && fileType === "csv"}
          className="text-grey-70 text-xs decoration-current underline"
          onClick={() => onRequestOpenFile(url)}
        >
          {url && fileType !== "csv" ? fileName : ""}
        </button>
        {/* // TODO: enable when phase-3
        <button
          className="group scale-75"
          onClick={() => onRequestDeleteFile(fileName)}
        >
          <RemoveBoLButtonIcon />
        </button> */}
      </footer>
    </section>
  )
}

export default UploadedFilesViewer
