import * as React from "react"
import toast, {Toaster, ToastBar} from "react-hot-toast"

import ICLoading from "components/shared/icons/loading"
import ICSuccessful from "components/shared/icons/successful"
import ICWrong from "components/shared/icons/wrong"

export default toast

export const ToastContainer = () => {
  return (
    <Toaster
      position="top-center"
      containerStyle={{
        top: 80,
      }}
      toastOptions={{
        className: "text-sm font-semibold",
        style: {
          border: "0.5px solid #005EB8",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
          borderRadius: "4px",
          minWidth: "384px",
          padding: "14px 6px 14px 16px",
        },
        loading: {
          icon: <ICLoading />,
          style: {
            background: "#FFFFFF",
            border: "0.5px solid #99B5CC",
            color: "#767D8B",
          },
        },
        success: {
          icon: <ICSuccessful />,
          style: {
            background: "#DDE4ED",
            border: "0.5px solid #005EB8",
            color: "#005EB8",
          },
          duration: 4000,
        },
        error: {
          icon: <ICWrong />,
          style: {
            background: "#FFE1D7",
            border: "0.5px solid #FF6A39",
            color: "#FF6A39",
          },
          duration: 4000,
        },
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({icon, message}) => (
            <>
              {icon}
              <div>{message}</div>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
