import * as React from "react"
import {useState} from "react"
import {Combobox, Transition} from "@headlessui/react"
import {ForwardIcon} from "../icons"

import {baseTextInputClassName} from "./TextInputField"

const defaultShowCustomOptionFn = (query = "") => query.length > 0

/**
 * @template Option
 * @typedef {object} ComboboxProps
 * @property {string=} name
 * @property {Option[]} options required, array of options
 * @property {(option: Option) => string} displayOption required, how to render option
 * @property {(option: Option) => string | number | null} idExtractor required, the id of an option
 * @property {(option: Option) => string } [valueExtractor] optional, used for data-value in html
 * @property {Option} [selectedOption] for controlled combobox
 * @property {(option: Option | undefined) => void} [onOptionSelect] for controlled combobox
 * @property {Option} [defaultSelectedOption] the initial selected option
 * @property {string} [containerClassName] class for the outermost div
 * @property {string} [placeholder] placeholder when there's no selection, default is empty string
 * @property {string} [searchPlaceholder] placeholder for search input, default is "Search"
 * @property {boolean} [disabled]
 * @property {(option: Option, query: string) => boolean} [optionQueryFilter] whether to include the option when search with `query`
 * @property {boolean | ((query: string, options: Option[]) => boolean)} [showCustomOption] whether to show custom option (option not in the `options`)
 * @property {(query: string) => Option} [createCustomOptionFromQuery]
 * @property {(option: Option) => boolean} [isOptionCustom] if the selected option is a custom option, we need to have another option displayed
 */

/**
 * @template Option
 * @param {ComboboxProps<Option>} param0
 */
const GiantCombobox = ({
  name,
  options,
  displayOption,
  idExtractor,
  valueExtractor,
  defaultSelectedOption,
  selectedOption,
  onOptionSelect,
  disabled = false,
  placeholder = "",
  searchPlaceholder,
  containerClassName,
  optionQueryFilter,
  showCustomOption = defaultShowCustomOptionFn,
  createCustomOptionFromQuery,
  isOptionCustom,
}) => {
  const controlled = typeof onOptionSelect === "function"
  const [selectedOptionInner, setSelectedOptionInner] = useState(
    controlled ? selectedOption : defaultSelectedOption,
  )
  const [query, setQuery] = useState("")

  const theSelectedValue = controlled ? selectedOption : selectedOptionInner

  const onOptionSelectRef = React.useRef(onOptionSelect)
  React.useEffect(() => {
    onOptionSelectRef.current = onOptionSelect
  })
  const onOptionSelectHandler = React.useCallback(
    /**
     * @param {Option} option
     */
    option => {
      // console.log("on option select", option)
      setSelectedOptionInner(option)
      typeof onOptionSelectRef.current === "function" &&
        onOptionSelectRef.current(option)
    },
    [],
  )

  const filteredOptions =
    typeof optionQueryFilter !== "function" || query === ""
      ? options
      : options.filter(option => optionQueryFilter(option, query))

  showCustomOption =
    typeof showCustomOption === "function"
      ? showCustomOption(query, options)
      : showCustomOption

  return (
    <div className={containerClassName}>
      <Combobox
        name={name}
        disabled={disabled}
        value={theSelectedValue}
        onChange={onOptionSelectHandler}
        nullable
      >
        {({activeOption, value, open, activeIndex, ...rest}) => {
          const displayText = activeOption
            ? displayOption(activeOption)
            : value
            ? displayOption(value)
            : ""

          return (
            <div className="relative">
              <Combobox.Button
                aria-label={placeholder}
                disabled={disabled}
                data-value={
                  valueExtractor && value ? valueExtractor(value) : undefined
                }
                className={`${baseTextInputClassName} disabled:text-grey-70 w-full appearance-none flex flex-row justify-between items-center pr-2`}
              >
                <div className="text-left">{displayText || placeholder}</div>
                <ForwardIcon className="svg-icon pointer-events-none right-2 rotate-90 ui-open:-rotate-90" />
              </Combobox.Button>

              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <div className="absolute mt-2 shadow-md border border-gray-3 rounded-lg w-full bg-white h-[285px] overflow-y-hidden z-10">
                  <div className="px-4">
                    <Combobox.Input
                      autoFocus={false}
                      name={name ? `search-${name}` : undefined}
                      className="w-full pt-3.5 pb-3 text-sm text-grey-70 font-medium border-b border-gray-3 appearance-none focus:outline-none"
                      onChange={event => setQuery(event.target.value)}
                      placeholder={searchPlaceholder}
                      displayValue={() => query}
                    />
                  </div>
                  <Combobox.Options className="overflow-y-auto">
                    {/* display the selected option if it is a custom option */}
                    {query.length <= 0 &&
                    theSelectedValue &&
                    typeof isOptionCustom === "function" &&
                    isOptionCustom(theSelectedValue) ? (
                      <Combobox.Option
                        className="px-4 my-2 py-1 text-sm text-grey ui-active:bg-gray-3 ui-active:text-black"
                        value={theSelectedValue}
                      >
                        Use "{displayOption(theSelectedValue)}"
                      </Combobox.Option>
                    ) : null}
                    {filteredOptions.map((option, optionIndex) => (
                      <Combobox.Option
                        className="px-4 my-2 py-1 text-sm text-grey ui-active:bg-gray-3 ui-active:text-black"
                        key={idExtractor(option)}
                        value={option}
                        data-value={
                          valueExtractor ? valueExtractor(option) : undefined
                        }
                      >
                        {displayOption(option)}
                      </Combobox.Option>
                    ))}
                    {createCustomOptionFromQuery && showCustomOption ? (
                      <Combobox.Option
                        className="px-4 my-2 py-1 text-sm text-grey ui-active:bg-gray-3 ui-active:text-black"
                        value={createCustomOptionFromQuery(query)}
                      >
                        Use "{query}"
                      </Combobox.Option>
                    ) : null}
                  </Combobox.Options>
                </div>
              </Transition>
            </div>
          )
        }}
      </Combobox>
    </div>
  )
}

export default GiantCombobox
