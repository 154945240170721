import * as React from "react"
import {useTranslation} from "react-i18next"

import toast from "lib/toast"
import {useConfirmModal} from "lib/store/useConfirmModal"
import {useEditBoLModal, closeEditBoLModal} from "lib/store/useEditBoLModal"
import {useRemoveBol} from "services/bol"
import {useIsUpdatingEditableBillOfLading} from "services/bol-edit"

import {RemoveBoLButtonIcon} from "components/shared/icons/btn"

const TOAST_ID = "remove-bol"

const RemoveBoLButton = () => {
  const {t} = useTranslation()
  const createConfirm = useConfirmModal(state => state.createConfirm)
  const bolRowData = useEditBoLModal(state => state.bolRowData)
  const isUpdating = useIsUpdatingEditableBillOfLading()

  const {mutate: deleteBillOfLading} = useRemoveBol({
    onMutate: args => {
      closeEditBoLModal()
      toast.loading(t("bolRemoveStatus.loading", args), {
        id: TOAST_ID + ":" + args.bolId,
      })
      return undefined
    },
    onSuccess: (_r, args) => {
      toast.success(t("bolRemoveStatus.success", args), {
        id: TOAST_ID + ":" + args.bolId,
      })
    },
    onError: (_e, args) => {
      toast.error(t("bolRemoveStatus.error", args), {
        id: TOAST_ID + ":" + args.bolId,
      })
    },
  })

  return (
    <button
      data-test-id="remove-bol-button"
      disabled={isUpdating}
      onClick={() => {
        if (bolRowData?.bolSN.id) {
          createConfirm(t("confirmDeleteFile"), {
            onConfirm: () => {
              console.log("onConfirm", {bolId: bolRowData.bolSN.id})
              deleteBillOfLading({bolId: bolRowData.bolSN.id})
            },
            onCancel: () => {},
          })
        }
      }}
    >
      <RemoveBoLButtonIcon />
    </button>
  )
}

export default RemoveBoLButton
