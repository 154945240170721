import * as React from "react"

/**
 * @type {React.FC<React.SVGAttributes<SVGElement>>}
 */
export const SolidCircleXMarkIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#A3B6CD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.999960879564286,10.939361095631122 L16.005160879564286,6.934131095631123 C16.29806087956429,6.641241095631123 16.772960879564284,6.641241095631123 17.065860879564287,6.934131095631123 C17.358760879564286,7.227021095631123 17.358760879564286,7.701901095631122 17.065860879564287,7.994761095631123 L13.060660879564285,11.999961095631123 L17.065860879564287,16.005161095631124 C17.358760879564286,16.298061095631123 17.358760879564286,16.77296109563112 17.065860879564287,17.065861095631124 C16.772960879564284,17.358761095631124 16.29806087956429,17.358761095631124 16.005160879564286,17.065861095631124 L11.999960879564286,13.060661095631122 L7.9947908795642855,17.065861095631124 C7.7019008795642865,17.358761095631124 7.227020879564285,17.358761095631124 6.934130879564285,17.065861095631124 C6.641240879564285,16.77296109563112 6.641240879564285,16.298061095631123 6.934130879564285,16.005161095631124 L10.939360879564285,11.999961095631123 L6.934130879564285,7.994761095631123 C6.641240879564285,7.701901095631122 6.641240879564285,7.227021095631123 6.934130879564285,6.934131095631123 C7.227020879564285,6.641241095631123 7.7019008795642865,6.641241095631123 7.9947908795642855,6.934131095631123 L11.999960879564286,10.939361095631122 z"
      fill="#FFF"
    />
  </svg>
)

/**
 * @type {React.FC<{
 *  size?: number
 *  progress?: number
 *  strokeWidth?: number
 *  className?: string
 * }>}
 */
export const ProgressXMarkIcon = ({
  size = 24,
  progress = 0,
  strokeWidth = 2,
  className,
}) => {
  const [offset, setOffset] = React.useState(0)
  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  React.useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)
  }, [circumference, progress])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="#A3B6CD"
        stroke="none"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="#A3B6CD"
        stroke="#005EB8"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{
          transition: "stroke-dashoffset 480ms ease-in-out",
          transformOrigin: "center",
          transform: "rotate(-90deg)",
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.999960879564286,10.939361095631122 L16.005160879564286,6.934131095631123 C16.29806087956429,6.641241095631123 16.772960879564284,6.641241095631123 17.065860879564287,6.934131095631123 C17.358760879564286,7.227021095631123 17.358760879564286,7.701901095631122 17.065860879564287,7.994761095631123 L13.060660879564285,11.999961095631123 L17.065860879564287,16.005161095631124 C17.358760879564286,16.298061095631123 17.358760879564286,16.77296109563112 17.065860879564287,17.065861095631124 C16.772960879564284,17.358761095631124 16.29806087956429,17.358761095631124 16.005160879564286,17.065861095631124 L11.999960879564286,13.060661095631122 L7.9947908795642855,17.065861095631124 C7.7019008795642865,17.358761095631124 7.227020879564285,17.358761095631124 6.934130879564285,17.065861095631124 C6.641240879564285,16.77296109563112 6.641240879564285,16.298061095631123 6.934130879564285,16.005161095631124 L10.939360879564285,11.999961095631123 L6.934130879564285,7.994761095631123 C6.641240879564285,7.701901095631122 6.641240879564285,7.227021095631123 6.934130879564285,6.934131095631123 C7.227020879564285,6.641241095631123 7.7019008795642865,6.641241095631123 7.9947908795642855,6.934131095631123 L11.999960879564286,10.939361095631122 z"
        fill="#FFF"
      />
    </svg>
  )
}
