import React from "react"

/**
 * @type {React.FC<React.PropsWithChildren<
 * React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
 *  showBorder?: boolean
 *  defaultBackground?: boolean,
 *  borderClassName?: string
 * }
 * >>}
 */
const Container = ({
  children,
  className,
  showBorder = true,
  defaultBackground = true,
  borderClassName = "border border-gray-3 rounded-lg",
  ...props
}) => {
  const classNameString = React.useMemo(
    () =>
      [
        "container mx-auto",
        defaultBackground ? "bg-white" : undefined,
        showBorder ? borderClassName : undefined,
        className,
        //
      ].join(" "),
    [showBorder, borderClassName, className],
  )
  return (
    <div className={classNameString} {...props}>
      {children}
    </div>
  )
}

export default Container
