import {BolPrecheckResultDTO} from "@domain"
import ICWrong from "components/shared/icons/wrong"
import React from "react"
import {useTranslation} from "react-i18next"
import ReactTooltip from "react-tooltip"
import {z} from "zod"

/**
 * @typedef {keyof import("@domain").TBolPrecheckResultRow} TAvailableKeys
 * @type {Array<TAvailableKeys>}
 */
const tableColumnKeys = ["bol", "vendor", "shipType", "carrier", "container"]

/**
 * @typedef {keyof import("@domain").TBolPrecheckResultDetailedRow} TAvailableDetailedKeys
 * @type {Array<TAvailableDetailedKeys>}
 */
const tableColumnKeysWithRow = ["row", ...tableColumnKeys]

/**
 * @type {Record<TAvailableDetailedKeys, string>}
 */
const ColumnHeaderI18nKeyMapping = {
  row: "rowNumber",
  bol: "bol",
  vendor: "vendor",
  shipType: "shipType",
  carrier: "carrier",
  container: "containerSN",
}

/**
 *
 * @param {TAvailableKeys} key
 * @param {string} originalCode
 * @param {import("react-i18next").TFunction<"translation", undefined>} t
 */
const getErrorCode = (key, originalCode, t) => {
  switch (key) {
    case "shipType":
      if (originalCode === "ERROR_NOT_SUPPORT")
        return "ERROR_NOT_SUPPORT_SHIPTYPE"
      return originalCode
    default:
      return originalCode
  }
}

/**
 * @type {React.FC<{children: React.ReactNode}& React.ThHTMLAttributes<HTMLTableCellElement>>}
 */
const TableHeader = ({children, className, ...restProps}) => (
  <th
    className={`${className ?? ""} text-grey-70 font-bold text-xs p-3`}
    {...restProps}
  >
    {children}
  </th>
)

/**
 * @type {React.FC<{
 *  children?: React.ReactNode,
 *  warningText?: string,
 *  errorText?: string,
 *  columnKey: TAvailableDetailedKeys
 * } & React.TdHTMLAttributes<HTMLTableCellElement>>}
 */
const TableCell = ({
  children,
  className,
  warningText,
  errorText,
  columnKey,
  ...restProps
}) => {
  const isContainer = columnKey === "container"
  return (
    <td
      className={`${className ?? ""} text-grey font-normal text-sm py-2 px-3`}
      valign="top"
      width={isContainer ? 150 : undefined}
      {...restProps}
    >
      {children ? (
        <div
          className="max-w-[150px] truncate"
          data-for={isContainer ? CONTAINER_TOOLTIP_ID : undefined}
          data-tip={children}
        >
          {children}
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
      {warningText ? <WarningHint>{warningText}</WarningHint> : null}
      {errorText ? <ErrorHint>{errorText}</ErrorHint> : null}
    </td>
  )
}

const LoadingSkeletonRow = () => (
  <tr>
    {tableColumnKeysWithRow.map(key => (
      <TableCell key={key} columnKey="row">
        <div className="animate-pulse bg-grey-30 rounded w-full">&nbsp;</div>
      </TableCell>
    ))}
  </tr>
)

/**
 * @type {React.FC<{children: React.ReactNode}>}
 */
const ErrorHint = ({children}) => (
  <div className="text-error text-xs font-medium flex gap-1 items-center w-fit mt-1 whitespace-nowrap">
    <ICWrong />
    {children}
  </div>
)

/**
 * @type {React.FC<{children: React.ReactNode}>}
 */
const WarningHint = ({children}) => (
  <div className="text-blue text-xs font-medium w-fit mt-1 whitespace-nowrap">
    {children}
  </div>
)

const CONTAINER_TOOLTIP_ID = "CONTAINER_TOOL_TIP"

const ContainerToolTip = () => {
  return (
    <ReactTooltip
      id={CONTAINER_TOOLTIP_ID}
      place="bottom"
      effect="solid"
      backgroundColor="transparent"
      border={false}
      className="!opacity-100"
      clickable
      delayHide={200}
      offset={{top: 0, bottom: 0, left: 0, right: 0}}
      getContent={arrStr => {
        if (!arrStr) return null

        const arr = arrStr.split(";")

        return (
          <div className="px-4 py-3 rounded-lg bg-white border border-gray-3 shadow-md">
            <div className="text-black text-sm font-medium">
              {arr.map((arrValue, i) => (
                <div key={i}>{arrValue}</div>
              ))}
            </div>
          </div>
        )
      }}
    />
  )
}

/**
 * @typedef {object} BolCsvPrecheckTableProps
 * @property {string} [className]
 * @property {boolean} [isLoading]
 * @property {z.infer<typeof BolPrecheckResultDTO>} [result]
 */
/**
 * @type {React.FC<BolCsvPrecheckTableProps>}
 */
const BolCsvPrecheckTable = ({className, isLoading, result}) => {
  const {t} = useTranslation()

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [result])
  return (
    <div
      className={`${
        className ?? ""
      } rounded-lg border border-gray-3 bg-light overflow-auto`}
    >
      <div className="min-w-[888px] h-[calc(100vh*0.475)]">
        <table className="relative table-auto w-full">
          <thead className="sticky top-0 border-b border-gray-3 bg-light">
            <tr>
              {tableColumnKeysWithRow.map(key => (
                <TableHeader
                  align={key === "row" ? "center" : "left"}
                  key={key}
                >
                  {t(ColumnHeaderI18nKeyMapping[key])}
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-3">
            {result && !isLoading ? (
              result.map(row => {
                const {row: rowNumber, ...rowData} = row
                return (
                  <tr key={rowNumber}>
                    <TableCell align="center" columnKey="row">
                      {rowNumber}
                    </TableCell>
                    {tableColumnKeys.map(key => {
                      const {data, error, warning} = rowData[key]
                      const errorMessage = error
                        ? t(
                            `bolUploadPrecheckTable.${getErrorCode(
                              key,
                              error,
                              t,
                            )}`,
                          )
                        : undefined
                      const warningMessage = warning
                        ? t(`bolUploadPrecheckTable.${warning}`)
                        : undefined

                      return (
                        <TableCell
                          align="left"
                          warningText={warningMessage}
                          errorText={errorMessage}
                          key={key}
                          // @ts-ignore
                          columnKey={key}
                        >
                          {key === "container" && Array.isArray(data)
                            ? data.join(";")
                            : data}
                        </TableCell>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <>
                <LoadingSkeletonRow />
                <LoadingSkeletonRow />
                <LoadingSkeletonRow />
              </>
            )}
          </tbody>
        </table>
      </div>
      <ContainerToolTip />
    </div>
  )
}

export default BolCsvPrecheckTable
